export const interviewSettingsTabProjectsControlCopy = {
    projectsControl: {
        en: "Projects control"
    },
    generateSyntheticInterviews: {
        en: "Generate synthetic interviews"
    },
    hideGlautLogo: {
        en: "Hide Glaut logo"
    },
    activateWebRtc: {
        en: "Activate WebRTC"
    },
    allowMultipleSubmissions: {
        en: "Allow multiple submissions"
    },
    includeUncompleted: {
        en: "Include uncompleted answers in analysis"
    }
} as const
