import { useMemo, useState } from "react"
import { buildIFrameCode } from "./utils/build-iframe-code"
import { getCopy } from "@utils/Copy"
import { embedCodeBlockCopy } from "./utils/copy"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import { MdOutlineCheck, MdOutlineCopyAll } from "react-icons/md"
import { useCopyToClipboardString } from "@hooks/useCopyToClipboardString"
import GlautSwitch from "@components/inputs/GlautSwitch"

interface IEmbedCodeBlockProps {
    projectId: string
}

export default function EmbedCodeBlock({ projectId }: Readonly<IEmbedCodeBlockProps>) {
    // #region States
    const [skipWelcome, setSkipWelcome] = useState(false)
    // #endregion

    // #region Memos
    const iFrameCode = useMemo(
        () => buildIFrameCode(projectId, { skipWelcome }), 
        [projectId, skipWelcome]
    )
    // #endregion

    // #region Helper hooks
    const { hasCopied, copyToClipboard } = useCopyToClipboardString({ timeout: 1500 })
    // #endregion

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-3 py-[0.5rem] px-[1.5rem] rounded-[0.75rem] border-1
               bg-glaut-very-light-grey border-glaut-light-grey overflow-hidden">
                {/* Code Display */}
                <p className="text-[13.33px] text-black font-mono overflow-x-auto min-w-0">
                    {iFrameCode}
                </p>
                
                {/* Controls Row */}
                <div className="flex justify-between items-center border-t border-glaut-light-grey pt-3 rounded-none">
                    <div className="flex items-center gap-2">
                        <GlautSwitch
                            checked={skipWelcome}
                            onChange={e => setSkipWelcome(e.target.checked)}
                        />
                        <span className="text-xs text-gray-600">{getCopy(embedCodeBlockCopy.skipWelcome)}</span>
                    </div>
                    <GlautButtonPrimary onClick={() => copyToClipboard(iFrameCode)}>
                        {hasCopied && (
                            <div className="flex items-center gap-[0.25rem]">
                                <MdOutlineCheck className="w-[1.125rem] h-[1.125rem]" />
                                <p className="text-[11.11px] font-medium">
                                    {getCopy(embedCodeBlockCopy.copied)}!
                                </p>
                            </div>
                        )}
                        {!hasCopied && (
                            <div className="flex items-center gap-[0.25rem]">
                                <MdOutlineCopyAll className="w-[1.125rem] h-[1.125rem]" />
                                <p className="text-[11.11px] font-medium whitespace-nowrap">
                                    {getCopy(embedCodeBlockCopy.copyCode)}
                                </p>
                            </div>
                        )}
                    </GlautButtonPrimary>
                </div>
            </div>
        </div>
    )
}