import PdfGlautWideLogo from "@components/Pdf/PdfGlautWideLogo"
import { Document, Page, View } from "@react-pdf/renderer"
import { copy, getCopy } from "@utils/Copy"
import { forwardRef, useEffect } from "react"
import { useReportDetails, useReportDetailsDispatch } from "../../contexts/ReportDetailsContext"
import { defaultPageTwStyling } from "../../utils/pdf-styling"
import ReportDetailsAppendix from "../ReportDetailsAppendix"
import ReportDetailsContentGoal from "../ReportDetailsContentGoal"
import KeyInterviewData from "../ReportDetailsContentKeyInterviewData"
import LastEditedAt from "../ReportDetailsContentLastEditedAt"
import StandardTextSession from "../ReportDetailsContentStandardTextSession"
import Title from "../ReportDetailsContentTitle"
import { useScrollToElementCrossPlatform } from "@hooks/useScrollToElementCrossPlatform"

const ReportDetailsContent = forwardRef<HTMLDivElement>((_, ref) => {
    // #region Contexts
    const { report, renderAsPdf, tw, scrollToBlockId } = useReportDetails()
    const reportDetailsDispatch = useReportDetailsDispatch()
    // #endregion

    // #region Scroll to element
    const { scrollToElement } = useScrollToElementCrossPlatform({ headerElementId: "div--report-header" })
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!scrollToBlockId) return

        const element = document.getElementById(scrollToBlockId)
        if (!element) return

        scrollToElement(element)

        reportDetailsDispatch({ type: "clear-block-to-scroll" })
    }, [reportDetailsDispatch, scrollToBlockId, scrollToElement])
    // #endregion

    if (!report) return <></>

    if (renderAsPdf)
        return (
            <Document title={report.title}>
                <Page
                    bookmark="Introduction"
                    style={tw(`${defaultPageTwStyling} flex flex-col gap-[15.28px]`)}
                    size="A4"
                >
                    <View style={tw("flex flex-col")}>
                        <View style={tw("px-[8px] pb-[4px] border-b-1 border-b-glautLightGrey")}>
                            <PdfGlautWideLogo />
                        </View>
                        <View style={tw("mb-[30.54px]")}>
                            <Title />
                        </View>
                        <LastEditedAt />
                    </View>
                    <StandardTextSession
                        title={getCopy(copy.words.projectOverview) ?? ""}
                        content={report.project_description}
                        size="big"
                    />
                    <KeyInterviewData />
                </Page>
            </Document>
        )

    return (
        <div className={`flex flex-col gap-[1.5em] pb-96 flex-1 min-w-0 mx-[0.675rem]
            sm:container sm:mx-auto sm:max-w-[80%]
            lg:max-w-[70%]
            2xl:max-w-[60%]
        `} ref={ref}>
            <Title />
            <LastEditedAt />
            <StandardTextSession
                id="project-overview"
                title={getCopy(copy.words.projectOverview) ?? ""}
                content={report.project_description}
                size="big"
                withTitleUnderline
            />
            <KeyInterviewData />
            {report.goals.map((goal, goalIdx) => (
                <ReportDetailsContentGoal key={`${goal.title}-${goalIdx}`} goal={goal} index={goalIdx} />
            ))}
            <ReportDetailsAppendix />
        </div>
    )
})

ReportDetailsContent.displayName = "ReportDetailsContent"

export default ReportDetailsContent