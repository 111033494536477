interface IGlautButtonAiSecondaryLabelProps {
    disabled?: boolean
    children: React.ReactNode
}

export default function GlautButtonAiSecondaryLabel({
    children,
    disabled = false
}: Readonly<IGlautButtonAiSecondaryLabelProps>) {
    return (
        <p className={`text-[13.33px] font-medium bg-clip-text
            ${disabled ? "text-glaut-grey" : ""}
            ${!disabled ? "bg-glaut-icon-gradient-1 text-transparent" : ""}
        `}>
            {children}
        </p>
    )
}