import Members from "@components/Auth/Members"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import DeletePopup from "@components/layouts/DeletePopup"
import { useToast } from "@contexts/ToastProvider"
import { useProjectService } from "@hooks/services/useProjectService"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { copy, getCopy } from "@utils/Copy"
import ErrorLevel from "@utils/ErrorLevel"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import CSVSettingsTabProjectProjectLanguage from "../CSVSettingsTabProjectProjectLanguage"
import CSVSettingsTabProjectTitle from "../CSVSettingsTabProjectTitle"
import CSVSettingsTabProjectUsage from "../CSVSettingsTabProjectUsage"
import InterviewSettingsTabProjectEmbeddingInstructions from "../InterviewSettingsTabProjectEmbeddingInstructions"
import InterviewSettingsTabProjectsControl from "../InterviewSettingsTabProjectsControl"

const roles = [
    {
        slug: "editor",
        title: "Editor",
        description: "Can edit projects but cannot add or remove members"
    },
    {
        slug: "viewer",
        title: "Viewer",
        description: "Can only view the project"
    }
]

export default function SettingsTab() {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    // #region Hooks
    const navigate = useNavigate()
    const {
        currentProjectGuests,
        inviteProjectGuest,
        changeProjectGuestRole,
        removeProjectGuest,
        currentProjectIsAtLeastRole,
        isSuperuser
    } = useGlautAuthInfo()
    const { addToast } = useToast()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region State
    const [openDelete, setOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    // #endregion

    // #region Memo
    const canEdit = useMemo(
        () => currentProjectIsAtLeastRole("owner"),
        [currentProjectIsAtLeastRole]
    )
    // #endregion

    // #region Callbacks
    const inviteUser = useCallback((email: string, role: string) => {
        setLoading(true)
        return inviteProjectGuest(email, role).then(() => {
            addToast(getCopy(copy.org.inviteSent)?.(email) ?? "")
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.inviteUserError) ?? "", "", ErrorLevel.Error)
        })
    }, [addToast, inviteProjectGuest])

    const changeUserRole = useCallback((email: string, role: string) => {
        setLoading(true)
        return changeProjectGuestRole(email, role).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.changeUserRoleError) ?? "")
        })
    }, [addToast, changeProjectGuestRole])

    const removeUser = useCallback(propAuthUserId => {
        setLoading(true)
        return removeProjectGuest(propAuthUserId).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.removeUserError) ?? "")
        })
    }, [addToast, removeProjectGuest])
    // #endregion

    if (!project) return <></>

    return (
        <div className="flex justify-center overflow-auto">
            <div className="flex flex-col w-3/4">
                <div id="project-settings" className="flex flex-col py-[4.5rem] gap-[0.75rem]">
                    <CSVSettingsTabProjectTitle />
                    <CSVSettingsTabProjectProjectLanguage disabled />
                    <CSVSettingsTabProjectUsage />
                    {currentProjectGuests !== null && <Members
                        users={currentProjectGuests}
                        defaultRole="viewer"
                        roles={roles}
                        canEdit={canEdit}
                        inviteUser={inviteUser}
                        changeUserRole={changeUserRole}
                        removeUser={removeUser}
                        loading={loading}
                        copy={copy.project}
                    />}
                    <InterviewSettingsTabProjectEmbeddingInstructions />
                    {canEdit && (
                        <div
                            id="danger-zone"
                            className="flex flex-row justify-between items-center py-[0.5rem] px-[0.75rem] rounded-md
                                bg-[#FBD7D7]"
                        >
                            <div className="flex flex-col gap-[0.25rem]">
                                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                                    {getCopy(copy.project.deleteThis)}
                                </p>
                                <p className="text-[13.33px] text-glaut-text-midnight">
                                    {getCopy(copy.project.deleteDescription)}
                                </p>
                            </div>
                            <GlautButtonSecondary onClick={() => setOpenDelete(true)}>
                                {getCopy(copy.project.deleteButton)}
                            </GlautButtonSecondary>
                        </div>
                    )}
                    {isSuperuser && <InterviewSettingsTabProjectsControl />}
                    {openDelete && project && (
                        <DeletePopup
                            confirmString={project.name || "Untitled"}
                            entityName="project"
                            close={() => setOpenDelete(false)}
                            onConfirm={() => {
                                projectService.deleteProject({ projectId: project._id }).then(() => {
                                    setOpenDelete(false)
                                    navigate(`/w/${project.workspace_id}`)
                                })
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
