import { ITerminationReason } from "@/@types/entry"
import ErrorPage from "@components/layouts/ErrorPage"
import ErrorLevel from "@utils/ErrorLevel"
import InterviewTerminatedScreenout from "./components/InterviewTerminatedScreenout"
import InterviewTerminatedQuotaFull from "./components/InterviewTerminatedQuotaFull"
import InterviewTerminatedUncooperative from "./components/InterviewTerminatedUncooperative"
import InterviewTerminatedCompleted from "./components/InterviewTerminatedCompleted"

interface IInterviewTerminatedProps {
    lang: string
    terminationReason: ITerminationReason
}

export default function InterviewTerminated({
    lang,
    terminationReason
}: Readonly<IInterviewTerminatedProps>) {
    if (terminationReason === "screenout")
        return <InterviewTerminatedScreenout lang={lang} />

    if (terminationReason === "quotafull")
        return <InterviewTerminatedQuotaFull lang={lang} />

    if (terminationReason === "uncooperative")
        return <InterviewTerminatedUncooperative lang={lang} />

    if (terminationReason === "completed")
        return <InterviewTerminatedCompleted lang={lang} />

    return <ErrorPage lang={lang} errorLevel={ErrorLevel.Error} />
}