export const outline = {
    outline: {
        sidebar: {
            projectStatus: {
                en: "PROJECT STATUS"
            },
            projectSetUp: {
                en: "PROJECT SETUP"
            },
            shareInterview: {
                en: "SHARE INTERVIEW"
            },
            briefing: {
                en: "Briefing"
            },
            overview: {
                en: "Overview"
            },
            interviewQuestions: {
                en: "Interview questions"
            },
            screeningAndRedirects: {
                en: "Screening and redirects"
            },
            branding: {
                en: "Branding"
            },
            youNeedToSwitchTheProjectStatusToTest: {
                en: "You need to switch the project status to Test to enable copying the interview URL!"
            },
            copyInterviewUrl: {
                en: "Copy interview URL"
            },
            copied: {
                en: "Copied"
            },
            switchToTestModeToCopyTheUrlFromTheInterviewExclamation: {
                en: "Switch to Test mode to copy the URL from the interview!"
            },
            processingTranslationPleaseWaitExclamation: {
                en: "Processing translation, please wait!"
            },
            pleaseWaitTheTranslationToEnd: {
                en: "Please wait the translation to end."
            }
        },
        overview: {
            projectLanguage: {
                en: "Project language"
            },
            thisIsTheLanguageTheProjectIsWrittenAndAnalyzedIn: {
                en: "This is the language the project is written and analyzed in."
            },
            selectALanguage: {
                en: "Select a language"
            },
            selectOneLanguage: {
                en: "Select one language"
            },
            interviewLanguages: {
                en: "Interview languages"
            },
            selectWhichLanguagesToRunInterviewsIn: {
                en: "Select which languages to run interviews in."
            },
            responsesTarget: {
                en: "Responses target"
            },
            moderatorVoice: {
                en: "Moderator voice"
            },
            collectEmail: {
                en: "Collect email"
            },
            privacyNoticeForRespondents: {
                en: "Privacy notice for respondents"
            },
            privacyPolicyLink: {
                en: "Privacy policy link"
            },
            ifNoLinkIsSharedGlautWillDisplay: {
                en: "If no link is shared, Glaut will display its privacy notice for respondents"
            },
            requestExplicitConsent: {
                en: "Request explicit consent"
            },
            projectTitle: {
                en: "Project title"
            },
            projectContext: {
                en: "Project context"
            },
            provideAnyValuableInformationForGlaut: {
                en: "Provide any valuable information for Glaut to moderate the interview effectively."
            },
            weAreInterviewingYoungAdults: {
                en: "We are interviewing young adults 18-30 years old on their dietary goals and motivations..."
            },
            welcomeMessage: {
                en: "Welcome message"
            },
            thisMessageWillBeDisplayedInTheInitial: {
                en: "This message will be displayed in the initial screen of the interview."
            },
            welcomeToOurInterviewPleaseTakeAMoment: {
                en: "Welcome to our interview! Please take a moment to read through the instructions before we begin..."
            },
            setAMaximumNumberOfResponses: {
                en: "Set a maximum number of responses for the project."
            },
            engageWithTheModeratorAsInAReal: {
                en: "Engage with the moderator as in a real conversation."
            },
            ensureRespondentsAgreeToTheTerms: {
                en: "Ensure respondents agree to the terms before proceeding with the interview."
            },
            requireAnEmailAddressToStart: {
                en: "Require an email address to start the conversation"
            },
            areYouSureYouWantToDeleteThisInterLang: {
                en: "Are you sure you want to delete this interview language?"
            },
            addTranslation: {
                en: "Add translation"
            },
            endingMessage: {
                en: "Ending message"
            },
            thisMessageWillBeDisplayedWhenTheInterviewIsCompleted: {
                en: "This message will be displayed when the interview is completed."
            },
            thankYouForParticipatingInOurStudyWeHopeYouEnjoyedIt: {
                en: "Thank you for participating in our study, we hope you enjoyed it!"
            },
            interviewLayout: {
                en: "Interview layout"
            },
            voiceFirstInterviewGivesMoreRelevanceToTheMicrophone: {
                en: "Voice first interview gives more relevance to the microphone to encourage voice responses, while" +
                    " text first interview to the keyboard."
            }
        },
        interviewQuestions: {
            questionList: {
                en: "Question list"
            },
            questionSimulation: {
                en: "Question simulation"
            },
            noQuestionsCreatedYet: {
                en: "No questions created yet"
            },
            chooseTheTypeOfQuestionToAdd: {
                en: "Choose the type of question to add"
            },
            simulateQuestion: {
                en: "Simulate question"
            },
            simulateTheQuestionOutcomeWithSyntheticData: {
                en: "Simulate the question outcome with synthetic data to fine-tune it."
            },
            defineTheQuestion: {
                en: "Define the question"
            },
            createABranchLogic: {
                en: "Create a branch logic"
            },
            writeYourQuestionHereEllipsis: {
                en: "Write your question here..."
            },
            probeDeeperWithFollowUpsOptional: {
                en: "Probe deeper with follow-ups (optional)"
            },
            probeDeeperWithFollowUps: {
                en: "Probe deeper with follow-ups"
            },
            aiFollowUpQuestions: {
                en: "AI follow-up questions"
            },
            instructTheModeratorHowToProbeDeeper: {
                en: "Instruct the moderator how to probe deeper"
            },
            glautWillFollowUpToExpandAndUnderstand: {
                en: "Glaut will follow up to expand and understand the participant’s response better."
            },
            probingTemplates: {
                askWhy: {
                    label: {
                        en: "Ask why"
                    },
                    description: {
                        en: "Explore the underlying reasons or motivations behind the respondent’s answer"
                    }
                },
                askWhat: {
                    label: {
                        en: "Ask what"
                    },
                    description: {
                        en: "Use step-by-step prompts to uncover a more detailed understanding of the reply"
                    }
                },
                askExample: {
                    label: {
                        en: "Ask example"
                    },
                    description: {
                        en: "Encourage participants to provide real-life scenarios or anecdotal evidence that illustrate their response or viewpoints"
                    }
                }
            },
            analyzeTheQuestion: {
                en: "Analyze the question"
            },
            areYouSureYouWantToDeleteThisAnalysis: {
                en: "Are you sure you want to delete this analysis?"
            },
            areYouSureYouWantToDeleteThisQuestion: {
                en: "Are you sure you want to delete this question?"
            },
            defineTheQuestionEllipsis: {
                en: "Define the question..."
            },
            howManyOptionsCanTheUserSelectQuestion: {
                en: "How many options can the user select?"
            },
            addAnalysis: {
                en: "Add analysis"
            },
            addQuestion: {
                en: "Add question"
            },
            branchLogics: {
                en: "Branch logics"
            },
            ifTheRespondentMentionsAbcFollowUpWithXyzEllipsis: {
                en: "If the respondent mentions ABC, follow-up with XYZ..."
            },
            instructGlautHowToAnalyzeTheOpenDataFromFollowUps: {
                en: "Instruct Glaut how to analyze the open data from follow-ups."
            },
            showQuestionsList: {
                en: "Show questions list"
            },
            tipUseYourArrowKeysToChangeSimulatedResponsesExclamation: {
                en: "TIP: Use your arrow keys to change simulated responses!"
            }
        },
        branding: {
            logo: {
                en: "Logo"
            },
            fontColor: {
                en: "Font color"
            },
            backgroundColor: {
                en: "Background color"
            }
        },
        screeningAndRedirects: {
            screeningQuestions: {
                en: "Screening questions"
            },
            createNewQuestions: {
                en: "Create new questions"
            },
            addScreeningQuestionsToFilterRespondents: {
                en: "Add screening questions to filter respondents based on your target conditions. Set qualifications or quotas to define who can proceed in the interview."
            },
            endingPaths: {
                en: "Ending paths"
            },
            endingMessage: {
                en: "Ending message"
            },
            types: {
                complete: {
                    switchLabel: {
                        en: "Complete redirect"
                    },
                    urlLabel: {
                        en: "Redirect respondents who complete the interview successfully."
                    },
                    endingMessageLabel: {
                        en: "This message will be displayed when the interview is completed."
                    },
                    endingMessagePlaceholder: {
                        en: "Thank you for participating in our study, we hope you enjoyed it! You will now be redirected..."
                    }
                },
                screenout: {
                    switchLabel: {
                        en: "Screen out redirect"
                    },
                    urlLabel: {
                        en: "Redirect respondents who do not meet the screening criteria and are disqualified."
                    },
                    endingMessageLabel: {
                        en: ""
                    },
                    endingMessagePlaceholder: {
                        en: "Thank you for trying our study, but unfortunately you don’t match the target profile. We hope to see you again for a future study! You will now be redirected..."
                    }
                },
                quotafull: {
                    switchLabel: {
                        en: "Quota full redirect"
                    },
                    urlLabel: {
                        en: "Redirect respondents whose quota seats have already been filled."
                    },
                    endingMessageLabel: {
                        en: ""
                    },
                    endingMessagePlaceholder: {
                        en: "Thank you for trying our study, but unfortunately we have already filled it. We hope to see you again for a future study! You will now be redirected..."
                    }
                },
                uncooperative: {
                    switchLabel: {
                        en: "Non cooperative redirect"
                    },
                    urlLabel: {
                        en: "Redirect respondents who are non cooperative with the interview."
                    },
                    endingMessageLabel: {
                        en: ""
                    },
                    endingMessagePlaceholder: {
                        en: "Thank you for trying our study, but unfortunately the level of engagement is not satisfactory. We hope to see you again for a future study! You will now be redirected..."
                    }
                }
            }
        },
        untitledProject: {
            en: "Untitled project"
        },
        untitled: {
            en: "Untitled"
        },
        moveTo: {
            en: "Move to"
        },
        writeHere: {
            en: "Write here..."
        },
        areYouSureYouWantToDeleteThisImageQuestion: {
            en: "Are you sure you want to delete this image?"
        }
    }
} as const