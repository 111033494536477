import { Text, View } from "@react-pdf/renderer"
import { forwardRef, useMemo } from "react"
import { MdChevronRight } from "react-icons/md"
import Markdown from "react-markdown"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { markdownToPdfComponents } from "../../utils/markdown-components"

type IReportDetailsContentStandardTextSessionBaseProps = {
    id?: string
    upperTitle?: string
    title: string
    content?: string
    size: "large" | "big" | "medium"
    withTitleUnderline?: boolean
}

type IReportDetailsContentStandardTextSessionProps = IReportDetailsContentStandardTextSessionBaseProps & ({
    expandable?: false
    onToggleExpand?: never
    isExpanded?: never
} | {
    expandable: true
    onToggleExpand: (isExpanded: boolean) => void
    isExpanded: boolean
})

const ReportDetailsContentStandardTextSession = forwardRef<
    HTMLDivElement,
    Readonly<IReportDetailsContentStandardTextSessionProps>
>(({
    id,
    upperTitle,
    title,
    content,
    size = "large",
    withTitleUnderline = false,
    expandable = false,
    onToggleExpand,
    isExpanded
}, ref) => {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion


    // #region Element memos
    const titleElement = useMemo(() => (
        <div>
            {upperTitle && (
                <p className="text-[11.11px] font-medium text-glaut-pink">
                    {upperTitle}
                </p>
            )}
            {/* // `withTitleUnderline` is only applied to the title when not expandable => avoid floating underline */}
            <p className={`text-glaut-text-midnight pr-[1rem] mb-[0.25rem] font-serif
                ${withTitleUnderline && !expandable ? "border-b-1 border-b-glaut-very-light-grey" : ""}
                ${size === "large" ? "text-[32px]" : ""}
                ${size === "big" ? "text-[23px]" : ""}
                ${size === "medium" ? "text-[19.2px]" : ""}
            `}>
                {title}
            </p>
        </div>
    ), [title, size, withTitleUnderline, expandable, upperTitle])
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-col gap-[8.91px]")}>
                <Text style={[
                    tw("text-[12.2px] px-[10.18px] pt-[10.18px] pb-[2.55px] border-b-1 font-serif"),
                    tw("text-glautDarkGrey border-b-glautVeryLightGrey")
                ]}>
                    {title}
                </Text>
                {content && (
                    <View style={tw("px-[10.18px]")}>
                        <Markdown components={markdownToPdfComponents}>
                            {content}
                        </Markdown>
                    </View>
                )}
            </View>
        )

    return (
        <div className="flex flex-col gap-[0.875rem] py-[1rem]" id={id} ref={ref}>
            {expandable && (
                <button
                    className={`flex justify-start gap-[0.75rem] rounded-[0.25rem] w-full
                        py-[0.5rem] px-[0.5rem] m-0 bg-transparent shadow-none border-0 text-base 
                        hover:bg-glaut-very-light-grey
                        ${withTitleUnderline ? "border-b-1 border-b-glaut-very-light-grey" : ""}
                    `}
                    onClick={() => { onToggleExpand?.(!isExpanded) }}
                >
                    <MdChevronRight
                        className={`h-[1.125rem] w-[1.125rem] transition-all
                            text-glaut-text-midnight
                            ${isExpanded ? "rotate-90" : ""}
                        `}
                    />
                    {titleElement}
                </button>
            )}
            {!expandable && titleElement}
            {content && (
                <Markdown className="markdown text-glaut-dark-grey pr-[1rem]">
                    {content}
                </Markdown>
            )}
        </div>
    )
})

ReportDetailsContentStandardTextSession.displayName = "ReportDetailsContentStandardTextSession"

export default ReportDetailsContentStandardTextSession