export const interviewTerminatedCompletedCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        ar: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                لا تقلق،<br />
                <span className="text-glaut-pink font-medium">نحن نهتم <br />بك.</span>
            </p>
        ),
        zh: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                别担心，<br />
                <span className="text-glaut-pink font-medium">我们会<br />照顾好你。</span>
            </p>
        ),
        hr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Ne brini,<br />
                <span className="text-glaut-pink font-medium">mi smo te <br />pokrili.</span>
            </p>
        ),
        cs: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Neboj se,<br />
                <span className="text-glaut-pink font-medium">máme tě <br />krytého.</span>
            </p>
        ),
        nl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Maak je geen zorgen,<br />
                <span className="text-glaut-pink font-medium">wij zorgen <br />voor je.</span>
            </p>
        ),
        en_GB: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        en_AU: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        en_ZA: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        en_NG: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        en_KE: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Don&apos;t worry,<br />
                <span className="text-glaut-pink font-medium">we got you <br />covered.</span>
            </p>
        ),
        fi: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Älä huoli,<br />
                <span className="text-glaut-pink font-medium">pidämme sinusta <br />huolta.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Ne t&apos;inquiète pas,<br />
                <span className="text-glaut-pink font-medium">on s&apos;occupe <br />de toi.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Keine Sorge,<br />
                <span className="text-glaut-pink font-medium">wir kümmern uns <br />um dich.</span>
            </p>
        ),
        de_CH: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Keine Sorge,<br />
                <span className="text-glaut-pink font-medium">wir kümmern uns <br />um dich.</span>
            </p>
        ),
        el: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Μην ανησυχείς,<br />
                <span className="text-glaut-pink font-medium">σε έχουμε <br />καλύψει.</span>
            </p>
        ),
        hi_IN: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                चिंता मत करो,<br />
                <span className="text-glaut-pink font-medium">हम आपका ध्यान <br />रखेंगे।</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Non preoccuparti,<br />
                <span className="text-glaut-pink font-medium">ci pensiamo <br />noi.</span>
            </p>
        ),
        id: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Jangan khawatir,<br />
                <span className="text-glaut-pink font-medium">kami akan <br />mengurusnya.</span>
            </p>
        ),
        ja: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                ご心配なく、<br />
                <span className="text-glaut-pink font-medium">私たちが<br />お守りします。</span>
            </p>
        ),
        no: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Ikke bekymre deg,<br />
                <span className="text-glaut-pink font-medium">vi tar vare <br />på deg.</span>
            </p>
        ),
        pl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Nie martw się,<br />
                <span className="text-glaut-pink font-medium">zadbamy <br />o ciebie.</span>
            </p>
        ),
        pt: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Não se preocupe,<br />
                <span className="text-glaut-pink font-medium">nós cuidamos <br />de ti.</span>
            </p>
        ),
        pt_BR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Não se preocupe,<br />
                <span className="text-glaut-pink font-medium">nós cuidamos <br />de você.</span>
            </p>
        ),
        ru: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Не волнуйтесь,<br />
                <span className="text-glaut-pink font-medium">мы о вас <br />позаботимся.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                No te preocupes,<br />
                <span className="text-glaut-pink font-medium">nosotros te <br />cubrimos.</span>
            </p>
        ),
        es_AR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                No te preocupes,<br />
                <span className="text-glaut-pink font-medium">nosotros te <br />cubrimos.</span>
            </p>
        ),
        es_MX: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                No te preocupes,<br />
                <span className="text-glaut-pink font-medium">nosotros te <br />cubrimos.</span>
            </p>
        ),
        es_CL: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                No te preocupes,<br />
                <span className="text-glaut-pink font-medium">nosotros te <br />cubrimos.</span>
            </p>
        ),
        es_CO: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                No te preocupes,<br />
                <span className="text-glaut-pink font-medium">nosotros te <br />cubrimos.</span>
            </p>
        ),
        sv: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Oroa dig inte,<br />
                <span className="text-glaut-pink font-medium">vi tar hand <br />om dig.</span>
            </p>
        ),
        tr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Endişelenme,<br />
                <span className="text-glaut-pink font-medium">biz seni <br />koruyoruz.</span>
            </p>
        ),
        uk: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Не хвилюйтеся,<br />
                <span className="text-glaut-pink font-medium">ми про вас <br />подбаємо.</span>
            </p>
        )
    },
    detail: {
        en: "It looks like you've already answered this interview. Thanks for your time!",
        ar: "يبدو أنك قد أجبت على هذه المقابلة بالفعل. شكراً على وقتك!",
        zh: "看来您已经回答过这个面试了。感谢您的时间！",
        hr: "Izgleda da ste već odgovorili na ovaj intervju. Hvala na vašem vremenu!",
        cs: "Vypadá to, že jste na tento rozhovor již odpověděli. Děkujeme za váš čas!",
        nl: "Het lijkt erop dat je dit interview al hebt beantwoord. Bedankt voor je tijd!",
        en_GB: "It looks like you've already answered this interview. Thanks for your time!",
        en_AU: "It looks like you've already answered this interview. Thanks for your time!",
        en_ZA: "It looks like you've already answered this interview. Thanks for your time!",
        en_NG: "It looks like you've already answered this interview. Thanks for your time!",
        en_KE: "It looks like you've already answered this interview. Thanks for your time!",
        fi: "Näyttää siltä, että olet jo vastannut tähän haastatteluun. Kiitos ajastasi!",
        fr: "Il semble que vous ayez déjà répondu à cet entretien. Merci de votre temps !",
        de: "Es scheint, dass Sie dieses Interview bereits beantwortet haben. Danke für Ihre Zeit!",
        de_CH: "Es scheint, dass Sie dieses Interview bereits beantwortet haben. Danke für Ihre Zeit!",
        el: "Φαίνεται ότι έχετε ήδη απαντήσει σε αυτή τη συνέντευξη. Ευχαριστούμε για το χρόνο σας!",
        hi_IN: "ऐसा लगता है कि आप पहले ही इस साक्षात्कार का जवाब दे चुके हैं। आपके समय के लिए धन्यवाद!",
        it: "Sembra che tu abbia già risposto a questa intervista. Grazie per il tuo tempo!",
        id: "Sepertinya Anda sudah menjawab wawancara ini. Terima kasih atas waktu Anda!",
        ja: "このインタビューにはすでに回答されているようです。お時間をいただき、ありがとうございました！",
        no: "Det ser ut til at du allerede har svart på dette intervjuet. Takk for tiden din!",
        pl: "Wygląda na to, że już odpowiedziałeś na ten wywiad. Dziękujemy za poświęcony czas!",
        pt: "Parece que já respondeu a esta entrevista. Obrigado pelo seu tempo!",
        pt_BR: "Parece que você já respondeu a esta entrevista. Obrigado pelo seu tempo!",
        ru: "Похоже, вы уже ответили на это интервью. Спасибо за ваше время!",
        es: "Parece que ya has respondido a esta entrevista. ¡Gracias por tu tiempo!",
        es_AR: "Parece que ya respondiste a esta entrevista. ¡Gracias por tu tiempo!",
        es_MX: "Parece que ya respondiste a esta entrevista. ¡Gracias por tu tiempo!",
        es_CL: "Parece que ya respondiste a esta entrevista. ¡Gracias por tu tiempo!",
        es_CO: "Parece que ya respondiste a esta entrevista. ¡Gracias por tu tiempo!",
        sv: "Det verkar som att du redan har svarat på denna intervju. Tack för din tid!",
        tr: "Bu görüşmeyi daha önce yanıtlamış görünüyorsunuz. Zamanınız için teşekkürler!",
        uk: "Схоже, ви вже відповіли на це інтерв'ю. Дякуємо за ваш час!"
    }
}