export const coding = {
    coding: {
        settings: {
            selectCsv: {
                title: {
                    en: "Import as CSV"
                },
                subtitle: {
                    en: "Import a file containing multiple anserws and analyze its content"
                },
                instructionsTitle: {
                    en: "Formatting instructions:"
                },
                instructions: {
                    en: (<ul>
                        <li>Field names or labels must be listed in the first row (heading)</li>
                        <li>Fields must be delimited by, (comma)</li>
                        <li>The fields can be in any order</li>
                        <li>Field values containing a comma must be enclosed by " (double quotes)</li>
                        <li>Use two double quotes within field values if the value is quoted. For example, A "quoted" word and "Some, ""quotes within"" must be doubled." are both valid.</li>
                        <li>Headings (first line of the CSV):</li>
                        <li>(Choose a form or list to see the headings to use in the CSV)</li>
                    </ul>)
                },
                instructionsFooter: {
                    en: "→ You will have a chance to review the data before finalising the upload."
                },
                uploadCsvFile: {
                    en: "Upload CSV file"
                },
            },
            csvSettings: {
                projectTitle: {
                    en: "Project title"
                },
                projectLanguage: {
                    en: "Project language"
                },
                projectLanguageDescription: {
                    en: "This is the language the project is written and analysed in."
                },
                totalNumberOfAnalysesRun: {
                    en: "Total number of analyses run"
                },
                language: {
                    en: "Language"
                },
                languageDescription: {
                    en: "Select the language the project is written and analyzed in:"
                },
                contextDescription: {
                    en: "Add some context to help Glaut analyze text better"
                },
                contextPlaceholder: {
                    en: "Write here.."
                },
                writeYourQuestion: {
                    en: "Write your questions in Glaut"
                },
                writeYourQuestionText: {
                    en: "What was the prompt that users answered? We asumme that is whatever is written on the header. In order to help Glaut analyze text better, please write down the specific prompt that your respondents saw before responding."
                },
                questionPlaceholder: {
                    en: i => `Question ${i}`
                },
                defaultColumnHeader: {
                    en: i => `column ${i}`
                },
                importData: {
                    en: "Import"
                }
            },
            projectSettings: {
                en: "Project Settings"
            },
            preview: {
                en: "Preview"
            },
        },
        themesAndCodesHeader: {
            en: "Themes and codes",
        },
        results: {
            en: "Results",
        },
        analyses: {
            en: "Analyses"
        },
        newAnalysis: {
            en: "New analysis"
        },
        addToReport: {
            en: "Add to report"
        },
        analysisColumnHeader: {
            en: "Analysis results",
        },
        verbatims: {
            en: "Verbatims"
        },
        back: {
            en: "Back"
        },
        reset: {
            en: "Reset"
        },
        createExclamation: {
            en: "Create!"
        },
        saveExclamation: {
            en: "Save!"
        },
        analysisName: {
            en: "Analysis name"
        },
        analysisNameOptional: {
            en: "Analysis name (optional)"
        },
        theTitleIsUsedToNameTheAnalysis: {
            en: "The title is used to name the analysis and will be shown in the analysis box on the left. If no title is defined, the prompt will be used."
        },
        writeATitleHere: {
            en: "Write your analysis name here..."
        },
        analysisPrompt: {
            en: "Analysis prompt"
        },
        analysisGoal: {
            en: "Analysis goal"
        },
        writeYourPromptHere: {
            en: "Write your prompt here..."
        },
        instructGlautHowToAnalyzeTheOpenData: {
            en: "Instruct Glaut how to analyze the open data."
        },
        hideTips: {
            en: "Hide tips"
        },
        showTips: {
            en: "Show tips"
        },
        thisIsThePromptGlautWillUseToPerformTheAnalysis: {
            en: "Clarify the analysis goal and relevant details as if you were instructing someone else to perform the analysis on your behalf."
        },
        clarifyTheAnalysisGoalAndRelevantDetails: {
            en: "Clarify the analysis goal and relevant details as if you were instructing someone else to perform the analysis on your behalf."
        },
        glautWillUseTheseDescriptionsToClassifyResponses: {
            en: "Glaut will use these descriptions to classify responses to the most fitting category. Make sure the descriptions are accurate and comprehensive to maximize the analysis performance."
        },
        letGlautCreateACodeBook: {
            en: "Let Glaut create a codebook"
        },
        useAnExistingCodeBook: {
            en: "Use an existing codebook"
        },
        interviewQuestionsSelection: {
            en: "Interview questions selection"
        },
        select1To4InterviewQuestionsToRunTheAnalysisOn: {
            en: "Select 1-4 interview questions to run the analysis on. The analysis can only be run on open questions type, so closed questions are not shown in this list."
        },
        existingCodeBook: {
            en: "Existing codebook"
        },
        addTheme: {
            en: "Add theme"
        },
        addEntity: {
            en: "Add entity"
        },
        writeANewThemeHere: {
            en: "Write a new theme here..."
        },
        writeANewCodeHere: {
            en: "Write a new code here..."
        },
        addEditOrDeleteCategoriesDirectly: {
            en: "Add, edit or delete categories directly"
        },
        writeANewCategoryHere: {
            en: "Write a new category here..."
        },
        writeADescriptionHere: {
            en: "Write a description here..."
        },
        addCategory: {
            en: "Add category"
        },
        addDescription: {
            en: "Add description"
        },
        hereIsGlautsProposalOfThemesAndCodes: {
            en: "Here is Glaut’s proposal of themes and codes. You can review and edit it now, or accept it and return to it at any time."
        },
        totalResponses: {
            en: "total responses"
        },
        filterBy: {
            en: "Filter by"
        },
        breakdownBy: {
            en: "Breakdown by"
        },
        switchBetweenNumberOfCitationsAndDistribution: {
            en: "Switch between # of citations and distribution"
        },
        selectAnInterviewQuestion: {
            en: "Select an interview question"
        },
        selectAnAnalysis: {
            en: "Select an analysis"
        },
        setValue: {
            en: "Set value"
        },
        typeHereToSetAValue: {
            en: "Type here to set a value"
        },
        cancel: {
            en: "Cancel"
        },
        save: {
            en: "Save"
        },
        delete: {
            en: "Delete"
        },
        close: {
            en: "Close"
        },
        confirm: {
            en: "Confirm"
        },
        averageScore: {
            en: "Average score"
        },
        createOneAnalysisToStart: {
            en: "Create one analysis to start."
        },
        theAnalysisIsBeingProcessed: {
            en: "The analysis is being processed!"
        },
        resultsWillAppearSoon: {
            en: "Results will appear soon."
        },
        showCodes: {
            en: "Show codes"
        },
        hideCodes: {
            en: "Hide codes"
        },
        noCodesToShow: {
            en: "No codes to show"
        },
        areYouSureYouWantToDeleteThisTheme: {
            en: "Are you sure you want to delete this theme?"
        },
        areYouSureYouWantToDeleteThisCode: {
            en: "Are you sure you want to delete this code?"
        },
        reviewThemesAndCodes: {
            en: "Review themes and codes"
        },
        reviewEntities: {
            en: "Review entities"
        },
        editCategories: {
            en: "Edit categories"
        },
        clickingTheSaveButtonWillTriggerGlaut: {
            en: "Clicking the “Save” button will trigger Glaut to review the current codebook according to the new edits. Depending on the edits this operation might take a while."
        },
        instructionsHelpGlautSelect: {
            en: "Instructions help Glaut select the most relevant codes for a theme."
        },
        manualEdit: {
            en: "Manual edit"
        },
        promptGlaut: {
            en: "Prompt Glaut"
        },
        addEditOrDeleteThemesDirectly: {
            en: "Add, edit or delete themes directly"
        },
        editInstructionsOptional: {
            en: "Edit instructions (optional)"
        },
        editDescription: {
            en: "Edit description"
        },
        describeWhatThisCategoryShouldContainToHelpGlaut: {
            en: "Describe what this category should contain to help Glaut select the most relevant codes"
        },
        instructGlautHowToReviewCurrentThemesOrGenerateANewProposal: {
            en: "Instruct Glaut how to review current themes or generate a new proposal"
        },
        includeInThisThemeAllMentionsAboutABC: {
            en: "Include in this theme all mentions about ABC..."
        },
        createANewXYZThemeThatIncludesAllCodesMentioningABCConcept: {
            en: "Create a new XYZ theme that includes all codes mentioning ABC concept, or reorganize the themes grouping codes according to dimension DEF"
        },
        reviewCodebook: {
            en: "Review codebook"
        },
        typeHereToSearchForACode: {
            en: "Type here to search for a code"
        },
        questionOrAnalysisHeader: {
            en: (question, analysis) => analysis ? `Analysis ${question.index + 1}.${analysis.index + 1} 🔍` : `Question ${question.index + 1}`,
        },
        questionAndAnalysisHeader: {
            en: (question, analysis) => analysis ? `Question ${question.index + 1} - Analysis ${analysis.index + 1}` : `Question ${question.index + 1}`,
        },
        createSubtheme: {
            selectCodes: {
                en: "Select the codes you want to cluster as a sub-theme"
            },
            selectName: {
                en: "What is this sub-theme about?"
            },
            selectNamePlaceholder: {
                en: "Write here the name of your sub-theme..."
            },
            continue: {
                en: "Continue"
            },
            back: {
                en: "Back"
            },
            createSubtheme: {
                en: "Create subtheme"
            },
            confirm: {
                en: "Confirm Sub-theme"
            }
        },
        merge: {
            mergeCodeWithTitle: {
                en: "Select a code to merge:",
            },
            mergeThemeWithTitle: {
                en: "Select a theme to merge:"
            },
            mergeCodeWithDescription: {
                en: "The first selected code will keep the name of the merge.",
            },
            mergeThemeWithDescription: {
                en: "The first selected theme will keep the name of the merge.",
            },
            confirmTitle: {
                en: "Are you sure you want to merge these codes?"
            },
            confirmTitleThemes: {
                en: "Are you sure you want to merge these themes?"
            },
            confirmButton: {
                en: "Confirm"
            },
            cancelButton: {
                en: "Cancel"
            }
        },
        codingMode: {
            fixed: {
                en: "Use existing codebook"
            },
            flexible: {
                en: "Let Glaut create a codebook"
            },
        },
        analyze: {
            en: "Analyze!",
        },
        addHiddenQuestion: {
            en: "Add hidden question"
        },
        addHiddenQuestionOptionsTitle: {
            en: "Decide how to analyze this information"
        },
        addQuestion: {
            en: "Add question",
        },
        pinQuestion: {
            en: "Pin question",
        },
        saveCodebook: {
            en: "Save codebook",
        },
        deleteQuestion: {
            en: "Delete question",
        },
        writeTheNameOfYourNewCode: {
            en: "Write the name of your new code...",
        },
        newThemeName: {
            en: "New theme name",
        },
        confirmTheme: {
            en: "Confirm Theme",
        },
        analysisMenu: {
            openTextCoding: {
                en: "Open Text Coding 🏷️",
            },
            openTextCodingDetail: {
                en: "Write a question to create codes/subcodes with the main concepts within the text",
            },
            multipleChoice: {
                en: "Multiple Choice 🗳️",
            },
            multipleChoiceDetail: {
                en: "Apply the categories from a multiple-options question",
            },
            entities: {
                en: "Entities 📺",
            },
            entitiesDetail: {
                en: "Identify codes of brands and celebrities",
            },
            numerical: {
                en: "NPS ⭐",
            },
            numericalDetail: {
                en: "Identify the numerical score",
            },
        },
        emptyAnalysisColumnMessage: {
            en: "There are no results to show"
        },
        emptyVerbatimsColumnMessage: {
            en: "Nothing to show here"
        },
        filterByTheme: {
            en: "Filter by theme"
        },
        filterByScore: {
            en: "Filter by score"
        },
        editAnalysis: {
            en: "Edit analysis"
        },
        deleteAnalysis: {
            en: "Delete analysis"
        },
        chooseTheTypeOfAnalysisToCreate: {
            en: "Choose the type of analysis to create:"
        },
        addNewThemeAsAThemeCallback: {
            en: (label, type = "theme") => <>Create a new {type === "entity" ? "entity" : "theme"} {label}</>
        }
    },
} as const
