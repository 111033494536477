import { useCallback, useEffect, useRef, useState } from "react"
import * as Sentry from "@sentry/react"

interface IUseCopyToClipboardStringProps {
    string?: string
    timeout?: number
}

export function useCopyToClipboardString({ string, timeout = 3000 }: Readonly<IUseCopyToClipboardStringProps> = {}) {
    // #region States
    const [hasCopied, setHasCopied] = useState(false)
    // #endregion

    // #region Refs
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    // #endregion

    // #region Callbacks
    const copyToClipboard = useCallback((overriddenString?: string) => {
        if (!navigator?.clipboard?.writeText || typeof navigator.clipboard.writeText !== "function") return

        const actualString = overriddenString ?? string
        if (!actualString) return

        if (timeoutRef.current) clearTimeout(timeoutRef.current)

        navigator.clipboard.writeText(actualString).then(() => {
            setHasCopied(true)
            timeoutRef.current = setTimeout(() => { setHasCopied(false) }, timeout)
        }).catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    internalMessage: "Error while copying string to clipboard",
                    copiedString: actualString
                })

                return scope
            })
        })
    }, [string, timeout])
    // #endregion

    // #region Effects

    // onUnmountClearTimeoutIfExists
    useEffect(() => () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }, [])

    // #endregion

    return { hasCopied, copyToClipboard }
}