import { ICategoryStats } from "@/@types/processing/statistics"
import { ISegmentedStats } from "@/@types/segmentation"
import ShowMoreButton from "@components/Buttons/ShowMoreButton"
import PdfIcon from "@components/Pdf/PdfIcon"
import { useToPng } from "@hugocxl/react-to-image"
import { Text, View } from "@react-pdf/renderer"
import { namedColors } from "@utils/Variables"
import {
    extractDescriptionFromSegment,
    extractIdFromSegment,
    extractLabelFromSegment,
    isStatsACategoryStats
} from "@utils/analysis/segmentation"
import { getProjectLang } from "@utils/language"
import {
    normalizeToColorIndex
} from "@utils/styling/colors-from-index"
import { useMemo, useState } from "react"
import { FaHashtag, FaTag } from "react-icons/fa"
import { MdLayers } from "react-icons/md"
import slugify from "slugify"
import { IInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import ReportDetailsInsightBlockDownloadBar from "../ReportDetailsInsightBlockDownloadBar"
import ReportDetailsInsightBlockThemeItem from "../ReportDetailsInsightBlockThemeItem"
import ReportDetailsInsightBlockThemeSegmentedTheme from "../ReportDetailsInsightBlockThemeSegmentedTheme"
import { MAX_INITIAL_ITEMS } from "./constants/max-initial-items"
import { isQuoteFromStats } from "./utils/is-quote-from-stats"

interface IReportDetailsInsightBlockThemesProps {
    block: IInsight
    isDownloadable?: boolean
}

export default function ReportDetailsInsightBlockThemes({
    block,
    isDownloadable = false
}: Readonly<IReportDetailsInsightBlockThemesProps>) {
    // #region Contexts
    const { project, renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region States
    const [isExporting, setIsExporting] = useState(false)
    const [isShowingMore, setIsShowingMore] = useState(false)
    // #endregion

    // #region React to Image
    const [, convertToPng, ref] = useToPng<HTMLDivElement>({
        onStart: () => {
            setIsExporting(true)
        },
        onSuccess: data => {
            const link = document.createElement("a")
            link.download = `${slugify(block.title)}-themes.png`
            link.href = data
            link.click()

            setTimeout(() => {
                link.remove()
                setIsExporting(false)
            }, 500)
        },
        onError: () => {
            setIsExporting(false)
        }
    })
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const isSegmentingApplied = useMemo(() => !!block.segment_by, [block])
    const categoryStats = useMemo(() => block.stats ?? [], [block])
    const formattedThemes = useMemo(() => {
        if (!Array.isArray(categoryStats)) return []

        if (categoryStats.every(isStatsACategoryStats))
            return (categoryStats as ICategoryStats[])
                .filter(stats => extractIdFromSegment(stats.category) !== "empty")
                .map((stats, idx) => ({
                    index: idx,
                    categoryId: extractIdFromSegment(stats.category),
                    label: extractLabelFromSegment(stats.category, lang),
                    description: extractDescriptionFromSegment(stats.category, lang),
                    percentage: stats.frequency,
                    occurrences: stats.occurrences,
                    quotes: block.type === "open"
                        ? block.quotes.filter(quote => isQuoteFromStats(stats, quote))
                        : [],
                    children: []
                })).sort((a, b) => b.occurrences - a.occurrences)

        return (categoryStats as ISegmentedStats[])
            .filter(stats => extractIdFromSegment(stats.segment.category) !== "empty")
            .map((stats, idx) => ({
                index: idx,
                categoryId: extractIdFromSegment(stats.segment.category),
                label: extractLabelFromSegment(stats.segment.category, lang),
                description: extractDescriptionFromSegment(stats.segment.category, lang),
                percentage: stats.segment.frequency,
                occurrences: stats.segment.occurrences,
                quotes: [],
                children: Array.isArray(stats.segmented)
                    ? stats.segmented.map(segmentedStats => ({
                        id: extractIdFromSegment(segmentedStats.category),
                        label: extractLabelFromSegment(segmentedStats.category, lang),
                        frequency: segmentedStats.frequency,
                        occurrences: segmentedStats.occurrences
                    }))
                    : []
            })).sort((a, b) => b.occurrences - a.occurrences)
    }, [categoryStats, lang, block])
    // #endregion

    if (formattedThemes.length === 0) return <></>

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-col bg-glautOffWhite w-full")}>
                {formattedThemes.map(theme => (
                    <View
                        key={theme.categoryId}
                        style={tw("flex flex-col px-[5.09px]")}
                    >
                        <View
                            style={[
                                tw("flex flex-row justify-between w-full border-b-1 py-[11.18px]"),
                                tw("border-b-glautStrokeGlaut")
                            ]}
                        >
                            <View style={tw("flex flex-row items-center gap-[5.09px] max-w-[50%]")}>
                                {isSegmentingApplied && (
                                    <PdfIcon
                                        icon={MdLayers}
                                        color={namedColors[`color${normalizeToColorIndex(theme.index)}`]}
                                        size={"10.18px"}
                                    />
                                )}
                                {!isSegmentingApplied && (
                                    <PdfIcon
                                        icon={FaTag}
                                        color={namedColors[`color${normalizeToColorIndex(theme.index)}`]}
                                        size={"10.18px"}
                                    />
                                )}
                                <Text style={tw("font-sans text-[8.48px] font-medium text-glautTextMidnight")}>
                                    {theme.label ?? ""}
                                </Text>
                            </View>
                            <View style={tw("flex flex-row items-center w-[45%] justify-end")}>
                                <Text style={tw("text-[12.22px] text-glautTextMidnight w-10")}>
                                    {Math.round(theme.percentage * 100)}%
                                </Text>
                                <View style={tw("bg-glautCards h-[2.14px] flex-1 rounded-full")}>
                                    <View style={[tw("h-full rounded-full"), {
                                        backgroundColor: namedColors[`color${normalizeToColorIndex(theme.index)}`],
                                        width: `${theme.percentage * 100}%`
                                    }]}></View>
                                </View>
                                <View style={tw("flex flex-row items-center justify-end gap-[1.91px] w-10")}>
                                    <PdfIcon
                                        icon={FaHashtag}
                                        color={namedColors.grey}
                                        style={{ marginBottom: "2px" }}
                                        size={"8.91px"}
                                    />
                                    <Text style={tw("text-[12.22px] text-glautTextMidnight")}>
                                        {theme.occurrences}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {isSegmentingApplied && (
                            <View
                                style={[
                                    tw("px-[8.48px] py-[5.09px] flex flex-row flex-wrap"),
                                    { rowGap: "10.18px", columnGap: "5.09px" }
                                ]}
                            >
                                {theme.children.map(segmentedTheme => (
                                    <ReportDetailsInsightBlockThemeSegmentedTheme
                                        key={segmentedTheme.id}
                                        colorIndex={theme.index}
                                        label={segmentedTheme.label}
                                        frequency={segmentedTheme.frequency}
                                        occurrences={segmentedTheme.occurrences}
                                    />
                                ))}
                            </View>
                        )}
                    </View>
                ))}
            </View>
        )

    return (
        <div className="flex flex-col gap-[1rem] bg-glaut-off-white p-1" ref={ref}>
            <div className="flex flex-col w-full">
                {formattedThemes.filter((_, idx) => isShowingMore || idx < MAX_INITIAL_ITEMS).map(theme => (
                    <ReportDetailsInsightBlockThemeItem
                        renderAsPdf={renderAsPdf}
                        key={theme.categoryId}
                        block={block}
                        isExporting={isExporting}
                        theme={theme}
                    />
                ))}
                <div className="mt-[0.75rem]">
                    <ShowMoreButton
                        onClick={() => setIsShowingMore(prev => !prev)}
                        asShowLess={isShowingMore}
                        className={formattedThemes.length <= MAX_INITIAL_ITEMS ? "hidden" : ""}
                    />
                </div>
            </div>

            {isDownloadable && (
                <ReportDetailsInsightBlockDownloadBar
                    onDownload={convertToPng}
                    showDownloadButton={!isExporting}
                    withBranding={isExporting}
                />
            )}
        </div>
    )
}