import { getLanguageKey } from "../language"
import { coding } from "./Coding"
import { interview } from "./Interview"
import { reports } from "./Reports"
import { org } from "./Org"
import { workspace } from "./Workspace"
import { project } from "./Project"
import { outline } from "./Outline"
import { interviewQuestions } from "./InterviewQuestions"
import { errors } from "./Errors"
import { dataTab } from "./DataTab"

export const copy = {
    error401: {
        en: "Unauthorized",
        it: "Non autorizzato",
        es: "No autorizado",
        fr: "Non autorisé",
        de: "Nicht autorisiert"
    },
    error401Detail: {
        en: "Seems that you don't have the right permissions to access this page.",
        it: "Sembra che tu non disponga dei permessi necessari per accedere a questa pagina.",
        es: "Parece que no tienes los permisos adecuados para acceder a esta página.",
        fr: "Il semble que vous ne disposez pas des autorisations nécessaires pour accéder à cette page.",
        de: "Offenbar verfügen Sie nicht über die erforderlichen Berechtigungen, um auf diese Seite zuzugreifen."
    },
    error403: {
        en: "Access Denied",
        it: "Accesso vietato",
        es: "Acceso prohibido",
        fr: "Accès interdit",
        de: "Verbotener zugriff"
    },
    error403Detail: {
        en: "Seems that you don't have the right permissions to access this page.",
        it: "Sembra che tu non disponga dei permessi necessari per accedere a questa pagina.",
        es: "Parece que no tienes los permisos adecuados para acceder a esta página.",
        fr: "Il semble que vous ne disposez pas des autorisations nécessaires pour accéder à cette page.",
        de: "Offenbar verfügen Sie nicht über die erforderlichen Berechtigungen, um auf diese Seite zuzugreifen."
    },
    error404: {
        en: "Page not found",
        it: "Pagina non trovata",
        es: "Página no encontrada",
        fr: "Page non trouvée",
        de: "Seite nicht gefunden"
    },
    error404Detail: {
        en: "We are deeply sorry, it looks like the page is no more.",
        it: "Siamo profondamente dispiaciuti, sembra che la pagina non ci sia più.",
        es: "Lo sentimos profundamente, parece que la página ya no existe.",
        fr: "Nous sommes profondément désolés, il semble que la page n'existe plus.",
        de: "Es tut uns sehr leid, es sieht so aus, als ob die Seite nicht mehr existiert."
    },
    // It's returned for invalid path parameters but it makes sense to hide it as 404
    error422: {
        en: "Page not found",
        it: "Pagina non trovata",
        es: "Página no encontrada",
        fr: "Page non trouvée",
        de: "Seite nicht gefunden"
    },
    error422Detail: {
        en: "We are deeply sorry, it looks like the page is no more.",
        it: "Siamo profondamente dispiaciuti, sembra che la pagina non ci sia più.",
        es: "Lo sentimos profundamente, parece que la página ya no existe.",
        fr: "Nous sommes profondément désolés, il semble que la page n'existe plus.",
        de: "Es tut uns sehr leid, es sieht so aus, als ob die Seite nicht mehr existiert."
    },
    error3008: {
        en: (<>The session <br /><b>has expired</b>.</>),
        it: (<>La sessione <br /><b>è scaduta</b>.</>),
        es: (<>La sesión <br /><b>ha expirado</b>.</>),
        fr: (<>La session <br /><b>a expiré</b>.</>),
        de: (<>Die Sitzung <br /><b>ist abgelaufen</b>.</>),
    },
    error3008Detail: {
        en: "Please refresh the page to start again from where you left!",
        it: "Aggiorna la pagina per ricominciare da dove ti eri fermato!",
        es: "¡Actualiza la página para volver a empezar desde donde lo dejaste!",
        fr: "Veuillez rafraîchir la page pour recommencer là où vous vous êtes arrêté !",
        de: "Bitte aktualisieren Sie die Seite, um wieder dort anzufangen, wo Sie aufgehört haben!"
    },
    errorGeneric: {
        en: "Internal issue",
        it: "Errore interno",
        es: "Problema interno",
        fr: "Problème interne",
        de: "Internes Problem"
    },
    errorGenericDetail: {
        en: (<span>Oops! It seems there's a problem loading the page. Don't worry, <b>we're on it!</b></span>),
        it: (<span>Ops! Sembra che ci sia un problema nel caricamento della pagina. Non preoccuparti, <b>ci pensiamo noi!</b></span>),
        es: (<span>¡Ups! Parece que hay un problema al cargar la página. ¡No te preocupes, <b>estamos en ello!</b></span>),
        fr: (<span>Oops! Il semble qu'il y ait un problème lors du chargement de la page. Ne vous inquiétez pas, <b>nous y sommes !</b></span>),
        de: (<span>Hoppla! Es scheint, dass beim Laden der Seite ein Problem vorliegt. Keine Sorge, <b>wir sind dabei!</b></span>)
    },
    errorNetwork: {
        en: "Network error",
        it: "Errore di rete",
        es: "Error de red",
        fr: "Erreur réseau",
        de: "Netzwerkfehler"
    },
    errorNetworkDetail: {
        en: "Unfortunately, there was some error during network communication, please try again.",
        it: "Sfortunatamente si è verificato un errore durante la comunicazione di rete, riprova.",
        es: "Desafortunadamente, hubo algún error durante la comunicación de red, inténtelo nuevamente.",
        fr: "Malheureusement, une erreur s'est produite lors de la communication réseau, veuillez réessayer.",
        de: "Leider ist bei der Netzwerkkommunikation ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
    },
    errorProjectNotFound: {
        en: "This interview is no longer active!",
        it: "Questa intervista non è più attiva!",
        es: "¡Esta entrevista ya no está activa!",
        fr: "Cet entretien n'est plus actif!",
        de: "Dieses Interview ist nicht mehr aktiv!",
    },
    errorProjectNotFoundDetail: {
        en: "Thanks for your availability, it looks like the interview is no more available.",
        it: "Grazie per la tua disponibilità, sembra che l'intervista non sia più disponibile.",
        es: "Gracias por tu disponibilidad, parece que la entrevista ya no está disponible.",
        fr: "Merci pour votre disponibilité, il semble que l'entretien ne soit plus disponible.",
        de: "Vielen Dank für Ihre Verfügbarkeit, es sieht so aus, als ob das Interview nicht mehr verfügbar ist."
    },
    errorProjectUnavailable: {
        en: "This interview is no longer active!",
        it: "Questa intervista non è più attiva!",
        es: "¡Esta entrevista ya no está activa!",
        fr: "Cet entretien n'est plus actif!",
        de: "Dieses Interview ist nicht mehr aktiv!",
    },
    errorProjectUnavailableDetail: {
        en: "Thanks for your availability, it looks like the interview was terminated by the creator.",
        it: "Grazie per la tua disponibilità, sembra che l'intervista sia stata terminata dal creatore.",
        es: "Gracias por tu disponibilidad, parece que la entrevista fue terminada por el creador.",
        fr: "Merci pour votre disponibilité, il semble que l'entretien ait été terminé par le créateur.",
        de: "Vielen Dank für Ihre Verfügbarkeit, es sieht so aus, als ob das Interview vom Ersteller beendet wurde.",
    },
    errorTranscription: {
        en: "Some error happens, just tap again to re-start recording or switch to keyboard mode.",
        it: "Si verifica un errore, tocca di nuovo per riavviare la registrazione o passare alla modalità tastiera.",
        es: "Ocurre algún error, simplemente toque nuevamente para reiniciar la grabación o cambiar al modo de teclado.",
        fr: "Une erreur se produit, appuyez simplement à nouveau pour redémarrer l'enregistrement ou passer en mode clavier.",
        de: "Es tritt ein Fehler auf. Tippen Sie einfach erneut, um die Aufnahme neu zu starten oder in den Tastaturmodus zu wechseln."
    },
    errorPlayback: {
        en: "Playback error",
        it: "Playback error",
        es: "Error de reproducción",
        fr: "Erreur de lecture",
        de: "Wiedergabefehler"
    },
    errorPlaybackDetail: {
        en: "Audio playback may encounter some issues.",
        it: "La riproduzione audio potrebbe riscontrare alcuni problemi.",
        es: "La reproducción de audio puede encontrar algunos problemas.",
        fr: "La lecture audio peut rencontrer certains problèmes.",
        de: "Bei der Audiowiedergabe können einige Probleme auftreten."
    },
    errorIosPlayback: {
        en: "Muted by iOS",
        it: "Silenziato da iOS",
        es: "Silenciado por iOS",
        fr: "Muet par iOS",
        de: "Von iOS stummgeschaltet"
    },
    errorIosPlaybackDetail: {
        en: "When using iOS, audio playback may encounter some issues.",
        it: "Quando si utilizza iOS, la riproduzione audio potrebbe riscontrare alcuni problemi.",
        es: "Cuando se utiliza iOS, la reproducción de audio puede encontrar algunos problemas.",
        fr: "Lors de l'utilisation d'iOS, la lecture audio peut rencontrer certains problèmes.",
        de: "Bei Verwendung von iOS kann es bei der Audiowiedergabe zu Problemen kommen."
    },
    errorNoOrg: {
        en: "Access Denied",
        it: "Accesso vietato",
        es: "Acceso prohibido",
        fr: "Accès interdit",
        de: "Verbotener zugriff"
    },
    errorNoOrgDetail: {
        en: (<span>Sorry! As you are not part of an organization you do not have permission to view this page. Reach out to a referer for further assistance.<br /><br />If you want to use Glaut, get in touch with us at <a href="mailto:info@glaut.com">info@glaut.com</a></span>),
        it: (<span>Scusa! Poiché non fai parte di un'organizzazione, non hai il permesso di visualizzare questa pagina. Rivolgiti a un referente per ulteriore assistenza.<br /><br />Se vuoi utilizzare Glaut, contattaci a <a href="mailto:info@glaut.com">info@glaut.com</a></span>),
        es: (<span>¡Lo siento! Como no eres parte de una organización, no tienes permiso para ver esta página. Comuníquese con un referente para obtener más ayuda.<br /><br />Si quieres utilizar Glaut, ponte en contacto con nosotros en <a href="mailto:info@glaut.com">info@glaut.com</a></span>),
        fr: (<span>Désolé! Comme vous ne faites pas partie d'une organisation, vous n'êtes pas autorisé à consulter cette page. Contactez un référent pour obtenir de l’aide supplémentaire.<br /><br />Si vous souhaitez utiliser Glaut, contactez-nous à <a href="mailto:info@glaut.com">info@glaut.com</a></span>),
        de: (<span>Entschuldigung! Da Sie keiner Organisation angehören, haben Sie keine Berechtigung, diese Seite anzuzeigen. Wenden Sie sich für weitere Unterstützung an einen Überweiser.<br /><br />Wenn Sie Glaut nutzen möchten, kontaktieren Sie uns unter <a href="mailto:info@glaut.com">info@glaut.com</a></span>)
    },
    codes: {
        mergeWith: {
            en: "Merge with..."
        },
        createSubtheme: {
            en: "Create Sub-theme"
        },
        editName: {
            en: "Edit name"
        },
        editInstruction: {
            en: "Edit instruction"
        },
        editTheme: {
            en: "Edit theme"
        },
        changeDescription: {
            en: "Change description"
        },
        selectColor: {
            en: "Select color",
        },
        deleteTheme: {
            en: "Delete theme"
        },
        deleteCode: {
            en: "Delete theme",
        },
        makeTheme: {
            en: "Promote to theme"
        },
        deleteSubcode: {
            en: "Delete code",
        },
    },
    actions: {
        continue: {
            en: "Continue"
        },
        saveExclamation: {
            en: "Save!"
        },
        savedExclamation: {
            en: "Saved!"
        },
        back: {
            en: "Back"
        },
        copyUrl: {
            en: "Copy URL"
        },
        copiedExclamation: {
            en: "Copied!"
        },
        cancel: {
            en: "Cancel"
        },
        delete: {
            en: "Delete"
        },
        addOption: {
            en: "Add option"
        },
        createExclamation: {
            en: "Create!"
        },
        setValue: {
            en: "Set value"
        },
        writeHereEllipsis: {
            en: "Write here..."
        },
        generate: {
            en: "Generate"
        }
    },
    words: {
        yes: {
            en: "Yes"
        },
        no: {
            en: "No"
        },
        delete: {
            en: "Delete"
        },
        cancel: {
            en: "Cancel"
        },
        question: {
            en: "Question"
        },
        advanced: {
            en: "Advanced"
        },
        optional: {
            en: "Optional"
        },
        option: {
            en: "Option"
        },
        logic: {
            en: "Logic"
        },
        qualify: {
            en: "Qualify"
        },
        disqualify: {
            en: "Disqualify"
        },
        completed: {
            en: "Completed"
        },
        uncompleted: {
            en: "Uncompleted"
        },
        quotafull: {
            en: "Quota full"
        },
        screenedOut: {
            en: "Screened out"
        },
        nonCooperative: {
            en: "Non cooperative"
        },
        started: {
            en: "Started"
        },
        rejected: {
            en: "Rejected"
        },
        completionRate: {
            en: "Completion rate"
        },
        status: {
            en: "Status"
        },
        avgInterviewLength: {
            en: "Avg. interview length"
        },
        avgIntervTime: {
            en: "Avg. interv. time"
        },
        refresh: {
            en: "Refresh"
        },
        promoters: {
            en: "Promoters"
        },
        detractors: {
            en: "Detractors"
        },
        nps: {
            en: "NPS"
        },
        averageScore: {
            en: "Average score"
        },
        untitled: {
            en: "Untitled"
        },
        goal: {
            en: "Goal"
        },
        projectOverview: { // the other translations are commented to run the whole codebase translation at once
            en: "Project overview"
            // ar: "نظرة عامة على المشروع",
            // zh: "项目概述",
            // hr: "Pregled projekta",
            // cs: "Přehled projektu",
            // nl: "Projectoverzicht",
            // en_GB: "Project overview",
            // en_AU: "Project overview",
            // en_ZA: "Project overview",
            // en_NG: "Project overview",
            // fi: "Projektin yleiskatsaus",
            // fr: "Aperçu du projet",
            // de: "Projektübersicht",
            // de_CH: "Projektübersicht",
            // el: "Επισκόπηση έργου",
            // hi_IN: "परियोजना अवलोकन",
            // it: "Panoramica del progetto",
            // id: "Ikhtisar proyek",
            // ja: "プロジェクト概要",
            // no: "Prosjektoversikt",
            // pl: "Przegląd projektu",
            // pt: "Visão geral do projeto",
            // pt_BR: "Visão geral do projeto",
            // ru: "Обзор проекта",
            // es: "Resumen del proyecto",
            // es_AR: "Resumen del proyecto",
            // es_MX: "Resumen del proyecto",
            // es_CL: "Resumen del proyecto",
            // es_CO: "Resumen del proyecto",
            // sv: "Projektöversikt",
            // tr: "Proje genel bakışı",
            // uk: "Огляд проекту"
        },
        appendix: {
            en: "Appendix"
        },
        processing: {
            en: "Processing"
        }
    },
    ...errors,
    ...coding,
    ...interview,
    ...reports,
    ...org,
    ...workspace,
    ...outline,
    ...project,
    ...interviewQuestions,
    ...dataTab
} as const

export const getCopy = getLanguageKey