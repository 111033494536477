import { ArrayElement } from "@utils/types/array-element"
import { createContext, useContext, useReducer } from "react"
import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"

// #region Types
interface IAnalysisTabAnalysisColProviderProps {
    children: React.ReactNode
}

interface IAnalysisTabAnalysisColContextState {
    categoryBeingDeleted?: {
        id: string
        type: "theme" | "code"
    }
    categoryBeingEdited?: {
        id: string
        attribute?: "name" | "description"
        type: "theme" | "theme-inline" | "code"
    }
    /**
     * Only codes can be merged for now. `themeId` is the ID of the theme that contains the code.
     */
    categoryBeingMerged?: ICategoryStats<IAnalysisCategory> & {
        themeId: string
        index: number
    }
    targetCategoryBeingMerged?: ICategoryStats<IAnalysisCategory> & {
        themeId: string
        index: number
    }
    /**
     * Sub-themes can only be created with codes and under a theme.
     */
    categoryBeingUsedToCreateSubTheme?: {
        themeId: string
        codes: ICategoryStats<IAnalysisCategory>[]
    }
    /**
     * Code book review data
     */
    codeBook: {
        isInClearState: boolean
        edits: {
            [themeId: string]: {
                newLabel: string
                instruction: string
            }
        }
    }
    /**
     * Flag for showing the add insight to report modal.
     */
    isAddInsightToReportModalOpen: boolean
}

type IAnalysisTabAnalysisColContextAction = {
    type: "set-category-being-deleted"
    category: NonNullable<IAnalysisTabAnalysisColContextState["categoryBeingDeleted"]>
} | {
    type: "clear-category-being-deleted"
} | {
    type: "set-category-being-edited"
    category: NonNullable<IAnalysisTabAnalysisColContextState["categoryBeingEdited"]>
} | {
    type: "clear-category-being-edited"
} | {
    type: "set-category-being-merged"
    category: NonNullable<IAnalysisTabAnalysisColContextState["categoryBeingMerged"]>
} | {
    type: "set-target-category-being-merged"
    category: NonNullable<IAnalysisTabAnalysisColContextState["targetCategoryBeingMerged"]>
} | {
    type: "clear-category-being-merged"
} | {
    type: "set-category-being-used-to-create-sub-theme"
    category: NonNullable<IAnalysisTabAnalysisColContextState["categoryBeingUsedToCreateSubTheme"]>
} | {
    type: "add-code-to-sub-theme-cluster"
    code: ArrayElement<NonNullable<IAnalysisTabAnalysisColContextState["categoryBeingUsedToCreateSubTheme"]>["codes"]>
} | {
    type: "remove-code-from-sub-theme-cluster"
    codeId: string
} | {
    type: "clear-category-being-used-to-create-sub-theme"
} | {
    type: "clear-target-category-being-merged"
} | {
    type: "set-code-book"
    codeBook: IAnalysisTabAnalysisColContextState["codeBook"]["edits"]
} | {
    type: "add-code-book-edit"
    themeId: string
    newLabel: string
    instruction: string
    shouldRemoveIfEmpty?: boolean
} | {
    type: "clear-code-book"
} | {
    type: "open-add-insight-to-report-modal"
} | {
    type: "close-add-insight-to-report-modal"
}
// #endregion

// #region Context definitions
const AnalysisTabAnalysisColContext = createContext(
    {} as IAnalysisTabAnalysisColContextState
)

const AnalysisTabAnalysisColContextDispatch = createContext(
    {} as React.Dispatch<IAnalysisTabAnalysisColContextAction>
)
// #endregion

// #region Hook definitions
export function useAnalysisTabAnalysisCol() {
    return useContext(AnalysisTabAnalysisColContext)
}

export function useAnalysisTabAnalysisColDispatch() {
    return useContext(AnalysisTabAnalysisColContextDispatch)
}
// #endregion

// #region Provider definition
export default function AnalysisTabAnalysisColProvider({
    children
}: Readonly<IAnalysisTabAnalysisColProviderProps>) {
    const initialState: IAnalysisTabAnalysisColContextState = {
        codeBook: { isInClearState: true, edits: {} },
        isAddInsightToReportModalOpen: false
    }

    const [state, dispatch] = useReducer(AnalysisTabAnalysisColReducer, initialState)

    return (
        <AnalysisTabAnalysisColContext.Provider value={state}>
            <AnalysisTabAnalysisColContextDispatch.Provider value={dispatch}>
                {children}
            </AnalysisTabAnalysisColContextDispatch.Provider>
        </AnalysisTabAnalysisColContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function AnalysisTabAnalysisColReducer(
    state: IAnalysisTabAnalysisColContextState,
    action: IAnalysisTabAnalysisColContextAction
): IAnalysisTabAnalysisColContextState {
    switch (action.type) {
        case "set-category-being-deleted": {
            return {
                ...state,
                categoryBeingDeleted: action.category
            }
        }
        case "clear-category-being-deleted": {
            return {
                ...state,
                categoryBeingDeleted: undefined
            }
        }
        case "set-category-being-edited": {
            return {
                ...state,
                categoryBeingEdited: action.category
            }
        }
        case "clear-category-being-edited": {
            return {
                ...state,
                categoryBeingEdited: undefined
            }
        }
        case "set-category-being-merged": {
            return {
                ...state,
                categoryBeingMerged: action.category
            }
        }
        case "set-target-category-being-merged": {
            return {
                ...state,
                targetCategoryBeingMerged: action.category
            }
        }
        case "clear-category-being-merged": {
            return {
                ...state,
                categoryBeingMerged: undefined,
                targetCategoryBeingMerged: undefined
            }
        }
        case "clear-target-category-being-merged": {
            return {
                ...state,
                targetCategoryBeingMerged: undefined
            }
        }
        case "set-category-being-used-to-create-sub-theme": {
            return {
                ...state,
                categoryBeingUsedToCreateSubTheme: action.category
            }
        }
        case "add-code-to-sub-theme-cluster": {
            if (
                !state.categoryBeingUsedToCreateSubTheme ||
                action.code.category.parent !== state.categoryBeingUsedToCreateSubTheme?.themeId // code not from theme
            ) return state

            return {
                ...state,
                categoryBeingUsedToCreateSubTheme: {
                    ...state.categoryBeingUsedToCreateSubTheme,
                    codes: [...state.categoryBeingUsedToCreateSubTheme.codes, action.code]
                }
            }
        }
        case "remove-code-from-sub-theme-cluster": {
            if (!state.categoryBeingUsedToCreateSubTheme) return state

            return {
                ...state,
                categoryBeingUsedToCreateSubTheme: {
                    ...state.categoryBeingUsedToCreateSubTheme,
                    codes: state.categoryBeingUsedToCreateSubTheme.codes.filter(
                        c => c.category.id !== action.codeId
                    )
                }
            }
        }
        case "clear-category-being-used-to-create-sub-theme": {
            return {
                ...state,
                categoryBeingUsedToCreateSubTheme: undefined
            }
        }
        case "add-code-book-edit": {
            if (action.shouldRemoveIfEmpty && !action.newLabel && !action.instruction) {
                // remove edit
                delete state.codeBook.edits[action.themeId]
                return {
                    ...state,
                    codeBook: {
                        ...state.codeBook,
                        isInClearState: false,
                        edits: {
                            ...state.codeBook.edits
                        }
                    }
                }
            }

            return {
                ...state,
                codeBook: {
                    ...state.codeBook,
                    isInClearState: false,
                    edits: {
                        ...state.codeBook.edits,
                        [action.themeId]: {
                            newLabel: action.newLabel,
                            instruction: action.instruction
                        }
                    }
                }
            }
        }
        case "set-code-book": {
            return {
                ...state,
                codeBook: {
                    isInClearState: true,
                    edits: action.codeBook
                }
            }
        }
        case "clear-code-book": {
            return {
                ...state,
                codeBook: { isInClearState: true, edits: {} }
            }
        }
        case "open-add-insight-to-report-modal": {
            return {
                ...state,
                isAddInsightToReportModalOpen: true
            }
        }
        case "close-add-insight-to-report-modal": {
            return {
                ...state,
                isAddInsightToReportModalOpen: false
            }
        }
        default: {
            return state
        }
    }
}
// #endregion