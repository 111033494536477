import { Page, View } from "@react-pdf/renderer"
import { forwardRef, useState } from "react"
import { MdClose } from "react-icons/md"
import Markdown from "react-markdown"
import { IInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { markdownToPdfComponents } from "../../utils/markdown-components"
import DefaultBlockTitle from "../ReportDetailsDefaultBlockTitle"
import ReportDetailsDeleteBlockModal from "../ReportDetailsDeleteBlockModal"
import InsightBlockDetails from "../ReportDetailsInsightBlockDetails"
import { defaultPageTwStyling } from "../../utils/pdf-styling"

interface IReportDetailsInsightBlockTemplateProps {
    id?: string
    block: IInsight
    children?: React.ReactNode
}

const ReportDetailsInsightBlockTemplate = forwardRef<
    HTMLDivElement,
    Readonly<IReportDetailsInsightBlockTemplateProps>
>(({
    id,
    block,
    children
}, ref) => {
    // #region Contexts
    const { renderAsPdf, tw, isReadOnly } = useReportDetails()
    // #endregion

    // #region States
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    if (renderAsPdf)
        return (
            <Page bookmark={block.title} style={tw(defaultPageTwStyling)} size="A4">
                <View style={tw("flex flex-col gap-[15.28px]")}>
                    <DefaultBlockTitle content={block.title} />

                    <InsightBlockDetails block={block} />

                    {children}

                    <Markdown components={markdownToPdfComponents}>
                        {block.narration}
                    </Markdown>
                </View>
            </Page>
        )

    return (
        <div className="flex flex-col gap-[1.5rem] group/block" ref={ref} id={id}>
            <div className="flex justify-between items-center group/block-title">
                <DefaultBlockTitle content={block.title} />
                <MdClose
                    className={`h-[0.875rem] w-[0.875rem] opacity-0 group-hover/block-title:opacity-100 cursor-pointer
                        text-glaut-grey hidden
                    `}
                    onClick={() => { setIsDeleteModalOpen(true) }}
                />
            </div>

            <InsightBlockDetails block={block} />

            {children}

            <Markdown className="markdown text-glaut-dark-grey">
                {block.narration}
            </Markdown>

            {!isReadOnly && (
                <ReportDetailsDeleteBlockModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => { setIsDeleteModalOpen(false) }}
                    block={block}
                />
            )}
        </div>
    )
})

ReportDetailsInsightBlockTemplate.displayName = "ReportDetailsInsightBlockTemplate"

export default ReportDetailsInsightBlockTemplate