import { copy, getCopy } from "@utils/Copy"
import EndingPathsItem from "../OutlineTabContentScreeningAndRedirectsEndingPathsItem"

export default function OutlineTabContentScreeningAndRedirectsEndingPaths() {
    return (
        <div className="flex-1 flex flex-col gap-[0.75rem] h-full">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(copy.outline.screeningAndRedirects.endingPaths)?.toUpperCase()}
            </p>
            <div className="flex flex-col gap-[0.75rem] overflow-y-auto">
                <EndingPathsItem redirectType="complete" />
                <EndingPathsItem redirectType="screenout" />
                <EndingPathsItem redirectType="quotafull" />
                <EndingPathsItem redirectType="uncooperative" />
            </div>
        </div>
    )
}