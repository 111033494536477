import { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import GlautButtonSecondary from "../GlautButtonSecondary"
import { namedColors } from "@utils/Variables"
import Sprinkles from "@components/Icons/Sprinkles"

interface IGlautButtonAiSecondaryProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    withIcon?: boolean
}

export default function GlautButtonAiSecondary({
    disabled,
    withIcon = true,
    children,
    ...props
}: Readonly<IGlautButtonAiSecondaryProps>) {
    return (
        <GlautButtonSecondary disabled={disabled} {...props}>
            {withIcon && <Sprinkles designMode="ai" fill={disabled ? namedColors.grey : undefined} />}
            {children}
        </GlautButtonSecondary>
    )
}