import { useLegacyProject } from "../../contexts/ProjectProvider"
import CollectEmail from "../OutlineTabContentOverviewCollectEmail"
import InterviewLanguages from "../OutlineTabContentOverviewInterviewLanguages"
import InterviewLayout from "../OutlineTabContentOverviewInterviewLayout"
import ModeratorVoice from "../OutlineTabContentOverviewModeratorVoice"
import PrivacyPolicy from "../OutlineTabContentOverviewPrivacyPolicy"
import ProjectContext from "../OutlineTabContentOverviewProjectContext"
import ProjectLanguage from "../OutlineTabContentOverviewProjectLanguage"
import ResponsesTarget from "../OutlineTabContentOverviewResponsesTarget"
import WelcomeMessage from "../OutlineTabContentOverviewWelcomeMessage"

export default function OutlineTabContentOverview() {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    if (!project) return <></>

    return (
        <div className="flex gap-[1.625em] h-full">
            <div className="flex-1 flex flex-col gap-[1.5em] overflow-auto px-1 pb-5">
                <ProjectLanguage />
                <InterviewLanguages />
                <InterviewLayout />
                <ResponsesTarget />
                <ModeratorVoice />
                <CollectEmail />
                <PrivacyPolicy />
            </div>
            <div className="flex-1 flex flex-col gap-[1.5em] overflow-auto px-1 pb-5">
                <ProjectContext />
                <WelcomeMessage />
            </div>
        </div>
    )
}