interface IFaTagsOutlineProps {
    stroke?: string
}

export default function FaTagsOutline({ stroke = "#101720" }: Readonly<IFaTagsOutlineProps>) {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6959 17.1959L11.3423 17.5494L11.6959 17.903L11.7071 17.9142C12.4881 18.6953 13.7545 18.6953
            14.5355 17.9142L20.9142 11.5355C21.6953 10.7545 21.6953 9.48815 20.9142 8.7071L14.2929 2.08579C13.9178
            1.71072 13.4091 1.5 12.8787 1.5H11.3562H10.149L11.0026 2.35355L17.1352 8.48611C17.1352 8.48611 17.1352
            8.48611 17.1352 8.48612C17.5726 8.92357 17.8125 9.50268 17.8125 10.1213C17.8125 10.74 17.5726 11.3191
            17.1352 11.7565L11.6959 17.1959ZM16.9142 8.7071L10.2929 2.08579C9.91783 1.71072 9.40912 1.5 8.87869
            1.5H2.5C1.39542 1.5 0.5 2.39542 0.5 3.5V9.87869C0.500003 10.4091 0.710718 10.9178 1.08579 11.2929L7.70709
            17.9142C7.7071 17.9142 7.7071 17.9142 7.7071 17.9142C8.48812 18.6953 9.75445 18.6953 10.5355 17.9142L16.9142
            11.5355C17.6953 10.7545 17.6953 9.48815 16.9142 8.7071ZM4.5 6.5C3.9477 6.5 3.5 6.0523 3.5 5.5C3.5 4.9477
            3.9477 4.5 4.5 4.5C5.0523 4.5 5.5 4.9477 5.5 5.5C5.5 6.05229 5.05229 6.5 4.5 6.5Z" stroke={stroke} />
        </svg>

    )
}
