import ConfirmModal from "@components/Modals/ConfirmModal"
import { createContext, useContext, useEffect, useReducer } from "react"
import { v4 as uuidv4 } from "uuid"

// #region Type definitions
interface ConfirmationModalProviderProps {
    children: React.ReactNode
}

interface ConfirmationModalContextState {
    modalStates: {
        id: string
        isOpen: boolean
        message: string
        onConfirm: () => void
        onConfirmLabel?: string
        onCloseLabel?: string
    }[]
    modalsToOpen: string[]
    modalsToRemove: string[]
}

type ConfirmationModalContextAction = {
    type: "create-confirmation-modal"
    message: string
    onConfirm: () => void
    onConfirmLabel?: string
    onCloseLabel?: string
} | {
    type: "hide-modal"
    id: string
} | {
    type: "clear-closed-modals"
} | {
    type: "clear-opened-modals"
}
// #endregion

// #region Context definitions
const ConfirmationModalContext = createContext(
    {} as ConfirmationModalContextState
)
const ConfirmationModalContextDispatch = createContext(
    {} as React.Dispatch<ConfirmationModalContextAction>
)
// #endregion

// #region Hook definitions
export function useConfirmationModalDispatch() {
    return useContext(ConfirmationModalContextDispatch)
}
// #endregion

// #region Provider definition
export default function ConfirmationModalProvider({
    children
}: Readonly<ConfirmationModalProviderProps>) {
    const initialState: ConfirmationModalContextState = {
        modalStates: [],
        modalsToOpen: [],
        modalsToRemove: []
    }

    const [state, dispatch] = useReducer(ConfirmationModalReducer, initialState)

    // #region Effects

    // onNewModalOpenIt
    useEffect(() => {
        // This effect is necessary for the modal opening animation to happen
        // => on creation: isOpen = false; on rerender: isOpen = true
        if (!state.modalsToOpen.length) return
        dispatch({ type: "clear-opened-modals" })
    }, [state.modalsToOpen])

    // onCloseModalRemoveIt
    useEffect(() => {
        // This effect is necessary for the modal closing animation to happen
        if (!state.modalsToRemove.length) return
        // Timeout for UX
        setTimeout(() => {
            dispatch({ type: "clear-closed-modals" })
        }, 150)
    }, [state.modalsToRemove])

    // #endregion

    return (
        <ConfirmationModalContext.Provider value={state}>
            <ConfirmationModalContextDispatch.Provider value={dispatch}>
                {children}
                {state.modalStates.map(modalState => (
                    <ConfirmModal
                        key={modalState.message}
                        open={modalState.isOpen}
                        onClose={() => dispatch({ type: "hide-modal", id: modalState.id })}
                        onCloseLabel={modalState.onCloseLabel}
                        onConfirm={modalState.onConfirm}
                        onConfirmLabel={modalState.onConfirmLabel}
                    >
                        <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                            {modalState.message}
                        </p>
                    </ConfirmModal>
                ))}
            </ConfirmationModalContextDispatch.Provider>
        </ConfirmationModalContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ConfirmationModalReducer(
    state: ConfirmationModalContextState,
    action: ConfirmationModalContextAction
): ConfirmationModalContextState {
    switch (action.type) {
        case "create-confirmation-modal": {
            const modalId = uuidv4()

            return {
                ...state,
                modalStates: [
                    ...state.modalStates,
                    {
                        id: modalId,
                        isOpen: false,
                        message: action.message,
                        onCloseLabel: action.onCloseLabel,
                        onConfirm: action.onConfirm,
                        onConfirmLabel: action.onConfirmLabel
                    }
                ],
                modalsToOpen: [...state.modalsToOpen, modalId]
            }
        }
        case "hide-modal": {
            const modalStateIndex = state.modalStates.findIndex(modalState => modalState.id === action.id)
            if (modalStateIndex === -1) return state

            const newModalStates = [...state.modalStates]
            newModalStates[modalStateIndex].isOpen = false

            return {
                ...state,
                modalStates: newModalStates,
                // make sure to mark it to be removed from available modals
                modalsToRemove: [...state.modalsToRemove, action.id]
            }
        }
        case "clear-closed-modals": {
            return {
                ...state,
                modalStates: state.modalStates.filter(modalState => modalState.isOpen),
                modalsToRemove: []
            }
        }
        case "clear-opened-modals": {
            const newModalStates = state.modalStates.map(modalState => ({
                ...modalState,
                isOpen: state.modalsToOpen.includes(modalState.id)
            }))

            return {
                ...state,
                modalStates: newModalStates,
                modalsToOpen: []
            }
        }
        default: {
            return state
        }
    }
}
// #endregion