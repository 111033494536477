import { IOpenInsight } from "src/@types/reports"
import InsightBlockTemplate from "../ReportDetailsInsightBlockTemplate"
import InsightBlockThemes from "../ReportDetailsInsightBlockThemes"
import { forwardRef } from "react"

interface IReportDetailsOpenInsightBlockProps {
    id?: string
    block: IOpenInsight
}

const ReportDetailsOpenInsightBlock = forwardRef<
    HTMLDivElement,
    Readonly<IReportDetailsOpenInsightBlockProps>
>(({
    id,
    block
}, ref) => (
    <InsightBlockTemplate block={block} ref={ref} id={id}>
        <InsightBlockThemes block={block} isDownloadable />
    </InsightBlockTemplate>
))

ReportDetailsOpenInsightBlock.displayName = "ReportDetailsOpenInsightBlock"

export default ReportDetailsOpenInsightBlock