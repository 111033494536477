import { ILang } from "@/@types/interview-question"
import { interviewSettingsTabProjectsControlCopy as copy } from "../utils/copy"
import { IProjectFeatures } from "@/@types/project"

export interface IFeatureFlag {
    id: string
    slug: keyof IProjectFeatures | "allow_multiple_submissions"
    title: ILang
}

export const featureFlags: IFeatureFlag[] = [
    {
        id: "hideGlautLogo",
        slug: "hide_branding",
        title: copy.hideGlautLogo
    },
    {
        id: "activateWebRtc",
        slug: "web_rtc",
        title: copy.activateWebRtc
    },
    {
        id: "includeUncompleted",
        slug: "include_uncompleted",
        title: copy.includeUncompleted
    },
    {
        id: "allowMultipleSubmissions",
        slug: "allow_multiple_submissions",
        title: copy.allowMultipleSubmissions
    }
]