import GlautWideLogo from "@assets/glaut-wide-logo.svg"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { MdOutlineFileDownload } from "react-icons/md"

interface IReportDetailsInsightBlockDownloadBarProps {
    onDownload: () => void
    showDownloadButton?: boolean
    withBranding?: boolean
}

export default function ReportDetailsInsightBlockDownloadBar({
    onDownload,
    showDownloadButton = true,
    withBranding = false
}: Readonly<IReportDetailsInsightBlockDownloadBarProps>) {
    return (
        <div className="relative w-full h-[1.5rem]">
            {withBranding && (
                <div className="absolute top-1/2 -translate-y-1/2 left-0 flex items-center gap-[0.3125em]">
                    <p className="text-[11.11px] font-medium text-glaut-light-grey">
                        Built with
                    </p>
                    <img src={GlautWideLogo} alt="glaut" />
                </div>
            )}
            {showDownloadButton && (
                <GlautButtonSecondary onClick={onDownload} className="absolute top-1/2 -translate-y-1/2 right-0">
                    <MdOutlineFileDownload className="w-[1.125em] h-[1.125em]" />
                    <p className="text-[11.11px] font-medium">Download</p>
                </GlautButtonSecondary>
            )}
        </div>
    )
}