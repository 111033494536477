import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautCommonQuestionCreationForm from "@components/Forms/GlautCommonQuestionCreationForm"
import GlautIcon from "@components/Icons/GlautIcon"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import ModalLayout from "@components/layouts/ModalLayout"
import Loading from "@components/loading/Loading"
import { useProjectService } from "@hooks/services/useProjectService"
import { useEditQuestion } from "@hooks/useEditQuestion"
import { useLegacyProject, useProject, useProjectDispatch } from "@pages/Project/ProjectArea/contexts/ProjectProvider"
import { deepCopy } from "@utils/miscellaneous"
import { RecursivePartial } from "@utils/types/recursive-partial"
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react"
import { MdClose, MdSettings } from "react-icons/md"
import {
    IInterviewQuestion,
    IQuestion,
    IQuestionDomain,
    IQuestionType,
    ISelectScreeningQuestion,
    NPS_MAX_VALUE,
    NPS_MIN_VALUE
} from "@/@types/interview-question"
import { v4 as uuid } from "uuid"
import { normalizeQuestionOrOption } from "../../../utils/strings"
import ImageInput from "../../inputs/ImageInput"
import EditMultipleChoice from "../EditMultipleChoice"
import EditInsight from "./components/EditInsights"
import EditScreening from "./components/EditScreening"
import { QuestionFilters } from "./components/QuestionFilter"
import usePrevProps from "@hooks/usePrevProps"

const selectQuestionType = [
    {
        slug: "open",
        title: "💬 Open",
        description: "Respondent can answer openly, either by voice or text"
    },
    {
        slug: "select",
        title: "🗳️ Select",
        description: "Respondent has to select one or more options (multiple-choice)"
    },
    {
        slug: "nps",
        title: "⭐ Rating",
        description: "Respondent has to give a 0-10 rating"
    }
] as const

interface IEditQuestionPopupProps {
    defaultType?: IQuestionType
    defaultDomain?: IQuestionDomain
    originalQuestion?: RecursivePartial<IQuestion>
    isOpen?: boolean
    onClose: () => void
    lang: string
    disabled?: boolean
}

export default function EditQuestionPopup({
    defaultType,
    defaultDomain,
    originalQuestion,
    onClose,
    isOpen = true,
    lang,
    disabled = false
}: Readonly<IEditQuestionPopupProps>) {
    // #region Contexts
    const { project, lang: projectLang } = useLegacyProject()
    const { projectQuestions, screeningQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Initializers
    const defaultEmptyQuestion = useCallback(() => ({
        content: {},
        deleted: false,
        img: undefined,
        embed_url: undefined,
        header: undefined,
        filters: [],
        ...(defaultDomain === "screening" ? {
            type: "select" as const,
            domain: "screening" as const
        } : {
            type: defaultType ?? undefined,
            domain: defaultDomain
        })
    }), [defaultDomain, defaultType])
    // #endregion

    // #region Prev props
    const prevOriginalQuestionId = usePrevProps(originalQuestion?.id)
    // #endregion

    // #region States

    const [question, setQuestion] = useState<RecursivePartial<IQuestion>>(
        () => originalQuestion ?? defaultEmptyQuestion()
    )
    const [showAdvanced, setShowAdvanced] = useState(false)

    // State to hold scroll value
    const [scroll, setScroll] = useState(0)

    const [isSaving, setIsSaving] = useState(false)

    // #endregion

    // #region Refs
    const parentRef = useRef<HTMLDivElement>(null)
    const textareaQuestionLabelRef = useRef<HTMLTextAreaElement>(null)
    // #endregion

    // #region Memos
    const questionTitle = useMemo(() => question.content?.[projectLang], [projectLang, question.content])
    const isNew = useMemo(() => question.id === undefined, [question.id])

    const assetsValue = useMemo(() => ({
        image: question.img,
        video: question.embed_url,
        "url-parameter": question.header
    }), [question.embed_url, question.header, question.img])

    const questionIdx = useMemo(() => {
        if (!question.id) return -1

        const idx = (projectQuestions ?? []).findIndex(q => q.id === question.id)
        return idx === -1 ? (projectQuestions?.length ?? 0) : idx
    }, [projectQuestions, question.id])

    const shouldAllowAddingFilters = useMemo(() => {
        if (question.domain === "screening")
            return screeningQuestions?.find(
                (iq, idx) => (questionIdx === -1 || idx < questionIdx) && ["select", "nps", "scale"].includes(iq.type)
            ) !== undefined

        return projectQuestions?.find(
            (iq, idx) => (questionIdx === -1 || idx < questionIdx) && (["select", "nps", "scale"].includes(iq.type))
        ) !== undefined
    }, [projectQuestions, question.domain, questionIdx, screeningQuestions])
    // #endregion

    // #region Custom hooks
    const { valid } = useEditQuestion(question, lang, project?.general_settings)
    // #endregion

    // #region Callbacks
    const isScreeningOptionNew = useCallback((id: string) => {
        // This method checks if the option with id `id` is a new one specifically for screening questions

        // If the id is empty, it means that the option is new
        if (!id.length) return true

        // If the question is not a screening question or the type is not select, defaults to true
        if (originalQuestion?.domain !== "screening" || originalQuestion?.type !== "select") return true

        // If the option is not found in the original question, it means that it is new
        return (originalQuestion.options ?? []).find(o => o.id === id) === undefined
    }, [originalQuestion])

    const handleClosePopup = useCallback(() => {
        setQuestion(defaultEmptyQuestion())
        onClose()
    }, [onClose, defaultEmptyQuestion])

    const handleSubmitQuestion = useCallback(() => {
        if (!project || !valid) return

        // Prepare payload
        const savedQuestion = deepCopy(question)
        savedQuestion.project_id = project._id

        // SCALE => ignore for now
        if (savedQuestion.type === "scale")
            return

        // OPEN => enforce `null` value when it hasn't been defined
        if (savedQuestion.type === "open")
            savedQuestion.probing_instructions = savedQuestion.probing_instructions ?? null

        // SELECT => Delete empty options
        if (savedQuestion.type === "select")
            if (savedQuestion.domain === "interview") {
                savedQuestion.options = savedQuestion.options?.filter(b => b.label?.[projectLang]) ?? []
            }
            else if (savedQuestion.domain === "screening") {
                // SCREENING => + force max_choices to 1
                if (savedQuestion.screening_type === "screener")
                    savedQuestion.options = savedQuestion.options?.filter(
                        b => b.label?.[projectLang] && b.action
                    ).map(b => isScreeningOptionNew(b.id ?? "") ? { ...b, id: "" } : b) ?? []
                if (savedQuestion.screening_type === "quota")
                    savedQuestion.options = savedQuestion.options?.filter(
                        b => b.label?.[projectLang] && typeof b.quota === "number"
                    ).map(b => isScreeningOptionNew(b.id ?? "") ? { ...b, id: "" } : b) ?? []

                savedQuestion.max_choices = 1
            }

        // COMMON => delete invalid filters
        if (question.filters)
            savedQuestion.filters = savedQuestion.filters?.filter(f => f.id && f.operator && f.value) ?? []

        // COMMON => parse assets
        savedQuestion.img = question.img ?? null
        savedQuestion.embed_url = question.embed_url ?? null
        savedQuestion.header = question.header ?? ""

        if (savedQuestion.id) {
            setIsSaving(true)

            projectService.updateInterviewQuestion({
                projectId: project._id,
                questionId: savedQuestion.id,
                data: savedQuestion as IInterviewQuestion
            }).then(response => {
                projectDispatch({ type: "update-interview-question", interviewQuestion: response })
                handleClosePopup()
            }).finally(() => {
                setIsSaving(false)
            })

            return
        }

        projectService.createInterviewQuestion({
            projectId: project._id,
            data: savedQuestion as IInterviewQuestion
        }).then(response => {
            projectDispatch({ type: "add-interview-question", interviewQuestion: response })
            handleClosePopup()
        })
    }, [
        handleClosePopup,
        isScreeningOptionNew,
        projectDispatch,
        project,
        projectLang,
        projectService,
        question,
        valid
    ])

    const handleCancelOperation = useCallback(() => {
        if (!project) return

        if (!isNew)
            projectService.deleteInterviewQuestion({
                projectId: project._id,
                questionId: question.id!
            }).then(() => {
                projectDispatch({ type: "delete-interview-question", interviewQuestionId: question.id! })
            })

        handleClosePopup()
    }, [project, isNew, projectService, question.id, handleClosePopup, projectDispatch])

    const handleSelectQuestionType = useCallback((type: IQuestionType) => {
        switch (type) {
            case "nps": {
                setQuestion(p => ({ ...p, type, domain: "interview", min: NPS_MIN_VALUE, max: NPS_MAX_VALUE }))
                break
            }
            case "scale": {
                setQuestion(p => ({ ...p, type, domain: "interview", min: undefined, max: undefined }))
                break
            }
            case "open": {
                setQuestion(p => ({ ...p, type, domain: "interview" }))
                break
            }
            case "select": {
                setQuestion(p => ({ ...p, type, domain: undefined }))
                break
            }
        }
    }, [])

    const setImage = useCallback((img: string | undefined) => setQuestion(q => ({ ...q, img: img ?? null })), [])

    const deleteOption = (optionId: string) => {
        setQuestion(p => {
            if (p.type !== "select") return p

            const newOptions = p?.options?.filter(o => o.id !== optionId) ?? []
            return {
                ...p,
                options: newOptions,
                max_choices: Math.min(p.max_choices ?? 1, newOptions.length),
                domain: p.domain
            } as RecursivePartial<IQuestion>
        })
    }

    const handleChangeQuestionContent = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, normalize: boolean) =>
        setQuestion(q => ({
            ...q,
            content: {
                ...q.content,
                [projectLang]: normalize ? normalizeQuestionOrOption(e.target.value) : e.target.value
            }
        }))

    const handleChangeImage = useCallback((image: File | null | undefined) => {
        if (!project || !question) return

        if (!image) {
            setQuestion(q => ({ ...q, img: image }))
            return
        }

        const projectId = project._id
        const questionId = question.id ?? uuid()

        projectService.setInterviewQuestionImage({ projectId, questionId, image }).then(url => {
            setQuestion(q => ({ ...q, img: url }))
        })
    }, [project, question, setQuestion, projectService])

    const handleChangeVideo = useCallback((url: string | null | undefined) => {
        setQuestion(q => ({ ...q, embed_url: url }))
    }, [setQuestion])

    const handleChangeUrlParameter = useCallback((value: string | null | undefined) => {
        setQuestion(q => ({ ...q, header: value }))
    }, [setQuestion])
    // #endregion

    // #region Effects

    // Add event listener for scroll event
    useEffect(() => {
        // Function to handle scroll event
        if (parentRef.current === null) return

        const handleScroll = () => setScroll(parentRef.current!.scrollTop)

        const parent = parentRef.current
        parent.addEventListener("scroll", handleScroll)

        // Clean up listener on unmount
        return () => { parent.removeEventListener("scroll", handleScroll) }
    }, [])

    // Auto resizes the question textbox
    useEffect(() => {
        if (!textareaQuestionLabelRef.current) return

        textareaQuestionLabelRef.current.style.height = "auto"
        textareaQuestionLabelRef.current.style.height = `${textareaQuestionLabelRef.current.scrollHeight}px`
    }, [questionTitle])

    // onOpenSetDefaultQuestion
    useEffect(() => {
        if (!isOpen || prevOriginalQuestionId === originalQuestion?.id) return

        setQuestion(originalQuestion ?? defaultEmptyQuestion())
    }, [isOpen, defaultEmptyQuestion, originalQuestion, prevOriginalQuestionId])

    // #endregion

    if (!project) return <></>

    return (
        <ModalLayout
            onClose={handleClosePopup}
            isOpen={isOpen}
            className={`flex flex-col rounded-[4px] w-[456px] max-h-screen
                border-glaut-stroke bg-white shadow-[0_0_8px_0_rgba(0,0,0,0.15)] border-[1px]
                overflow-auto no-scrollbar
                ${!question.type ? "py-[10px] px-[15px]" : "py-[18px] px-[24px]"}
            `}
        >
            {question.type && (
                <>
                    <div className="flex flex-row justify-between items-center w-full">
                        <p className="text-[16px] text-glaut-bar">
                            {selectQuestionType.find(
                                t => t.slug === (question.domain !== "screening" ? question.type : "select")
                            )?.title?.toUpperCase()}
                        </p>
                        {question.domain !== "screening" && (
                            <div
                                className="flex flex-row gap-[5px] items-center"
                                onClick={() => setShowAdvanced(s => !s)}
                            >
                                <span
                                    className={`text-[11.11px] font-medium underline cursor-pointer
                                        text-glaut-stroke-button`}
                                >
                                    {showAdvanced ? "Hide" : "Show"} advanced settings
                                </span>
                                <MdSettings className="text-glaut-stroke-button" />
                            </div>
                        )}
                    </div>
                    {showAdvanced && question.domain !== "screening" && (
                        <>
                            {question.img ? (
                                <div className="flex flex-col items-center gap-[0.5rem] mt-[0.5rem]">
                                    {question.img && (
                                        <img
                                            alt="question-img"
                                            src={question.img}
                                            className="text-center"
                                            style={{
                                                maxWidth: "100%",
                                                borderRadius: "var(--border-radius)"
                                            }}
                                        />
                                    )}
                                    <button
                                        className={["secondary-button danger", disabled ? "disabled" : ""].join(" ")}
                                        disabled={disabled}
                                        onClick={() => !disabled && setImage(undefined)}
                                    >
                                        Clear
                                    </button>
                                </div>
                            ) : (question.id && project && (
                                <div className="flex flex-row justify-center">
                                    <ImageInput
                                        projectId={project._id}
                                        imageId={question.id ?? uuid()}
                                        setUrl={setImage}
                                        disabled={disabled}
                                    />
                                </div>
                            ))}
                            <QuestionFilters
                                question={question}
                                setQuestion={setQuestion}
                                lang={lang || projectLang}
                                disabled={disabled}
                            />
                        </>
                    )}
                    {question.type === "select" && question.domain === "screening"
                        ? (
                            <div className="mt-[0.75rem]">
                                <GlautCommonQuestionCreationForm
                                    iqToBeBuilt={question}
                                    shouldAllowAddingFilters={shouldAllowAddingFilters}
                                    lang={lang}
                                    assets={{
                                        values: assetsValue,
                                        onChange: {
                                            image: handleChangeImage,
                                            video: handleChangeVideo,
                                            "url-parameter": handleChangeUrlParameter
                                        }
                                    }}
                                    title={{
                                        value: question.content?.[lang] ?? "",
                                        onChange: e => handleChangeQuestionContent(e, false)
                                    }}
                                    setQuestion={setQuestion}
                                    disabled={disabled}
                                    filtersDomain="screening"
                                />
                            </div>
                        ) : (
                            <GlautTextAreaMultiLine
                                placeholder="Write you question here..."
                                withBiggerTextSize
                                ref={textareaQuestionLabelRef}
                                value={question.content?.[projectLang] ?? ""}
                                onChange={e => handleChangeQuestionContent(e, false)}
                                onBlur={e => { handleChangeQuestionContent(e, true) }}
                                disabled={disabled}
                            />
                        )
                    }
                </>
            )}
            {!question.type && (
                <div>
                    <div className="flex flex-row w-full justify-between">
                        <p className="text-[11.11px] font-medium text-glaut-midnight">
                            Choose the type of question to create:
                        </p>
                        <MdClose
                            className="text-glaut-midnight w-[14px] h-[14px] hover:bg-glaut-cards cursor-pointer"
                            onClick={onClose}
                        />
                    </div>
                    {selectQuestionType.map(o => (
                        <div
                            key={o.slug}
                            onClick={() => { handleSelectQuestionType(o.slug) }}
                            className={`flex flex-col gap-[4px] p-[8px] w-full rounded-none cursor-pointer
                                border-t-[1px] border-t-glaut-cards
                                hover:bg-glaut-cards
                            `}
                        >
                            <p className="text-[13.33px] font-medium text-[#101720]">
                                {o.title}
                            </p>
                            <span className="text-[11.11px] font-medium text-[#666666]">
                                {o.description}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            {question.type === "select" && question.domain === "interview" && (
                <EditMultipleChoice
                    question={question}
                    setQuestion={setQuestion}
                    deleteOption={deleteOption}
                    scroll={scroll}
                    lang={projectLang}
                    disabled={disabled}
                />
            )}
            {question.type === "select" && question.domain === "screening" && (
                <EditScreening
                    question={question}
                    setQuestion={
                        setQuestion as React.Dispatch<React.SetStateAction<RecursivePartial<ISelectScreeningQuestion>>>
                    }
                    disabled={disabled}
                    lang={projectLang}
                />
            )}
            {question.type === "open" && (
                <EditInsight
                    question={question as Partial<IInterviewQuestion>}
                    setQuestion={setQuestion as Dispatch<SetStateAction<Partial<IInterviewQuestion>>>}
                    disabled={disabled}
                />
            )}
            <div className="flex flex-row justify-between mt-[18px]">
                <GlautButtonSecondary onClick={handleCancelOperation} disabled={disabled}>
                    <p className="text-[13.33px] font-medium">{isNew ? "Cancel" : "Delete question"}</p>
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={handleSubmitQuestion} disabled={isSaving || disabled || !valid}>
                    {!isSaving && (
                        <>
                            <GlautIcon
                                width={24}
                                height={24}
                                fill={
                                    !(disabled || !valid) ? "var(--color-glaut-off-white)" : "var(--color-glaut-grey)"
                                }
                            />
                            <p className="text-[16px] font-medium">{isNew ? "Create!" : "Save"}</p>
                        </>
                    )}
                    {isSaving && (<Loading />)}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}
