import { IGoal } from "@/@types/reports"
import InsightsList from "../ReportDetailsInsightsList"
import { copy, getCopy } from "@utils/Copy"
import ContentStandardTextSession from "../ReportDetailsContentStandardTextSession"
import { useState } from "react"
import { MdChevronRight } from "react-icons/md"
import { reportDetailsContentGoalCopy } from "./utils/copy"
import slugify from "slugify"

interface IReportDetailsContentGoalProps {
    goal: IGoal
    /**
     * 0-based index.
     */
    index: number
}

export default function ReportDetailsContentGoal({
    goal,
    index
}: Readonly<IReportDetailsContentGoalProps>) {
    // #region States
    const [isExploreInsightsExpanded, setIsExploreInsightsExpanded] = useState(false)
    // #endregion

    return (
        <div className="flex flex-col gap-[1.5em]" id={slugify(goal.title)}>
            <ContentStandardTextSession
                upperTitle={`${getCopy(copy.words.goal)} #${index + 1}`}
                title={goal.title}
                content={goal.findings}
                size="large"
                withTitleUnderline
            />
            <button
                type="button"
                className={`flex justify-start items-center gap-[0.75rem] p-[1rem] rounded-[0.5rem] border-1 w-full
                    transition-all group
                    m-0 shadow-none border-transparent
                    ${!isExploreInsightsExpanded ? "bg-glaut-cards" : ""}
                    ${isExploreInsightsExpanded ? "bg-glaut-very-light-grey" : ""}
                    hover:bg-glaut-very-light-grey hover:border-glaut-stroke-glaut
                `}
                onClick={() => setIsExploreInsightsExpanded(prev => !prev)}
            >
                <MdChevronRight
                    className={`h-[1.125rem] w-[1.125rem]
                        ${!isExploreInsightsExpanded ? "text-glaut-text-midnight" : ""}
                        ${isExploreInsightsExpanded ? "text-glaut-pink-dark rotate-90" : ""}
                        group-hover:text-glaut-pink-dark
                    `}
                />
                <p className={`text-[13.33px] font-medium 
                    ${!isExploreInsightsExpanded ? "text-glaut-dark-grey" : ""}
                    ${isExploreInsightsExpanded ? "text-glaut-pink-dark" : ""}
                    group-hover:text-glaut-pink-dark
                `}>
                    {getCopy(reportDetailsContentGoalCopy.exploreInsights)}
                </p>
            </button>
            {isExploreInsightsExpanded && <InsightsList insights={goal.insights} />}
        </div>
    )
}