export const interviewTerminatedQuotaFullCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        ar: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                عذراً!<br />
                جميع المقاعد<br />
                <span className="text-glaut-pink font-medium">محجوزة.</span>
            </p>
        ),
        zh: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                抱歉！<br />
                所有座位<br />
                <span className="text-glaut-pink font-medium">已被占用。</span>
            </p>
        ),
        hr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Žao nam je!<br />
                Sva mjesta<br />
                <span className="text-glaut-pink font-medium">su zauzeta.</span>
            </p>
        ),
        cs: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Omlouváme se!<br />
                Všechna místa<br />
                <span className="text-glaut-pink font-medium">jsou obsazena.</span>
            </p>
        ),
        nl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                Alle plaatsen<br />
                <span className="text-glaut-pink font-medium">zijn bezet.</span>
            </p>
        ),
        en_GB: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        en_AU: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        en_ZA: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        en_NG: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        en_KE: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        fi: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Anteeksi!<br />
                Kaikki paikat<br />
                <span className="text-glaut-pink font-medium">ovat varattuja.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Désolé !<br />
                Toutes les places<br />
                <span className="text-glaut-pink font-medium">sont prises.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Entschuldigung!<br />
                Alle Plätze<br />
                <span className="text-glaut-pink font-medium">sind belegt.</span>
            </p>
        ),
        de_CH: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Entschuldigung!<br />
                Alle Plätze<br />
                <span className="text-glaut-pink font-medium">sind belegt.</span>
            </p>
        ),
        el: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Συγγνώμη!<br />
                Όλες οι θέσεις<br />
                <span className="text-glaut-pink font-medium">είναι κατειλημμένες.</span>
            </p>
        ),
        hi_IN: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                माफ़ कीजिए!<br />
                सभी सीटें<br />
                <span className="text-glaut-pink font-medium">भर चुकी हैं।</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Ci dispiace!<br />
                Tutti i posti<br />
                <span className="text-glaut-pink font-medium">sono occupati.</span>
            </p>
        ),
        id: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Maaf!<br />
                Semua kursi<br />
                <span className="text-glaut-pink font-medium">sudah terisi.</span>
            </p>
        ),
        ja: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                申し訳ありません！<br />
                全ての席が<br />
                <span className="text-glaut-pink font-medium">埋まっています。</span>
            </p>
        ),
        no: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Beklager!<br />
                Alle plasser<br />
                <span className="text-glaut-pink font-medium">er opptatt.</span>
            </p>
        ),
        pl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Przepraszamy!<br />
                Wszystkie miejsca<br />
                <span className="text-glaut-pink font-medium">są zajęte.</span>
            </p>
        ),
        pt: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Desculpe!<br />
                Todos os lugares<br />
                <span className="text-glaut-pink font-medium">estão ocupados.</span>
            </p>
        ),
        pt_BR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Desculpe!<br />
                Todos os lugares<br />
                <span className="text-glaut-pink font-medium">estão ocupados.</span>
            </p>
        ),
        ru: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Извините!<br />
                Все места<br />
                <span className="text-glaut-pink font-medium">заняты.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                ¡Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        es_AR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                ¡Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        es_MX: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                ¡Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        es_CL: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                ¡Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        es_CO: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                ¡Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        sv: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Tyvärr!<br />
                Alla platser<br />
                <span className="text-glaut-pink font-medium">är upptagna.</span>
            </p>
        ),
        tr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Üzgünüz!<br />
                Tüm koltuklar<br />
                <span className="text-glaut-pink font-medium">dolu.</span>
            </p>
        ),
        uk: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Вибачте!<br />
                Усі місця<br />
                <span className="text-glaut-pink font-medium">зайняті.</span>
            </p>
        )
    },
    detail: {
        en: "Thank you, but this project has already been completed.",
        ar: "شكراً لك، ولكن هذا المشروع قد اكتمل بالفعل.",
        zh: "感谢您，但这个项目已经完成了。",
        hr: "Hvala vam, ali ovaj projekt je već završen.",
        cs: "Děkujeme, ale tento projekt již byl dokončen.",
        nl: "Bedankt, maar dit project is al voltooid.",
        en_GB: "Thank you, but this project has already been completed.",
        en_AU: "Thank you, but this project has already been completed.",
        en_ZA: "Thank you, but this project has already been completed.",
        en_NG: "Thank you, but this project has already been completed.",
        en_KE: "Thank you, but this project has already been completed.",
        fi: "Kiitos, mutta tämä projekti on jo valmistunut.",
        fr: "Merci, mais ce projet est déjà terminé.",
        de: "Danke, aber dieses Projekt wurde bereits abgeschlossen.",
        de_CH: "Danke, aber dieses Projekt wurde bereits abgeschlossen.",
        el: "Ευχαριστούμε, αλλά αυτό το έργο έχει ήδη ολοκληρωθεί.",
        hi_IN: "धन्यवाद, लेकिन यह प्रोजेक्ट पहले ही पूरा हो चुका है।",
        it: "Grazie, ma questo progetto è già stato completato.",
        id: "Terima kasih, tapi proyek ini sudah selesai.",
        ja: "ありがとうございますが、このプロジェクトは既に完了しています。",
        no: "Takk, men dette prosjektet er allerede fullført.",
        pl: "Dziękujemy, ale ten projekt został już zakończony.",
        pt: "Obrigado, mas este projeto já foi concluído.",
        pt_BR: "Obrigado, mas este projeto já foi concluído.",
        ru: "Спасибо, но этот проект уже завершен.",
        es: "Gracias, pero este proyecto ya ha sido completado.",
        es_AR: "Gracias, pero este proyecto ya ha sido completado.",
        es_MX: "Gracias, pero este proyecto ya ha sido completado.",
        es_CL: "Gracias, pero este proyecto ya ha sido completado.",
        es_CO: "Gracias, pero este proyecto ya ha sido completado.",
        sv: "Tack, men detta projekt har redan slutförts.",
        tr: "Teşekkürler, ama bu proje çoktan tamamlandı.",
        uk: "Дякуємо, але цей проект вже завершено."
    }
}