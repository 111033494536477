import { IAnalysisCategory } from "@/@types/analysis"
import { ISelectOption } from "@/@types/interview-question"
import { ICategoryStats } from "@/@types/processing/statistics"
import { IBlock, IInsight, IReport } from "@/@types/reports"
import { ISegmentedStats } from "@/@types/segmentation"
import { BaseApiService } from "@services/@shared/api/BaseApiService"
import { isStatsASegmentedStats } from "@utils/analysis/segmentation"
import { AxiosInstance } from "axios"
import {
    IReportGateway,
    IReportGatewayAddInsightToReportRequest,
    IReportGatewayAddInsightToReportResponse,
    IReportGatewayDeleteReportBlockRequest,
    IReportGatewayDeleteReportBlockResponse,
    IReportGatewayReportDetailsRequest,
    IReportGatewayReportDetailsResponse,
    IReportGatewayReportListRequest,
    IReportGatewayReportListResponse
} from "../IReportGateway"
import { settings } from "@utils/Variables"

interface IReportsApiServiceProps {
    api: AxiosInstance
}

export default class ReportsApiService extends BaseApiService implements IReportGateway {
    private readonly props: IReportsApiServiceProps

    constructor(props: IReportsApiServiceProps) {
        super()

        this.props = props
    }

    public async getReportList(
        request: IReportGatewayReportListRequest
    ): Promise<IReportGatewayReportListResponse> {
        const { projectId } = request

        return await this.props.api
            .get<IReport[]>(`projects/${projectId}/reports/v2`)
            .then(res => ({ reports: res.data }))
    }

    public async getReportDetails(
        request: IReportGatewayReportDetailsRequest
    ): Promise<IReportGatewayReportDetailsResponse> {
        const { reportId } = request

        return await this.props.api
            .get<IReportGatewayReportDetailsResponse>(`${settings.pubApiUrl}/reports/v2/${reportId}`)
            .then(res => ({
                ...res.data,
                report: {
                    ...res.data.report,
                    goals: res.data.report.goals.map(g => ({
                        ...g,
                        insights: g.insights.map(this.parseNoAnswerCategoryForInsight.bind(this)) as IInsight[]
                    })),
                    appendix: res.data.report.appendix.map(b =>
                        "stats" in b ? this.parseNoAnswerCategoryForInsight.bind(this)(b) : b
                    ) as (IInsight | IBlock)[]
                }
            }))
    }

    public async deleteReportBlock(
        request: IReportGatewayDeleteReportBlockRequest
    ): Promise<IReportGatewayDeleteReportBlockResponse> {
        const { projectId, reportId, blockId } = request

        return await this.props.api
            .delete(`projects/${projectId}/reports/${reportId}/blocks/${blockId}`)
            .then(res => res.data)
    }

    public async addInsightToReport(
        request: IReportGatewayAddInsightToReportRequest
    ): Promise<IReportGatewayAddInsightToReportResponse> {
        const { projectId, reportId, analysisId } = request

        return await this.props.api
            .post(`projects/${projectId}/reports/${reportId}/insights/${analysisId}`)
            .then(res => res.data)
    }

    // #region Private methods

    /**
     * @TODO improve type checking (and avoid forcing type assertions)
     */
    private parseNoAnswerCategoryForInsight(insight: IInsight) {
        const { stats } = insight
        if (!stats) return insight

        if (Array.isArray(stats) && stats.every(isStatsASegmentedStats))
            return {
                ...insight,
                stats: this.parseNoAnswerCategoryForSegmentedStats(stats as ISegmentedStats[])
            }

        if (insight.type === "nps") return insight
        if (insight.type === "select")
            return {
                ...insight,
                stats: this.parseNoAnswerCategoryForQuestionSelectOptions(stats as ICategoryStats<ISelectOption>[])
            }

        return {
            ...insight,
            stats: this.parseNoAnswerCategoryForAnalysisStats(stats as ICategoryStats<IAnalysisCategory>[])
        }
    }

    // #endregion
}
