import QuestionCardLabel from "@assets/question-card-label.svg"
import CodeItem from "@components/CodeItem"
import { copy, getCopy } from "@utils/Copy"
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import { FaChevronCircleRight } from "react-icons/fa"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { IInterview } from "src/@types/entry"
import { IInterviewQuestion } from "src/@types/interview-question"
import ConversationTranscriptBoxMessage, {
    IConversationTranscriptBoxMessageItemProps
} from "./components/ConversationTranscriptBoxMessage"
import ConversationTranscriptBoxCsv from "./components/ConversationTranscriptBoxCsv"

export type IMessagesProp = IInterview["messages"] | {
    [questionId: string]: {
        iqIndex: number
        iqType: IInterviewQuestion["type"]
        iqLabel: string
        messages: IInterview["messages"]
    }
}

interface IConversationTranscriptBoxProps {
    identifier?: string
    lastUpdateDate?: string
    messages: IMessagesProp
    codes?: {
        occurrences: number
        label: string
    }[]
    expandable?: boolean
    startExpanded?: boolean
    useLegacyStyling?: boolean
    /**
     * Implemented for retro-compatibility. The idea is to remake this component
     * for better practices.
     */
    customCodesElement?: React.ReactNode
    /**
     * Necessary for playing the audio of messages. If not defined, the audio button will be always hidden.
     */
    projectId?: string

    onClickIdentifier?: (identifier: string) => void
    showQuestionHeaders?: boolean
    showConversationHeader?: boolean
    highlightedQuote?: string

    designMode?: "interview" | "csv"
}

const ConversationTranscriptBox = forwardRef<HTMLDivElement, Readonly<IConversationTranscriptBoxProps>>(({
    identifier,
    messages,
    lastUpdateDate,
    expandable = false,
    codes,
    startExpanded = false,
    useLegacyStyling = false,
    customCodesElement,
    projectId,
    onClickIdentifier,
    showQuestionHeaders = false,
    showConversationHeader = false,
    highlightedQuote,
    designMode = "interview"
}, ref) => {
    // #region Util functions
    const calculateInitialCurrQuestionId = useCallback(
        () => (!Array.isArray(messages) && Object.values(messages).length > 0) ? Object.keys(messages)[0] : undefined,
        [messages]
    )
    // #endregion

    // #region States
    const [expanded, setExpanded] = useState(expandable ? (startExpanded ?? false) : true)
    const [currQuestionId, setCurrQuestionId] = useState<string | undefined>(calculateInitialCurrQuestionId())
    // #endregion

    // #region Memos

    const displayedMessages: IConversationTranscriptBoxMessageItemProps[] = useMemo(
        () => {
            if (Array.isArray(messages)) return messages
            return currQuestionId ? (messages[currQuestionId]?.messages ?? []) : []
        },
        [currQuestionId, messages]
    )

    const currPage = useMemo(
        () => Object.keys(messages).findIndex(questionId => questionId === currQuestionId) + 1,
        [messages, currQuestionId]
    )
    const totalPages = useMemo(
        () => Object.keys(messages).length,
        [messages]
    )

    const currQuestion = useMemo(() => {
        if (Array.isArray(messages) || currQuestionId === undefined) return undefined
        return messages[currQuestionId]
    }, [messages, currQuestionId])

    const questionTypeLabel = useMemo(() => {
        if (Array.isArray(messages) || currQuestionId === undefined) return undefined

        switch (messages[currQuestionId]?.iqType) {
            case "open": {
                return "💬 Open"
            }
            case "select": {
                return "🗳️ Select"
            }
            case "scale": {
                return "🔢 Scale"
            }
            default: {
                return undefined
            }
        }
    }, [currQuestionId, messages])

    // #endregion

    // #region Callbacks
    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded)
    }, [expanded])

    const handlePreviousPageClick = useCallback(() => {
        if (currPage === 1) return

        // currPage is 1-indexed => needs to make it 0-indexed and subtract 1
        setCurrQuestionId(Object.keys(messages)[currPage - 2])
    }, [currPage, messages])

    const handleNextPageClick = useCallback(() => {
        if (currPage === totalPages) return

        // currPage is 1-indexed => it is intrinsically 0-indexed with 1 added
        setCurrQuestionId(Object.keys(messages)[currPage])
    }, [currPage, totalPages, messages])

    const handleClickOnIdentifier = useCallback(() => {
        if (!identifier) return

        onClickIdentifier?.(identifier)
    }, [identifier, onClickIdentifier])
    // #endregion

    // #region Effects
    useEffect(() => {
        setCurrQuestionId(calculateInitialCurrQuestionId())
    }, [calculateInitialCurrQuestionId])
    // #endregion

    // #region Element memos

    const expandableIcon = useMemo(() => {
        if (!expandable) return undefined

        return (
            <button className="border-none shadow-none p-0 bg-transparent" onClick={toggleExpanded}>
                <FaChevronCircleRight
                    className={`h-[0.75rem] w-[0.75rem] text-glaut-off-white transition-all
                        ${expanded ? "rotate-90" : ""}
                    `}
                />
            </button>
        )
    }, [expandable, expanded, toggleExpanded])

    const messagesElement = useMemo(() => {
        let currQuestionId: string | null = null
        let currQuestionIndex = 0
        const elements: React.ReactNode[] = []

        displayedMessages.forEach((message, idx, arr) => {
            if (designMode === "csv" && message.role === "moderator") return
            if (showQuestionHeaders && message.question_id !== currQuestionId) {
                currQuestionId = message.question_id
                currQuestionIndex++
                elements.push(
                    <p className={`text-[13.33px] font-medium rounded-full self-center py-[0.25em] px-[0.75em]
                        mt-[0.3125em]
                        text-glaut-off-white bg-glaut-pink
                    `}>
                        {getCopy(copy.words.question)} {currQuestionIndex}
                    </p>
                )
            }

            elements.push(
                <ConversationTranscriptBoxMessage
                    key={`${message.role}-${message.date}-${idx}`}
                    designMode={designMode}
                    message={message}
                    shouldRenderFollowUpHeader={idx > 0 && (!arr[idx - 1].isFollowUp && message.isFollowUp)}
                    projectId={projectId}
                    useLegacyStyling={useLegacyStyling}
                    highlightedQuote={highlightedQuote}
                />
            )
        })

        return elements
    }, [displayedMessages, highlightedQuote, projectId, showQuestionHeaders, useLegacyStyling, designMode])

    // #endregion

    if (designMode === "csv")
        return (
            <ConversationTranscriptBoxCsv
                identifier={identifier}
                lastUpdateDate={lastUpdateDate}
                messagesElement={messagesElement}
                customCodesElement={customCodesElement}
                currQuestion={currQuestion}
                currPage={currPage}
                totalPages={totalPages}
                questionTypeLabel={questionTypeLabel}
                handlePreviousPageClick={handlePreviousPageClick}
                handleNextPageClick={handleNextPageClick}
                messages={messages}
            />
        )

    return (
        <div className={`flex flex-col pt-[0.625rem] overflow-hidden max-h-full
            ${useLegacyStyling ? "border-1 rounded-md border-glaut-stroke-glaut" : ""}
        `} ref={ref}>
            {showConversationHeader && (
                <div className="flex flex-row gap-[0.25rem] justify-between px-[0.75rem] py-[0.25rem] rounded-[0.25rem]
                    bg-glaut-headers-grey">
                    <div className="flex items-center gap-[0.625rem]">
                        {expandable && expandableIcon}
                        <button
                            className="border-none rounded-none shadow-none p-0 m-0 disabled:cursor-text bg-transparent"
                            onClick={handleClickOnIdentifier}
                            disabled={!onClickIdentifier}
                        >
                            <p className="text-[13.33px] underline text-glaut-off-white text-start">
                                {identifier}
                            </p>
                        </button>
                    </div>
                    {lastUpdateDate && (
                        <p className="text-[13.33px] font-medium text-glaut-off-white text-end">
                            {lastUpdateDate}
                        </p>
                    )}
                </div>
            )}
            {!Array.isArray(messages) && currQuestion && (
                <div className={`flex flex-col rounded-[0.25em] gap-[0.3125em] px-[0.75em]
                    transition-all
                    bg-glaut-very-light-grey
                    ${!expanded ? "h-0 opacity-0" : "h-full opacity-100 mt-[0.75em] py-[0.625em] "}
                `}>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            <p className="text-[11.11px] font-medium text-glaut-text-midnight mr-1">
                                {`Question ${currQuestion.iqIndex} - `}
                            </p>
                            <p className="text-[11.11px] font-medium text-glaut-stroke-button">
                                {questionTypeLabel}
                            </p>
                        </div>
                        <div className="flex flex-row gap-[0.5em] items-center">
                            <button
                                className="border-none shadow-none p-0 bg-transparent text-md"
                                onClick={handlePreviousPageClick}
                                disabled={currPage === 1}>
                                <MdChevronLeft
                                    className={`w-[1.125em] h-[1.125em] m-[0.25em] 
                                        ${currPage === 1 ? "text-glaut-grey" : "text-glaut-pink"}
                                    `}
                                />
                            </button>
                            <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                                {`${currPage} of ${totalPages}`}
                            </p>
                            <button
                                className="border-none shadow-none p-0 bg-transparent text-md"
                                onClick={handleNextPageClick}
                                disabled={currPage === totalPages}>
                                <MdChevronRight
                                    className={`w-[1.125em] h-[1.125em] m-[0.25em] 
                                        ${(currPage === totalPages) ? "text-glaut-grey" : "text-glaut-pink"}
                                    `}
                                />
                            </button>
                        </div>
                    </div>
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {currQuestion.iqLabel}
                    </p>
                </div>
            )}
            <div className={`
                flex flex-col overflow-auto transition-all gap-2
                ${!expanded ? "h-0 opacity-0" : "h-full opacity-100 mt-[0.625rem]"}
            `}>
                {...messagesElement}
            </div>
            {codes !== undefined && (
                <div className="flex flex-row flex-wrap px-3" style={{ gap: "4px" }}>
                    <img src={QuestionCardLabel} alt="Codes label" />
                    {codes.map((code, idx) => (
                        <CodeItem
                            key={`${code.label}-${code.occurrences}`}
                            content={code.label}
                            leadContent={String(code.occurrences)}
                            colorIndex={idx}
                        />
                    ))}
                </div>
            )}
            {customCodesElement && (
                <div className="mt-[0.5em]">
                    {customCodesElement}
                </div>
            )}
        </div>
    )
})

ConversationTranscriptBox.displayName = "ConversationTranscriptBox"

export default ConversationTranscriptBox