import { copy, getCopy } from "@utils/Copy"
import { useState } from "react"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import ReportDetailsContentStandardTextSession from "../ReportDetailsContentStandardTextSession"
import ReportDetailsInsightsList from "../ReportDetailsInsightsList"

export default function ReportDetailsAppendix() {
    // #region Contexts
    const { report } = useReportDetails()
    // #endregion

    // #region States
    const [isAppendixExpanded, setIsAppendixExpanded] = useState(false)
    // #endregion

    if (!report || report.appendix.length === 0) return <></>

    return (
        <div className="flex flex-col gap-[1rem]">
            <ReportDetailsContentStandardTextSession
                id="appendix"
                title={getCopy(copy.words.appendix) ?? ""}
                size="big"
                expandable
                isExpanded={isAppendixExpanded}
                onToggleExpand={setIsAppendixExpanded}
                withTitleUnderline
            />
            {isAppendixExpanded && <ReportDetailsInsightsList insights={report.appendix} />}
        </div>
    )
}