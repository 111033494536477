import { IAnalysis } from "@/@types/analysis"
import { useAnalysisTab } from "@pages/Project/ProjectArea/contexts/AnalysisTabProvider"
import { useLegacyProject, useProject } from "@pages/Project/ProjectArea/contexts/ProjectProvider"
import { useMemo } from "react"
import { isQuestionItemAnAnalysis } from "../utils/is-question-item-an-analysis"
import { IIndexedIq, IQuestionItem } from "../utils/types"
import { ILang, IQuestion } from "@/@types/interview-question"
import { createSequentialArray } from "@utils/array/sequential-array"

function buildOptionsAttributeForQuestion(iq: IQuestion, lang: string): { id: string, label: string }[] {
    if (iq.type === "select")
        return iq.options.map((option: { id: string, label: ILang }) => ({
            id: option.id,
            label: option.label[lang] ?? ""
        }))

    if (iq.type === "scale" || iq.type === "nps")
        return createSequentialArray(iq.min, iq.max).map(value => ({
            id: value.toString(),
            label: value.toString()
        }))

    return []
}

export function useSortedIqsAndAnalyses() {
    // #region Contexts
    const { lang } = useLegacyProject()
    const { projectQuestions, screeningQuestions } = useProject()
    const { analyses, selectedAnalysis, selectedInterviewQuestionId } = useAnalysisTab()
    // #endregion

    // #region Memos
    const sortedInterviewQuestions: IIndexedIq[] = useMemo(() => (projectQuestions ?? []).map((iq, idx) => ({
        id: iq.id,
        title: iq.content[lang] ?? iq.header ?? "",
        sources: [iq.id],
        type: iq.type,
        domain: iq.domain,
        index: iq.domain === "screening" ? idx : idx + (screeningQuestions?.length ?? 0),
        categories: buildOptionsAttributeForQuestion(iq, lang)
    })), [projectQuestions, lang, screeningQuestions])

    const sortedIqsAndAnalyses = useMemo(() => {
        const result: IQuestionItem[] = []

        if (!analyses) return result

        const multiSourceAnalyses: IAnalysis[] = []
        const questionsWithResults = sortedInterviewQuestions.filter(iq => ["select", "scale", "nps"].includes(iq.type))

        // Group IQs and Analyses based on IQ index
        const mapIqIndexToValue: { [iqIdx: number]: IQuestionItem[] } = {}
        for (const iq of questionsWithResults)
            if (mapIqIndexToValue[iq.index]) mapIqIndexToValue[iq.index].push(iq)
            else mapIqIndexToValue[iq.index] = [iq]

        for (const analysis of analyses) {
            if (analysis.sources.length === 0) continue

            // Analysis with multiple sources are handled later
            if (analysis.sources.length > 1) {
                multiSourceAnalyses.push(analysis)
                continue
            }

            const analysisIqIdx = sortedInterviewQuestions.findIndex(iq => iq.id === analysis.sources[0])
            if (analysisIqIdx === -1) continue

            const analysisIq = sortedInterviewQuestions[analysisIqIdx]
            const addedAnalysis = { ...analysis, index: analysisIq.index }
            if (mapIqIndexToValue[analysisIq.index]) mapIqIndexToValue[analysisIq.index].push(addedAnalysis)
            else mapIqIndexToValue[analysisIq.index] = [addedAnalysis]
        }

        for (const analysis of multiSourceAnalyses) {
            const sourceIqs = sortedInterviewQuestions.filter(iq => analysis.sources.includes(iq.id))
            if (sourceIqs.length === 0) continue

            const maxIndex = Math.max(...sourceIqs.map(iq => iq.index))
            const addedAnalysis = { ...analysis, index: maxIndex }
            if (mapIqIndexToValue[maxIndex]) mapIqIndexToValue[maxIndex].push(addedAnalysis)
            else mapIqIndexToValue[maxIndex] = [addedAnalysis]
        }

        // Insert already ordered IQs and "non-multiple source" analyses into `result`
        Object.entries(mapIqIndexToValue)
            .sort(([aIdx], [bIdx]) => Number(aIdx) - Number(bIdx))
            .forEach(([, items]) => {
                if (!isQuestionItemAnAnalysis(items[0])) {
                    // Only the first can be IQ. The rest is necessarily IAnalysis
                    const iq = items.shift()
                    if (iq) result.push(iq)
                }

                const sortedItems = ([...items] as (IAnalysis & { index: number })[]).sort((a, b) => {
                    if (a.sources.length < b.sources.length) return -1
                    if (a.sources.length > b.sources.length) return 1

                    return Date.parse(a.previous_run_created_at) - Date.parse(b.previous_run_created_at)
                })
                result.push(...sortedItems)
            })

        // Update indexes for each item
        result.forEach((_, idx, arr) => {
            arr[idx].index = idx
        })

        return result
    }, [analyses, sortedInterviewQuestions])

    const sortedIqsAndAnalysesForBreakdown = useMemo(() => sortedIqsAndAnalyses.filter(
        item => item.id !== selectedAnalysis?.id // Do not show the selected analysis if applicable
            && item.id !== selectedInterviewQuestionId // Do not show the selected IQ if applicable
    ), [sortedIqsAndAnalyses, selectedAnalysis, selectedInterviewQuestionId])

    const sortedIqsAndAnalysesForFiltering = sortedIqsAndAnalysesForBreakdown
    // #endregion

    return {
        sortedInterviewQuestions,
        sortedIqsAndAnalyses,
        sortedIqsAndAnalysesForBreakdown,
        sortedIqsAndAnalysesForFiltering
    }
}