export const interviewTerminatedScreenoutCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        ar: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                للأسف،<br />
                <span className="text-glaut-pink font-medium">أنت لست <br />مناسباً.</span>
            </p>
        ),
        zh: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                很遗憾，<br />
                <span className="text-glaut-pink font-medium">您不是<br />合适的人选。</span>
            </p>
        ),
        hr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Nažalost,<br />
                <span className="text-glaut-pink font-medium">niste <br />odgovarajući kandidat.</span>
            </p>
        ),
        cs: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Bohužel,<br />
                <span className="text-glaut-pink font-medium">nejste <br />vhodným kandidátem.</span>
            </p>
        ),
        nl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Helaas,<br />
                <span className="text-glaut-pink font-medium">je bent niet <br />de juiste match.</span>
            </p>
        ),
        en_GB: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        en_AU: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        en_ZA: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        en_NG: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        en_KE: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,<br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        fi: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Valitettavasti,<br />
                <span className="text-glaut-pink font-medium">et ole <br />sopiva.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Malheureusement,<br />
                <span className="text-glaut-pink font-medium">vous n&apos;êtes pas <br />le bon profil.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Leider<br />
                <span className="text-glaut-pink font-medium">sind Sie nicht <br />geeignet.</span>
            </p>
        ),
        de_CH: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Leider<br />
                <span className="text-glaut-pink font-medium">sind Sie nicht <br />geeignet.</span>
            </p>
        ),
        el: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Δυστυχώς,<br />
                <span className="text-glaut-pink font-medium">δεν είστε <br />κατάλληλος/η.</span>
            </p>
        ),
        hi_IN: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                दुर्भाग्य से,<br />
                <span className="text-glaut-pink font-medium">आप उपयुक्त <br />नहीं हैं।</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Purtroppo,<br />
                <span className="text-glaut-pink font-medium">non sei <br />adatto.</span>
            </p>
        ),
        id: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Sayangnya,<br />
                <span className="text-glaut-pink font-medium">Anda tidak <br />cocok.</span>
            </p>
        ),
        ja: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                申し訳ありませんが、<br />
                <span className="text-glaut-pink font-medium">あなたは<br />適任ではありません。</span>
            </p>
        ),
        no: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Dessverre,<br />
                <span className="text-glaut-pink font-medium">er du ikke <br />riktig kandidat.</span>
            </p>
        ),
        pl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Niestety,<br />
                <span className="text-glaut-pink font-medium">nie jesteś <br />odpowiednim kandydatem.</span>
            </p>
        ),
        pt: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Infelizmente,<br />
                <span className="text-glaut-pink font-medium">não é <br />o perfil adequado.</span>
            </p>
        ),
        pt_BR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Infelizmente,<br />
                <span className="text-glaut-pink font-medium">você não é <br />o perfil adequado.</span>
            </p>
        ),
        ru: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                К сожалению,<br />
                <span className="text-glaut-pink font-medium">вы нам <br />не подходите.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lamentablemente,<br />
                <span className="text-glaut-pink font-medium">no eres <br />el perfil adecuado.</span>
            </p>
        ),
        es_AR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lamentablemente,<br />
                <span className="text-glaut-pink font-medium">no eres <br />el perfil adecuado.</span>
            </p>
        ),
        es_MX: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lamentablemente,<br />
                <span className="text-glaut-pink font-medium">no eres <br />el perfil adecuado.</span>
            </p>
        ),
        es_CL: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lamentablemente,<br />
                <span className="text-glaut-pink font-medium">no eres <br />el perfil adecuado.</span>
            </p>
        ),
        es_CO: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lamentablemente,<br />
                <span className="text-glaut-pink font-medium">no eres <br />el perfil adecuado.</span>
            </p>
        ),
        sv: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Tyvärr,<br />
                <span className="text-glaut-pink font-medium">är du inte <br />rätt person.</span>
            </p>
        ),
        tr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Maalesef,<br />
                <span className="text-glaut-pink font-medium">uygun <br />değilsiniz.</span>
            </p>
        ),
        uk: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                На жаль,<br />
                <span className="text-glaut-pink font-medium">ви нам <br />не підходите.</span>
            </p>
        )
    },
    detail: {
        en: "Thank you, but you didn't match the requirements for this interview.",
        ar: "شكراً لك، ولكنك لم تستوفِ متطلبات هذه المقابلة.",
        zh: "感谢您，但您不符合此次面试的要求。",
        hr: "Hvala vam, ali ne ispunjavate uvjete za ovaj intervju.",
        cs: "Děkujeme, ale nesplňujete požadavky pro tento rozhovor.",
        nl: "Bedankt, maar je voldoet niet aan de vereisten voor dit interview.",
        en_GB: "Thank you, but you didn't match the requirements for this interview.",
        en_AU: "Thank you, but you didn't match the requirements for this interview.",
        en_ZA: "Thank you, but you didn't match the requirements for this interview.",
        en_NG: "Thank you, but you didn't match the requirements for this interview.",
        en_KE: "Thank you, but you didn't match the requirements for this interview.",
        fi: "Kiitos, mutta et täyttänyt tämän haastattelun vaatimuksia.",
        fr: "Merci, mais vous ne correspondez pas aux critères de cet entretien.",
        de: "Danke, aber Sie erfüllen die Anforderungen für dieses Interview nicht.",
        de_CH: "Danke, aber Sie erfüllen die Anforderungen für dieses Interview nicht.",
        el: "Ευχαριστούμε, αλλά δεν πληροίτε τις προϋποθέσεις για αυτή τη συνέντευξη.",
        hi_IN: "धन्यवाद, लेकिन आप इस साक्षात्कार की आवश्यकताओं के अनुरूप नहीं हैं।",
        it: "Grazie, ma non soddisfi i requisiti per questa intervista.",
        id: "Terima kasih, tapi Anda tidak memenuhi persyaratan untuk wawancara ini.",
        ja: "ありがとうございますが、このインタビューの要件を満たしていません。",
        no: "Takk, men du oppfyller ikke kravene for dette intervjuet.",
        pl: "Dziękujemy, ale nie spełniasz wymagań dla tej rozmowy.",
        pt: "Obrigado, mas não corresponde aos requisitos desta entrevista.",
        pt_BR: "Obrigado, mas você não corresponde aos requisitos desta entrevista.",
        ru: "Спасибо, но вы не соответствуете требованиям для этого интервью.",
        es: "Gracias, pero no cumples con los requisitos para esta entrevista.",
        es_AR: "Gracias, pero no cumplís con los requisitos para esta entrevista.",
        es_MX: "Gracias, pero no cumples con los requisitos para esta entrevista.",
        es_CL: "Gracias, pero no cumples con los requisitos para esta entrevista.",
        es_CO: "Gracias, pero no cumples con los requisitos para esta entrevista.",
        sv: "Tack, men du uppfyller inte kraven för denna intervju.",
        tr: "Teşekkürler, ancak bu görüşme için gereken şartları karşılamıyorsunuz.",
        uk: "Дякуємо, але ви не відповідаєте вимогам для цього інтерв'ю."
    }
}