import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import ConversationTranscriptBox, { IMessagesProp } from "@components/ConversationTranscriptBox"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { useFormattedDate } from "@hooks/useFormattedDate"
import { searchCategoryInStats } from "@utils/analysis/category-stats"
import { getLanguageKey, getProjectLang } from "@utils/language"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { IInterview } from "src/@types/entry"
import { IProjectResults } from "src/@types/project"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useLegacyProject, useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import AnalysisTabVerbatimsColItemCode from "../AnalysisTabVerbatimsColItemCode"
import AnalysisTabVerbatimsColItemSearchCodeBar from "../AnalysisTabVerbatimsColItemSearchCodeBar"
import { formatFiltersForApi } from "../AnalysisTabContent/utils/format-filters-for-api"

interface IAnalysisTabVerbatimsColItemProps {
    entry: IInterview
    startExpanded?: boolean
    allValidSelectedCodeIds?: string[]
    index: number
}

export default function AnalysisTabVerbatimsColItem({
    entry,
    startExpanded = false,
    allValidSelectedCodeIds,
    index
}: Readonly<IAnalysisTabVerbatimsColItemProps>) {
    // #region Contexts
    const { project } = useLegacyProject()
    const { interviewQuestions } = useProject()
    const {
        selectedAnalysis,
        analysesStats,
        categoryColorIndexes,
        selectedInterviewQuestionId,
        questionFilters,
        analysisFilters
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isDraggingOver, setIsDraggingOver] = useState(false)
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const entryResultsForSelectedAnalysis = useMemo(() => {
        if (!selectedAnalysis) return null
        return entry.analysis_results.find(r => r.analysis_id === selectedAnalysis.id)?.meaning_units ?? null
    }, [selectedAnalysis, entry])
    const displayedMessages: IMessagesProp = useMemo(() => {
        // Try question
        if (selectedInterviewQuestionId)
            return entry.messages.filter(m => m.question_id === selectedInterviewQuestionId)

        // Try analysis
        if (!selectedAnalysis) return {}

        const askedQuestions = (interviewQuestions ?? [])
            .map((iq, idx) => ({ ...iq, index: idx }))
            .filter(iq => selectedAnalysis.sources.includes(iq.id))

        const displayedMessages = askedQuestions.reduce((prev, curr) => {
            prev[curr.id] = {
                iqIndex: curr.index + 1,
                iqType: curr.type,
                iqLabel: getLanguageKey(curr.content, lang) || curr.header,
                messages: entry.messages.filter(m => m.question_id === curr.id)
            }

            return prev
        }, {})

        return displayedMessages
    }, [entry.messages, interviewQuestions, lang, selectedAnalysis, selectedInterviewQuestionId])
    // #endregion

    // #region Refs
    const codesRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Custom hooks
    const { text: lastUpdateDate } = useFormattedDate(entry.last_updated ?? entry.started)
    // #endregion

    // #region Callbacks
    const handleRemoveCode = useCallback((categoryId: string) => {
        if (!project || !selectedAnalysis) return

        const interviewId = entry._id
        const analysisId = selectedAnalysis.id

        interviewService.removeCategoryFromInterview({
            categoryId,
            interviewId,
            analysisId,
            projectId: project._id
        }).then(() => {
            analysisTabDispatch({
                type: "remove-category-from-interview",
                categoryId,
                interviewId,
                analysisId
            })

            analysisService.getAnalysesStats({
                projectId: project._id,
                questionFilters: formatFiltersForApi(questionFilters),
                analysisFilters: formatFiltersForApi(analysisFilters)
            }).then(res => {
                const { analysis_results: analysesStats } = res as IProjectResults
                analysisTabDispatch({ type: "set-analyses-stats", analysesStats })
            })
        })
    }, [
        analysisTabDispatch,
        entry,
        interviewService,
        project,
        selectedAnalysis,
        analysisService,
        questionFilters,
        analysisFilters
    ])
    // #endregion

    // #region Effects

    //  This turns the code into a draggable element and themes into a droppable element
    useEffect(() => {
        if (codesRef.current === null) return

        return dropTargetForElements({
            element: codesRef.current,
            onDragEnter: () => setIsDraggingOver(true),
            getData: () => ({ entry }),
            onDragLeave: () => setIsDraggingOver(false),
            onDrop: () => setIsDraggingOver(false)
        })
    }, [entry])

    // #endregion

    // #region Element memos
    const listOfVerbatimCodesElement = useMemo(() => {
        if (!selectedAnalysis?.categories || !entryResultsForSelectedAnalysis)
            return undefined

        const filteredCategoriesForSelectedAnalysis = selectedAnalysis.categories.filter(
            c => entryResultsForSelectedAnalysis.some(v => v.category_id === c.id)
        )

        if (entryResultsForSelectedAnalysis.length === 0 || filteredCategoriesForSelectedAnalysis.length === 0) {
            const message = "This answer has no codes."
            const messageSuffix = selectedAnalysis?.type === "thematic" ? " Drag one to assign to it" : ""
            return (
                <span className="text-[11.11px]">
                    {`${message}${messageSuffix}`}
                </span>
            )
        }

        // Insight, Select or Entities
        return filteredCategoriesForSelectedAnalysis
            .map(c => searchCategoryInStats(analysesStats[selectedAnalysis.id]?.stats ?? [], c.id))
            .filter(c => !!c)
            .sort((a, b) => {
                if (allValidSelectedCodeIds?.length) {
                    const isASelected = allValidSelectedCodeIds.includes(a.category.id)
                    const isBSelected = allValidSelectedCodeIds.includes(b.category.id)

                    if (isASelected && isBSelected) return 0
                    if (isASelected) return -1
                    if (isBSelected) return 1
                }

                return b.occurrences - a.occurrences
            })
            .map(c => (
                <AnalysisTabVerbatimsColItemCode
                    key={c.category.id}
                    codeStats={c}
                    index={categoryColorIndexes[c.category.id] ?? 1}
                    isGrey={allValidSelectedCodeIds ? !allValidSelectedCodeIds.includes(c.category.id) : false}
                    onRemove={() => { handleRemoveCode(c.category.id) }}
                />
            ))
    }, [
        allValidSelectedCodeIds,
        analysesStats,
        categoryColorIndexes,
        selectedAnalysis,
        entryResultsForSelectedAnalysis,
        handleRemoveCode
    ])
    // #endregion

    return (
        <div className={index !== 0 ? "border-t-1 border-t-stroke-glaut" : ""}>
            <ConversationTranscriptBox
                key={`${entry._id}-${selectedAnalysis?.id}`} // `key` attribute necessary to force a clean rendering
                lastUpdateDate={lastUpdateDate}
                messages={displayedMessages}
                startExpanded={startExpanded}
                identifier={entry.identifier}
                expandable
                customCodesElement={
                    <div className="flex flex-col gap-[0.3125em]">
                        <div
                            className={[
                                "codes flex flex-row flex-wrap py-[0.2em] px-[0.75em]",
                                isDraggingOver && "dragged-over"
                            ].join(" ")}
                            ref={codesRef}
                            style={{ gap: "0.625em" }}
                        >
                            {listOfVerbatimCodesElement}
                        </div>
                        <AnalysisTabVerbatimsColItemSearchCodeBar interview={entry} />
                    </div>
                }
                projectId={project?._id}
                onClickIdentifier={identifier => {
                    if (identifier !== entry.identifier) return
                    projectDispatch({ type: "select-interview", interview: entry })
                }}
                showConversationHeader
                designMode={project?.source === "CSV" ? "csv" : "interview"}
            />
        </div>
    )
}