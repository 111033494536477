import { IProjectFeatures } from "@/@types/project"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

interface IUseGlautBrandingProps {
    projectFeatures?: IProjectFeatures
}

export function useGlautBranding({ projectFeatures }: Readonly<IUseGlautBrandingProps> = {}) {
    // #region Query parameters
    const [searchParams] = useSearchParams()
    // #endregion

    // #region Memos
    const hasBranding = useMemo(() => {
        const queryParam = searchParams.get("brand")
        if (queryParam === "false") return false

        if (projectFeatures?.hide_branding) return false

        return true
    }, [searchParams, projectFeatures])
    // #endregion

    return { hasBranding }
}