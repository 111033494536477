/* eslint-disable max-len */
export const welcomePageCopy = {
    start: {
        ar: "ابدأ!",
        zh: "开始！",
        hr: "Počni!",
        cs: "Začít!",
        nl: "Begin!",
        en: "Start!",
        en_GB: "Start!",
        en_AU: "Start!",
        en_ZA: "Start!",
        en_NG: "Start!",
        en_KE: "Start!",
        fi: "Aloita!",
        fr: "Commencer!",
        de: "Starten!",
        de_CH: "Starten!",
        el: "Ξεκινήστε!",
        hi_IN: "शुरू करें!",
        it: "Cominciamo!",
        id: "Mulai!",
        ja: "スタート！",
        ko: "시작!",
        no: "Start!",
        pl: "Rozpocznij!",
        pt: "Começar!",
        pt_BR: "Começar!",
        ru: "Начать!",
        es: "¡Empezar!",
        es_AR: "¡Empezar!",
        es_MX: "¡Empezar!",
        es_CL: "¡Empezar!",
        es_CO: "¡Empezar!",
        sv: "Börja!",
        tr: "Başla!",
        uk: "Почати!"
    },
    privacyNoticeWithOrgName: {
        ar: () => "إجاباتك سرية ولا تتم مشاركتها إلا مع مالك هذا المشروع.",
        zh: () => "您的回答是保密的，仅与该项目的所有者共享。",
        hr: () => "Vaši odgovori su povjerljivi i dijele se samo s vlasnikom ovog projekta.",
        cs: () => "Vaše odpovědi jsou důvěrné a sdíleny pouze s vlastníkem tohoto projektu.",
        nl: () => "Uw antwoorden zijn vertrouwelijk en worden alleen gedeeld met de eigenaar van dit project.",
        en: () => "Your responses are confidential and only shared with the owner of this project.",
        en_GB: () => "Your responses are confidential and only shared with the owner of this project.",
        en_AU: () => "Your responses are confidential and only shared with the owner of this project.",
        en_ZA: () => "Your responses are confidential and only shared with the owner of this project.",
        en_NG: () => "Your responses are confidential and only shared with the owner of this project.",
        en_KE: () => "Your responses are confidential and only shared with the owner of this project.",
        fi: () => "Vastauksesi ovat luottamuksellisia ja jaetaan vain projektin omistajan kanssa.",
        fr: () => "Vos réponses sont confidentielles et partagées uniquement avec le propriétaire de ce projet.",
        de: () => "Ihre Antworten sind vertraulich und werden nur an den Eigentümer dieses Projekts weitergegeben.",
        de_CH: () => "Ihre Antworten sind vertraulich und werden nur an den Eigentümer dieses Projekts weitergegeben.",
        el: () => "Οι απαντήσεις σας είναι εμπιστευτικές και κοινοποιούνται μόνο στον ιδιοκτήτη αυτού του έργου.",
        hi_IN: () => "आपके जवाब गोपनीय हैं और केवल इस प्रोजेक्ट के मालिक के साथ साझा किए जाते हैं।",
        it: () => "Le tue risposte sono riservate e condivise solo con il proprietario di questo progetto.",
        id: () => "Tanggapan Anda bersifat rahasia dan hanya dibagikan dengan pemilik proyek ini.",
        ja: () => "あなたの回答は機密情報として扱われ、このプロジェクトの所有者とのみ共有されます。",
        ko: () => "귀하의 응답은 기밀이며 이 프로젝트 소유자와만 공유됩니다.",
        no: () => "Dine svar er konfidensielle og deles kun med eieren av dette prosjektet.",
        pl: () => "Twoje odpowiedzi są poufne i udostępniane tylko właścicielowi tego projektu.",
        pt: () => "As suas respostas são confidenciais e partilhadas apenas com o proprietário deste projeto.",
        pt_BR: () => "Suas respostas são confidenciais e compartilhadas apenas com o proprietário deste projeto.",
        ru: () => "Ваши ответы конфиденциальны и доступны только владельцу этого проекта.",
        es: () => "Sus respuestas son confidenciales y sólo se comparten con el propietario de este proyecto.",
        es_AR: () => "Sus respuestas son confidenciales y sólo se comparten con el propietario de este proyecto.",
        es_MX: () => "Sus respuestas son confidenciales y sólo se comparten con el propietario de este proyecto.",
        es_CL: () => "Sus respuestas son confidenciales y sólo se comparten con el propietario de este proyecto.",
        es_CO: () => "Sus respuestas son confidenciales y sólo se comparten con el propietario de este proyecto.",
        sv: () => "Dina svar är konfidentiella och delas endast med ägaren av detta projekt.",
        tr: () => "Yanıtlarınız gizlidir ve yalnızca bu projenin sahibiyle paylaşılır.",
        uk: () => "Ваші відповіді конфіденційні та доступні лише власнику цього проекту."
    },
    privacyNoticeAcceptanceWithOrgName: {
        ar: (_, privacyPolicyUrl) => <>
            أقبل استخدام بياناتي من قبل مالك المشروع وكل{"\n"}
            ما هو موضح في <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">سياسة الخصوصية</a>.
        </>,
        zh: (_, privacyPolicyUrl) => <>
            我接受项目所有者对我的数据的使用以及{"\n"}
            <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">隐私政策</a>中描述的所有内容。
        </>,
        hr: (_, privacyPolicyUrl) => <>
            Prihvaćam korištenje mojih podataka od strane vlasnika projekta i sve{"\n"}
            ostalo opisano u njihovoj <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">politici privatnosti</a>.
        </>,
        cs: (_, privacyPolicyUrl) => <>
            Souhlasím s použitím mých údajů vlastníkem projektu a vším{"\n"}
            ostatním popsaným v jejich <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">zásadách ochrany osobních údajů</a>.
        </>,
        nl: (_, privacyPolicyUrl) => <>
            Ik accepteer het gebruik van mijn gegevens door de projecteigenaar en alles{"\n"}
            wat beschreven staat in hun <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacybeleid</a>.
        </>,
        en: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_GB: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_AU: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_ZA: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_NG: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_KE: (_, privacyPolicyUrl) => <>
            I accept the project owner&apos;s use of my data and everything{"\n"}
            else described in their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        fi: (_, privacyPolicyUrl) => <>
            Hyväksyn projektin omistajan tietojeni käytön ja kaiken{"\n"}
            muun, joka on kuvattu heidän <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">tietosuojakäytännössään</a>.
        </>,
        fr: (_, privacyPolicyUrl) => <>
            J&apos;accepte l&apos;utilisation de mes données par le propriétaire du projet et tout{"\n"}
            ce qui est décrit dans leur <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">politique de confidentialité</a>.
        </>,
        de: (_, privacyPolicyUrl) => <>
            Ich akzeptiere die Verwendung meiner Daten durch den Projektinhaber und alles{"\n"}
            Weitere, was in deren <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">Datenschutzrichtlinie</a> beschrieben ist.
        </>,
        de_CH: (_, privacyPolicyUrl) => <>
            Ich akzeptiere die Verwendung meiner Daten durch den Projektinhaber und alles{"\n"}
            Weitere, was in deren <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">Datenschutzrichtlinie</a> beschrieben ist.
        </>,
        el: (_, privacyPolicyUrl) => <>
            Αποδέχομαι τη χρήση των δεδομένων μου από τον ιδιοκτήτη του έργου και όλα{"\n"}
            όσα περιγράφονται στην <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">πολιτική απορρήτου</a>.
        </>,
        hi_IN: (_, privacyPolicyUrl) => <>
            मैं प्रोजेक्ट मालिक द्वारा मेरे डेटा के उपयोग और{"\n"}
            उनकी <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">गोपनीयता नीति</a> में वर्णित सभी चीजों को स्वीकार करता/करती हूं।
        </>,
        it: (_, privacyPolicyUrl) => <>
            Accetto l&apos;utilizzo dei miei dati da parte del proprietario del progetto e{"\n"}
            tutto il resto descritto nella loro <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">informativa sulla privacy</a>.
        </>,
        id: (_, privacyPolicyUrl) => <>
            Saya menerima penggunaan data saya oleh pemilik proyek dan semua{"\n"}
            yang dijelaskan dalam <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">kebijakan privasi</a> mereka.
        </>,
        ja: (_, privacyPolicyUrl) => <>
            プロジェクト所有者による私のデータの使用と{"\n"}
            <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">プライバシーポリシー</a>に記載されているすべての内容に同意します。
        </>,
        ko: (_, privacyPolicyUrl) => <>
            프로젝트 소유자의 내 데이터 사용과{"\n"}
            <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">개인정보 처리방침</a>에 설명된 모든 내용에 동의합니다.
        </>,
        no: (_, privacyPolicyUrl) => <>
            Jeg godtar prosjekteierens bruk av mine data og alt annet{"\n"}
            som er beskrevet i deres <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">personvernpolicy</a>.
        </>,
        pl: (_, privacyPolicyUrl) => <>
            Akceptuję wykorzystanie moich danych przez właściciela projektu i wszystko{"\n"}
            inne opisane w ich <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">polityce prywatności</a>.
        </>,
        pt: (_, privacyPolicyUrl) => <>
            Aceito a utilização dos meus dados pelo proprietário do projeto e tudo{"\n"}
            o resto descrito na sua <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidade</a>.
        </>,
        pt_BR: (_, privacyPolicyUrl) => <>
            Aceito o uso dos meus dados pelo proprietário do projeto e tudo{"\n"}
            mais descrito na <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidade</a>.
        </>,
        ru: (_, privacyPolicyUrl) => <>
            Я принимаю использование моих данных владельцем проекта и все{"\n"}
            остальное, описанное в их <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">политике конфиденциальности</a>.
        </>,
        es: (_, privacyPolicyUrl) => <>
            Acepto el uso de mis datos por parte del propietario del proyecto y{"\n"}
            todo lo demás descrito en su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_AR: (_, privacyPolicyUrl) => <>
            Acepto el uso de mis datos por parte del propietario del proyecto y{"\n"}
            todo lo demás descrito en su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_MX: (_, privacyPolicyUrl) => <>
            Acepto el uso de mis datos por parte del propietario del proyecto y{"\n"}
            todo lo demás descrito en su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_CL: (_, privacyPolicyUrl) => <>
            Acepto el uso de mis datos por parte del propietario del proyecto y{"\n"}
            todo lo demás descrito en su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_CO: (_, privacyPolicyUrl) => <>
            Acepto el uso de mis datos por parte del propietario del proyecto y{"\n"}
            todo lo demás descrito en su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        sv: (_, privacyPolicyUrl) => <>
            Jag accepterar projektägarens användning av mina uppgifter och allt{"\n"}
            annat som beskrivs i deras <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">integritetspolicy</a>.
        </>,
        tr: (_, privacyPolicyUrl) => <>
            Proje sahibinin verilerimi kullanmasını ve{"\n"}
            <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">gizlilik politikasında</a> belirtilen her şeyi kabul ediyorum.
        </>,
        uk: (_, privacyPolicyUrl) => <>
            Я приймаю використання моїх даних власником проекту та все{"\n"}
            інше, описане в їхній <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">політиці конфіденційності</a>.
        </>
    },
    privacyNoticeWithUrlAndOrgName: {
        ar: (_, privacyPolicyUrl) => <>
            إجاباتك سرية ولا تتم مشاركتها إلا مع{"\n"}
            مالك هذا المشروع. راجع <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">سياسة الخصوصية</a>.
        </>,
        zh: (_, privacyPolicyUrl) => <>
            您的回答是保密的，仅与{"\n"}
            该项目的所有者共享。查看<a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">隐私政策</a>。
        </>,
        hr: (_, privacyPolicyUrl) => <>
            Vaši odgovori su povjerljivi i dijele se samo s{"\n"}
            vlasnikom ovog projekta. Pogledajte njihovu <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">politiku privatnosti</a>.
        </>,
        cs: (_, privacyPolicyUrl) => <>
            Vaše odpovědi jsou důvěrné a sdíleny pouze s{"\n"}
            vlastníkem tohoto projektu. Viz jejich <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">zásady ochrany osobních údajů</a>.
        </>,
        nl: (_, privacyPolicyUrl) => <>
            Uw antwoorden zijn vertrouwelijk en worden alleen gedeeld met{"\n"}
            de eigenaar van dit project. Bekijk hun <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacybeleid</a>.
        </>,
        en: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_GB: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_AU: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_ZA: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_NG: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        en_KE: (_, privacyPolicyUrl) => <>
            Your responses are confidential and only shared with{"\n"}
            the owner of this project. See their <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">privacy policy</a>.
        </>,
        fi: (_, privacyPolicyUrl) => <>
            Vastauksesi ovat luottamuksellisia ja jaetaan vain{"\n"}
            projektin omistajan kanssa. Katso heidän <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">tietosuojakäytäntönsä</a>.
        </>,
        fr: (_, privacyPolicyUrl) => <>
            Vos réponses sont confidentielles et partagées uniquement avec{"\n"}
            le propriétaire de ce projet. Consultez leur <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">politique de confidentialité</a>.
        </>,
        de: (_, privacyPolicyUrl) => <>
            Ihre Antworten sind vertraulich und werden nur mit{"\n"}
            dem Eigentümer dieses Projekts geteilt. Siehe deren <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">Datenschutzrichtlinie</a>.
        </>,
        de_CH: (_, privacyPolicyUrl) => <>
            Ihre Antworten sind vertraulich und werden nur mit{"\n"}
            dem Eigentümer dieses Projekts geteilt. Siehe deren <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">Datenschutzrichtlinie</a>.
        </>,
        el: (_, privacyPolicyUrl) => <>
            Οι απαντήσεις σας είναι εμπιστευτικές και κοινοποιούνται μόνο{"\n"}
            στον ιδιοκτήτη αυτού του έργου. Δείτε την <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">πολιτική απορρήτου</a>.
        </>,
        hi_IN: (_, privacyPolicyUrl) => <>
            आपके जवाब गोपनीय हैं और केवल{"\n"}
            इस प्रोजेक्ट के मालिक के साथ साझा किए जाते हैं। उनकी <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">गोपनीयता नीति</a> देखें।
        </>,
        it: (_, privacyPolicyUrl) => <>
            Le tue risposte sono riservate e condivise solo con{"\n"}
            il proprietario di questo progetto. Consulta la loro <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">informativa sulla privacy</a>.
        </>,
        id: (_, privacyPolicyUrl) => <>
            Tanggapan Anda bersifat rahasia dan hanya dibagikan dengan{"\n"}
            pemilik proyek ini. Lihat <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">kebijakan privasi</a> mereka.
        </>,
        ja: (_, privacyPolicyUrl) => <>
            あなたの回答は機密情報として扱われ、{"\n"}
            このプロジェクトの所有者とのみ共有されます。<a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">プライバシーポリシー</a>をご確認ください。
        </>,
        ko: (_, privacyPolicyUrl) => <>
            귀하의 응답은 기밀이며{"\n"}
            이 프로젝트 소유자와만 공유됩니다. <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">개인정보 처리방침</a>을 참조하세요.
        </>,
        no: (_, privacyPolicyUrl) => <>
            Dine svar er konfidensielle og deles kun med{"\n"}
            eieren av dette prosjektet. Se deres <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">personvernpolicy</a>.
        </>,
        pl: (_, privacyPolicyUrl) => <>
            Twoje odpowiedzi są poufne i udostępniane tylko{"\n"}
            właścicielowi tego projektu. Zobacz ich <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">politykę prywatności</a>.
        </>,
        pt: (_, privacyPolicyUrl) => <>
            As suas respostas são confidenciais e partilhadas apenas com{"\n"}
            o proprietário deste projeto. Consulte a sua <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidade</a>.
        </>,
        pt_BR: (_, privacyPolicyUrl) => <>
            Suas respostas são confidenciais e compartilhadas apenas com{"\n"}
            o proprietário deste projeto. Veja a <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidade</a>.
        </>,
        ru: (_, privacyPolicyUrl) => <>
            Ваши ответы конфиденциальны и доступны только{"\n"}
            владельцу этого проекта. Смотрите их <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">политику конфиденциальности</a>.
        </>,
        es: (_, privacyPolicyUrl) => <>
            Sus respuestas son confidenciales y solo se comparten con{"\n"}
            el propietario de este proyecto. Consulte su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_AR: (_, privacyPolicyUrl) => <>
            Sus respuestas son confidenciales y solo se comparten con{"\n"}
            el propietario de este proyecto. Consulte su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_MX: (_, privacyPolicyUrl) => <>
            Sus respuestas son confidenciales y solo se comparten con{"\n"}
            el propietario de este proyecto. Consulte su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_CL: (_, privacyPolicyUrl) => <>
            Sus respuestas son confidenciales y solo se comparten con{"\n"}
            el propietario de este proyecto. Consulte su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        es_CO: (_, privacyPolicyUrl) => <>
            Sus respuestas son confidenciales y solo se comparten con{"\n"}
            el propietario de este proyecto. Consulte su <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">política de privacidad</a>.
        </>,
        sv: (_, privacyPolicyUrl) => <>
            Dina svar är konfidentiella och delas endast med{"\n"}
            ägaren av detta projekt. Se deras <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">integritetspolicy</a>.
        </>,
        tr: (_, privacyPolicyUrl) => <>
            Yanıtlarınız gizlidir ve yalnızca{"\n"}
            bu projenin sahibiyle paylaşılır. <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">Gizlilik politikasına</a> bakın.
        </>,
        uk: (_, privacyPolicyUrl) => <>
            Ваші відповіді конфіденційні та доступні лише{"\n"}
            власнику цього проекту. Перегляньте їхню <a href={privacyPolicyUrl} target="_blank" className="text-glaut-codes-blue font-medium" rel="noreferrer">політику конфіденційності</a>.
        </>
    }
} as const