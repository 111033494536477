/* eslint-disable max-len */
export const interviewTerminatedUncooperativeCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        ar: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                شكراً لك<br />
                <span className="text-glaut-pink font-medium">على وقتك.</span>
            </p>
        ),
        zh: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                感谢您<br />
                <span className="text-glaut-pink font-medium">的时间。</span>
            </p>
        ),
        hr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Hvala vam<br />
                <span className="text-glaut-pink font-medium">na vašem vremenu.</span>
            </p>
        ),
        cs: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Děkujeme vám<br />
                <span className="text-glaut-pink font-medium">za váš čas.</span>
            </p>
        ),
        nl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Bedankt<br />
                <span className="text-glaut-pink font-medium">voor je tijd.</span>
            </p>
        ),
        // English variants
        en_GB: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        en_AU: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        en_ZA: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        en_NG: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        en_KE: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you<br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        fi: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Kiitos<br />
                <span className="text-glaut-pink font-medium">ajastasi.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Merci<br />
                <span className="text-glaut-pink font-medium">pour votre temps.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Danke<br />
                <span className="text-glaut-pink font-medium">für Ihre Zeit.</span>
            </p>
        ),
        de_CH: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Danke<br />
                <span className="text-glaut-pink font-medium">für Ihre Zeit.</span>
            </p>
        ),
        el: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Ευχαριστούμε<br />
                <span className="text-glaut-pink font-medium">για το χρόνο σας.</span>
            </p>
        ),
        hi_IN: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                धन्यवाद<br />
                <span className="text-glaut-pink font-medium">आपके समय के लिए।</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Grazie<br />
                <span className="text-glaut-pink font-medium">per il tuo tempo.</span>
            </p>
        ),
        id: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Terima kasih<br />
                <span className="text-glaut-pink font-medium">atas waktu Anda.</span>
            </p>
        ),
        ja: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                ありがとう<br />
                <span className="text-glaut-pink font-medium">ございました。</span>
            </p>
        ),
        no: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Takk<br />
                <span className="text-glaut-pink font-medium">for din tid.</span>
            </p>
        ),
        pl: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Dziękujemy<br />
                <span className="text-glaut-pink font-medium">za twój czas.</span>
            </p>
        ),
        pt: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Obrigado<br />
                <span className="text-glaut-pink font-medium">pelo seu tempo.</span>
            </p>
        ),
        pt_BR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Obrigado<br />
                <span className="text-glaut-pink font-medium">pelo seu tempo.</span>
            </p>
        ),
        ru: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Спасибо<br />
                <span className="text-glaut-pink font-medium">за ваше время.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        es_AR: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        es_MX: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        es_CL: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        es_CO: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        sv: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Tack<br />
                <span className="text-glaut-pink font-medium">för din tid.</span>
            </p>
        ),
        tr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Teşekkürler<br />
                <span className="text-glaut-pink font-medium">zamanınız için.</span>
            </p>
        ),
        uk: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Дякуємо<br />
                <span className="text-glaut-pink font-medium">за ваш час.</span>
            </p>
        )
    },
    detail: {
        en: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        ar: "يبدو أننا لم نتمكن من التوافق في هذه المقابلة. نأمل أن نتواصل مرة أخرى في المستقبل!",
        zh: "看来我们在这次面试中未能达成一致。希望将来能再次联系！",
        hr: "Čini se da se nismo uspjeli uskladiti u ovom intervjuu. Nadamo se ponovnom povezivanju u budućnosti!",
        cs: "Zdá se, že jsme se v tomto rozhovoru nemohli shodnout. Doufáme, že se v budoucnu znovu spojíme!",
        nl: "Het lijkt erop dat we niet op één lijn zaten tijdens dit interview. We hopen in de toekomst weer contact te hebben!",
        en_GB: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        en_AU: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        en_ZA: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        en_NG: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        en_KE: "It seems we couldn't align on this interview. We hope to connect again in the future!",
        fi: "Vaikuttaa siltä, ettemme päässeet yhteisymmärrykseen tässä haastattelussa. Toivottavasti voimme olla yhteydessä tulevaisuudessa!",
        fr: "Il semble que nous n'ayons pas pu nous accorder sur cet entretien. Nous espérons pouvoir nous reconnecter à l'avenir !",
        de: "Es scheint, dass wir bei diesem Interview nicht übereinstimmen konnten. Wir hoffen auf eine zukünftige Zusammenarbeit!",
        de_CH: "Es scheint, dass wir bei diesem Interview nicht übereinstimmen konnten. Wir hoffen auf eine zukünftige Zusammenarbeit!",
        el: "Φαίνεται ότι δεν μπορέσαμε να συμφωνήσουμε σε αυτή τη συνέντευξη. Ελπίζουμε να συνδεθούμε ξανά στο μέλλον!",
        hi_IN: "ऐसा लगता है कि हम इस साक्षात्कार में एक राय नहीं हो सके। हमें उम्मीद है कि भविष्य में फिर से जुड़ेंगे!",
        it: "Sembra che non siamo riusciti a trovare un accordo per questa intervista. Speriamo di riconnetterci in futuro!",
        id: "Sepertinya kita tidak bisa mencapai kesepakatan dalam wawancara ini. Kami berharap dapat terhubung kembali di masa depan!",
        ja: "このインタビューでは合意に至れなかったようです。将来また繋がれることを願っています！",
        no: "Det ser ut til at vi ikke kunne bli enige om dette intervjuet. Vi håper å kunne koble til igjen i fremtiden!",
        pl: "Wygląda na to, że nie mogliśmy dojść do porozumienia w tej rozmowie. Mamy nadzieję na ponowny kontakt w przyszłości!",
        pt: "Parece que não conseguimos alinhar nesta entrevista. Esperamos nos conectar novamente no futuro!",
        pt_BR: "Parece que não conseguimos alinhar nesta entrevista. Esperamos nos conectar novamente no futuro!",
        ru: "Похоже, мы не смогли найти общий язык в этом интервью. Надеемся на связь в будущем!",
        es: "Parece que no pudimos alinearnos en esta entrevista. ¡Esperamos conectarnos de nuevo en el futuro!",
        es_AR: "Parece que no pudimos alinearnos en esta entrevista. ¡Esperamos conectarnos de nuevo en el futuro!",
        es_MX: "Parece que no pudimos alinearnos en esta entrevista. ¡Esperamos conectarnos de nuevo en el futuro!",
        es_CL: "Parece que no pudimos alinearnos en esta entrevista. ¡Esperamos conectarnos de nuevo en el futuro!",
        es_CO: "Parece que no pudimos alinearnos en esta entrevista. ¡Esperamos conectarnos de nuevo en el futuro!",
        sv: "Det verkar som att vi inte kunde komma överens i denna intervju. Vi hoppas kunna återkoppla i framtiden!",
        tr: "Bu görüşmede uyum sağlayamadığımız görünüyor. Gelecekte tekrar bağlantı kurmayı umuyoruz!",
        uk: "Схоже, ми не змогли дійти згоди в цьому інтерв'ю. Сподіваємося на зв'язок у майбутньому!"
    }
}