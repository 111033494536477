import { IAnalysisCategory } from "@/@types/analysis"
import { IBaseScaleStats, ICategoryStats, INpsStats, IStats } from "src/@types/processing/statistics"
import { ISegmentedStats } from "@/@types/segmentation"
import slugify from "slugify"
import { ISegment } from "@/@types/base/generics"
import { ISegmentedProjectResults, IVariableResults } from "@/@types/project"

export function isStatsASegmentedStats(
    stats: IBaseScaleStats | INpsStats | ICategoryStats | ISegmentedStats
): stats is ISegmentedStats {
    return "segment" in stats && "segmented" in stats
}

// Necessary for static type checking => a simple `!isStatsACategoryStats` isn't enough
export function isStatsACategoryStats(stats: ICategoryStats | ISegmentedStats): stats is ICategoryStats {
    return !isStatsASegmentedStats(stats)
}

export function extractQuestionResultsCategoryStatsFromDefaultOrSegmentedStats(
    rawStats: IVariableResults | ISegmentedProjectResults[],
    questionId: string
) {
    if (Array.isArray(rawStats)) {
        const stats = rawStats[0].project_results.question_results[questionId]?.stats
        if (Array.isArray(stats)) return stats
        return null
    }

    if (Array.isArray(rawStats.stats)) return rawStats.stats
    return null
}

export function extractIdFromSegment(segment: ISegment): string {

    if (segment == null) return "empty"

    if ("id" in segment) return segment.id

    return slugify(segment.label, { lower: true, trim: true })
}

export function extractLabelFromSegment(segment: ISegment, lang: string = "en"): string {
    if (typeof segment.label === "string") return segment.label
    return segment.label[lang] ?? Object.values(segment.label)[0] ?? ""
}

export function extractDescriptionFromSegment(segment: ISegment, lang: string = "en"): string {
    if ("description" in segment) return segment.description ?? ""
    return extractLabelFromSegment(segment, lang)
}

export function forceConvertISegmentToIAnalysisCategory(segment: ISegment, lang: string = "en"): IAnalysisCategory {
    const result: IAnalysisCategory = {
        id: extractIdFromSegment(segment),
        label: extractLabelFromSegment(segment, lang),
        description: "description" in segment ? segment.description : extractLabelFromSegment(segment, lang),
        parent: "parent" in segment ? segment.parent : null,
        deleted: "deleted" in segment ? segment.deleted : undefined,
        url: "url" in segment ? segment.url : undefined
    }

    return result
}

export function recursivelyConvertChildrenToAnalysisCategory(stats: ICategoryStats, lang: string) {
    return {
        ...stats,
        category: forceConvertISegmentToIAnalysisCategory(stats.category, lang),
        children: stats.children.map(c => recursivelyConvertChildrenToAnalysisCategory(c, lang))
    }
}

export function forceConvertIStatsToICategoryStatsAnalysisCategory(
    stats: IStats,
    lang = "en"
): ICategoryStats<IAnalysisCategory>[] {
    if (!Array.isArray(stats)) return []

    return stats.map(s => ({
        ...s,
        category: forceConvertISegmentToIAnalysisCategory(s.category),
        children: recursivelyConvertChildrenToAnalysisCategory(s, lang)
    }))
}
