import { MdOutlineArrowDropDown } from "react-icons/md"
import Select, { DropdownIndicatorProps, SelectInstance } from "react-select"
import { getCopy } from "@utils/Copy"
import { forwardRef, Ref } from "react"
import { glautSelectCopy } from "./utils/copy"
import { isValidType } from "./utils/is-valid-type"

interface IOption {
    label: React.ReactNode
    value: string
}

interface IGlautSelectProps {
    options: IOption[]
    value?: IOption
    onSelect?: (value: string | null) => void
    placeholder?: string
}

function SelectDownChevron({ innerProps }: Readonly<DropdownIndicatorProps>) {
    return (
        <div className="" {...innerProps}>
            <MdOutlineArrowDropDown
                className="h-[1.25em] w-[1.25em] text-glaut-text-midnight"
            />
        </div>
    )
}

const GlautSelect = forwardRef<
    SelectInstance<IOption>,
    Readonly<IGlautSelectProps>
>(({
    options,
    onSelect,
    value,
    placeholder
}, ref) => (
    <Select
        ref={ref as Ref<SelectInstance>} // Type assertion needed due to react-select's internal type constraints
        placeholder={placeholder ?? getCopy(glautSelectCopy.selectAnOption)}
        classNames={{
            container: () => "flex-1",
            placeholder: () => "text-center text-[13.33px] font-medium",
            option: () => "mx-[0.5rem] border-1 border-transparent hover:border-glaut-grey",
            indicatorSeparator: () => "hidden",
            control: () => "pl-[0.25em] pr-[0.75em] border-0 outline-0"
        }}
        components={{
            DropdownIndicator: SelectDownChevron
        }}
        styles={{ // some styles don't work with class names
            option: () => ({
                backgroundColor: "white"
            }),
            placeholder: style => ({
                ...style,
                color: "var(--color-glaut-text-midnight)"
            }),
            control: style => ({
                ...style,
                borderColor: "var(--color-glaut-light-grey)",
                backgroundColor: "var(--color-glaut-cards)",
                boxShadow: "none",
                ":hover": {
                    ...style[":hover"],
                    borderColor: "black"
                }
            })
        }}
        value={value}
        options={options}
        onChange={ev => {
            if (ev === null) {
                onSelect?.(null)
                return
            }

            if (!isValidType(ev)) return
            onSelect?.(ev.value)
        }}
        isClearable
        isSearchable={false}
    />
))

GlautSelect.displayName = "GlautSelect"

export default GlautSelect