import BuiltWithGlaut from "@assets/build_with_glaut.svg"
import Button from "@components/layouts/Button"
import Input from "@components/layouts/Input"
import ChooseLanguage from "@components/menus/ChooseLanguage"
import { useTrackInteraction } from "@contexts/TrackInteractionProvider"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useGlautBranding } from "@pages/Conversation/Interview/hooks/useGlautBranding"
import { useLegacyProject } from "@pages/Project/ProjectArea/contexts/ProjectProvider"
import { copy, getCopy } from "@utils/Copy"
import { emailRegex, languages, privacyNoticeUrls, widthBreakpoint } from "@utils/Variables"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaCheckSquare, FaRegSquare } from "react-icons/fa"
import { RiTimerLine } from "react-icons/ri"
import { welcomePageCopy } from "./utils/copy"
import { IProjectFeatures } from "@/@types/project"

interface IWelcomePageProps {
    interviewSettings: {
        features: IProjectFeatures
        organization_name: string
        collect_email: boolean
        language: string
        supported_languages: string[]
        intro_message: Record<string, string>
        logo: string
        interview_duration: number
        privacy_notice: boolean
        privacy_notice_explicit_consent: boolean
        privacy_policy_link: string
        accent_color: string
    }
    lang: string
    setLang: (lang: string) => void
    email: string
    prefilledId: string
    setEmail: (email: string) => void
    setRunning: (running: boolean) => void
    previewProject: boolean
}

const WelcomePage = ({
    interviewSettings,
    lang,
    setLang,
    email,
    prefilledId,
    setEmail,
    setRunning,
    previewProject
}: Readonly<IWelcomePageProps>) => {
    // #region Branding
    const { hasBranding } = useGlautBranding({ projectFeatures: interviewSettings?.features })
    // #endregion

    // #region Contexts
    const legacyProjectContext = useLegacyProject() // can be null
    // #endregion

    // #region States
    const [keyboardStatus, setKeyboardStatus] = useState("close")
    const [valid, setValid] = useState(false)
    const [hasConsentedPrivacyPolicy, setHasConsentedPrivacyPolicy] = useState(false)
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region Hooks
    const { startInteraction } = useTrackInteraction()
    // #endregion

    // #region Refs
    const currentMessageRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos

    const orgName = useMemo(
        () => legacyProjectContext?.org?.name ?? interviewSettings.organization_name,
        [interviewSettings, legacyProjectContext]
    )
    const headerChildComponentWidth = useMemo(() => "min(30%,150px)", [])

    const askEmail = useMemo(
        () => interviewSettings?.collect_email !== false,
        [interviewSettings]
    )

    const availableLanguages = useMemo(() => {
        // "new Set()" to drop duplicates
        const interviewLanguages = [
            ...new Set([interviewSettings.language, ...interviewSettings?.supported_languages || []])
        ]

        // Sort the languages by the order of the "languages" array
        return interviewLanguages
            .map(lang => ({ lang, name: languages[lang].name }))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(l => l.lang)
    }, [interviewSettings])

    const glautPrivacyNoticeUrl = useMemo(() => privacyNoticeUrls[lang] ?? privacyNoticeUrls.en, [lang])

    // #endregion

    // #region Callbacks

    // Reset the scroll position
    const resetScroll = useCallback(() => {
        if (!isMobile) return

        // Fix iOS scroll
        window.scrollTo(0, 0)
        // Scroll to the bottom of the message
        if (currentMessageRef?.current?.scrollTo) currentMessageRef.current.scrollTo(0, 10000)
    }, [isMobile])

    // Triggered when the window is resized
    const handleResize = useCallback(() => {
        resetScroll()
    }, [resetScroll])

    // Manage input focus toggle
    const onInputFocusChange = useCallback((opening: boolean) => {
        if (isMobile) {
            setKeyboardStatus(opening ? "opening" : "closing")
            setTimeout(() => {
                setKeyboardStatus(opening ? "open" : "close")

                handleResize()
            }, 300)
        }
    }, [isMobile, handleResize])

    const handleWelcomeFormSubmit = useCallback(e => {
        e.preventDefault()

        // We can only start if we are not collecting emails or we already have a prefilledId or the email collected
        // is valid
        if (interviewSettings?.collect_email === false || prefilledId || valid) {
            startInteraction("moderator-reply")
            setRunning(true)
        }
    }, [interviewSettings, prefilledId, valid, startInteraction, setRunning])

    // #endregion

    // #region Effects

    // Add event listener to manage keyboard open 
    useEffect(() => {
        // for Android
        window.removeEventListener("resize", handleResize)
        // and for iOS
        window.visualViewport?.addEventListener("resize", handleResize)

        // Be sure to run at least one time the method
        handleResize()

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize)
            window.visualViewport?.removeEventListener("resize", handleResize)
        }
    }, [keyboardStatus, handleResize])

    useEffect(() => {
        // Checks the validity of the email
        if (askEmail && !emailRegex.test(email)) {
            setValid(false)
            return
        }

        if (interviewSettings.privacy_notice) {
            setValid(!interviewSettings.privacy_notice_explicit_consent || hasConsentedPrivacyPolicy)
            return
        }

        setValid(true)
    }, [email, interviewSettings, hasConsentedPrivacyPolicy, askEmail])

    // #endregion

    return (
        <div
            className={[
                "flex flex-col justify-between scroll-smooth overflow-y-hidden w-full pb-[2.5%] px-[0.25em]",
                "bg-glaut-off-white",
                previewProject ? "h-full" : "h-dvh",
                "welcome-page",
                isMobile ? "mobile" : "",
                `keyboard-${keyboardStatus}`
            ].join(" ")}
        >
            <div
                className="header long-bar logo-bar overlay-blur items-center"
                style={{ backgroundColor: "transparent" }}
            >
                <div
                    className={`flex justify-center items-center gap-[4px] rounded-full py-[0.3125em]
                        float-left bg-glaut-cards px-[0.3125em]`}
                    style={{ maxWidth: headerChildComponentWidth }}
                >
                    <RiTimerLine />
                    <div>
                        {interviewSettings?.interview_duration || 5} {getCopy(copy.interview.minutes, lang)}
                    </div>
                </div>

                {interviewSettings?.logo && (
                    <img src={interviewSettings?.logo} alt="company-logo" />
                )}

                {availableLanguages.length > 1 && (
                    <ChooseLanguage
                        setting={{
                            options: availableLanguages,
                            alignRight: true,
                            hideSelectedOption: window.innerWidth <= widthBreakpoint,
                            stretchDropdownBar: true
                        }}
                        value={lang}
                        setValue={setLang}
                        width={isMobile ? headerChildComponentWidth : "fit-content"}
                    />
                )}
            </div>
            <div
                className={`flex flex-col mx-auto w-[min(800px,90%)] h-full flex-1 justify-evenly items-center 
                    whitespace-pre-line`}
                ref={currentMessageRef}
                style={{ color: interviewSettings?.accent_color }}
            >
                <p id="welcome-message" className={`font-light overflow-auto max-h-[50vh]
                    ${isMobile && ["open", "opening"].includes(keyboardStatus) ? "text-[13.33px]" : "text-[19.2px]"}    
                `}>
                    {interviewSettings?.intro_message?.[lang] ||
                        (
                            (getCopy(copy.interview.welcome, lang) ?? "") +
                            (askEmail ? (getCopy(copy.interview.provideEmail, lang) ?? "") : "")
                        )}
                </p>
                {!askEmail
                    ? (
                        <Button
                            id="button--start-interview-without-email"
                            className="primary"
                            label={getCopy(welcomePageCopy.start, lang)}
                            onClick={handleWelcomeFormSubmit}
                            disabled={!valid}
                        />
                    )
                    : (
                        <div
                            className={"flex flex-row gap-[0.5em] justify-center items-center w-full"}>
                            <Input
                                value={email}
                                placeholder={getCopy(
                                    interviewSettings?.intro_message
                                        ? copy.interview.typeMailHere
                                        : copy.interview.typeHere,
                                    lang
                                )}
                                onChange={e => setEmail(e.target.value)}
                                className="input line message-input w-[min(300px,60%)]"
                                onKeyDown={e => e.key === "Enter" && handleWelcomeFormSubmit(e)}
                                autofocus={!isMobile}
                                autofocusDelay={1000}
                                onFocus={() => onInputFocusChange(true)}
                                onBlur={() => onInputFocusChange(false)}
                            />
                            <Button
                                id="button--start-interview-with-email"
                                className="primary"
                                label={getCopy(welcomePageCopy.start, lang)}
                                onClick={handleWelcomeFormSubmit}
                                disabled={!valid}
                            />
                        </div>
                    )
                }
                {!interviewSettings.privacy_notice && (
                    <p className={`text-center text-glaut-stroke-button text-[11.11px] font-medium 
                                    max-w-[350px]`}>
                        {getCopy(welcomePageCopy.privacyNoticeWithOrgName, lang)?.()}
                    </p>
                )}
                {interviewSettings.privacy_notice && (
                    interviewSettings.privacy_notice_explicit_consent
                        ? (
                            <div className="flex flex-row items-center gap-[0.75em]">
                                {!hasConsentedPrivacyPolicy
                                    ? (
                                        <FaRegSquare
                                            className="text-glaut-midnight"
                                            onClick={() => { setHasConsentedPrivacyPolicy(true) }}
                                        />
                                    )
                                    : (
                                        <FaCheckSquare
                                            className="text-glaut-midnight"
                                            onClick={() => { setHasConsentedPrivacyPolicy(false) }}
                                        />
                                    )
                                }

                                <p className="text-glaut-stroke-button text-[11.11px] font-medium">
                                    {getCopy(welcomePageCopy.privacyNoticeAcceptanceWithOrgName, lang)?.(
                                        orgName,
                                        interviewSettings.privacy_policy_link ?? glautPrivacyNoticeUrl
                                    )}
                                </p>
                            </div>
                        )
                        : (
                            <p className="text-center text-glaut-stroke-button text-[11.11px] font-medium">
                                {getCopy(welcomePageCopy.privacyNoticeWithUrlAndOrgName, lang)?.(
                                    orgName,
                                    interviewSettings.privacy_policy_link ?? glautPrivacyNoticeUrl
                                )}
                            </p>
                        )
                )}
            </div>
            {hasBranding && (
                <div className="flex flex-col items-center w-full">
                    <a href="https://www.glaut.com" target="_blank" rel="noreferrer">
                        <img className="" src={BuiltWithGlaut} alt="built-with-glaut" />
                    </a>
                </div>
            )}
        </div>
    )
}

export default WelcomePage