import { IBlock, IInsight, IReport } from "@/@types/reports"
import { ArrayElement } from "@utils/types/array-element"
import { Fragment, useCallback } from "react"
import ReportDetailsNpsInsightBlock from "../ReportDetailsNpsInsightBlock"
import ReportDetailsOpenInsightBlock from "../ReportDetailsOpenInsightBlock"
import ReportDetailsSelectInsightBlock from "../ReportDetailsSelectInsightBlock"

interface IReportDetailsInsightsListProps {
    insights: (IBlock | IInsight)[]
}

export default function ReportDetailsInsightsList({ insights }: Readonly<IReportDetailsInsightsListProps>) {
    // #region Element callbacks
    const toBlockComponent = useCallback((block: ArrayElement<IReport["appendix"]>, index: number) => {
        if (block.type === "open")
            return (
                <ReportDetailsOpenInsightBlock
                    id={block.id}
                    key={index}
                    block={block}
                />
            )

        if (block.type === "select")
            return (
                <ReportDetailsSelectInsightBlock
                    id={block.id}
                    key={index}
                    block={block}
                />
            )

        if (block.type === "nps")
            return (
                <ReportDetailsNpsInsightBlock
                    id={block.id}
                    key={index}
                    block={block}
                />
            )

        console.warn("Unknown block type: ", block.type)
        return <Fragment key={index} />
    }, [])
    // #endregion

    return insights.map(toBlockComponent)
}