import PdfIcon from "@components/Pdf/PdfIcon"
import { Text, View } from "@react-pdf/renderer"
import { copy, getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { normalizeToColorIndex, textColorWithCssColorVarFromIndex } from "@utils/styling/colors-from-index"
import { namedColors } from "@utils/Variables"
import { useEffect, useMemo, useState } from "react"
import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import { FaTag } from "react-icons/fa"
import { MdOutlineChat } from "react-icons/md"
import { IInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"

interface IReportDetailsInsightBlockDetailsProps {
    block: IInsight
}

export default function ReportDetailsInsightBlockDetails({
    block
}: Readonly<IReportDetailsInsightBlockDetailsProps>) {
    // #region Contexts
    const {
        project,
        interviewQuestions,
        analyses,
        numberOfEntries,
        renderAsPdf,
        tw
    } = useReportDetails()
    // #endregion

    // #region States
    const [isExpanded, setIsExpanded] = useState(false)
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const indexedIqs = useMemo(
        () => interviewQuestions.map((iq, index) => ({ ...iq, index })),
        [interviewQuestions]
    )
    const indexedAnalyses = useMemo(
        () => analyses.map((analysis, index) => ({ ...analysis, index })),
        [analyses]
    )
    const selectedIqs = useMemo(
        () => {
            if (block.type !== "open")
                return indexedIqs.filter(iq => iq.id === block.source.id)

            return indexedIqs
                .filter(iq => block.source.sources.includes(iq.id))
        },
        [block, indexedIqs]
    )
    const filterByIqs = useMemo(
        // Note: intentional `indexedIqs.reduce` instead of `block.question_filters.reduce` => no need to sort later
        () => indexedIqs.reduce((formattedIqs, relatedIq) => {
            if (relatedIq.type !== "select") return formattedIqs

            const currFilter = block.question_filters.find(f => relatedIq.id === f.id)
            if (!currFilter) return formattedIqs

            const filterValues = Array.isArray(currFilter.value)
                ? currFilter.value.map(String)
                : [String(currFilter.value)]
            const categories = relatedIq.options.filter(opt => filterValues.includes(opt.id)).map(opt => ({
                id: opt.id,
                label: opt.label[lang] ?? ""
            }))

            if (!formattedIqs[relatedIq.index])
                formattedIqs[relatedIq.index] = {
                    header: `Q${Number(relatedIq.index) + 1}`,
                    label: relatedIq.content[lang] ?? relatedIq.header ?? "",
                    categories
                }
            else
                formattedIqs[relatedIq.index].categories.push(...categories)

            return formattedIqs
        }, {} as {
            [iqId: string]: { header: string, label: string, categories: { id: string, label: string }[] }
        }),
        [block.question_filters, indexedIqs, lang]
    )
    const filterByAnalyses = useMemo(
        () => indexedAnalyses.reduce((formattedAnalyses, relatedAnalysis) => {
            const currFilter = block.analysis_filters.find(f => relatedAnalysis.id === f.id)
            if (!currFilter) return formattedAnalyses

            const filterValues = Array.isArray(currFilter.value)
                ? currFilter.value.map(String)
                : [String(currFilter.value)]

            const categories = relatedAnalysis.categories.filter(c => filterValues.includes(c.id)).map(c => ({
                id: c.id,
                label: c.label
            }))

            if (!formattedAnalyses[relatedAnalysis.index])
                formattedAnalyses[relatedAnalysis.index] = {
                    header: `A${Number(relatedAnalysis.index) + 1}`,
                    label: relatedAnalysis.title ?? "",
                    categories
                }
            else
                formattedAnalyses[relatedAnalysis.index].categories.push(...categories)

            return formattedAnalyses
        }, {} as {
            [iqId: string]: { header: string, label: string, categories: { id: string, label: string }[] }
        }),
        [block.analysis_filters, indexedAnalyses]
    )
    const filterBy = useMemo(
        () => ({ ...filterByAnalyses, ...filterByIqs }),
        [filterByAnalyses, filterByIqs]
    )
    const breakdownByIq = useMemo(
        () => indexedIqs.find(iq => iq.id === block.segment_by?.id),
        [block, indexedIqs]
    )
    const isExpandable = useMemo(
        () => (selectedIqs?.length ?? 0) > 0 || Object.entries(filterBy ?? []).length > 0,
        [filterBy, selectedIqs?.length]
    )
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!isExpandable && isExpanded) setIsExpanded(false)
    }, [isExpandable, isExpanded])
    // #endregion

    if (renderAsPdf)
        return (
            <View style={[
                tw("flex flex-col border-1 rounded-[5.09px] px-[15.27px] py-[7px]"),
                tw("bg-glautCards border-glautVeryLightGrey")
            ]}>
                <View style={[
                    tw("flex flex-row items-center gap-[5.09px] w-full"),
                    isExpandable ? tw("pb-[7px]") : {}
                ]}>
                    <PdfIcon icon={MdOutlineChat} color={namedColors.grey} size={"11.45px"} />
                    <Text style={tw("font-sans text-[7.07px] text-glautTextMidnight")}>
                        {numberOfEntries} {getCopy(copy.reports.responsesAnalyzed)}
                    </Text>
                </View>
                <View style={tw("flex flex-col pl-[5.09px]")}>
                    {!!selectedIqs?.length && (
                        <>
                            <Text style={[
                                tw("font-sans font-medium text-[7.07px] leading-[10.6px] my-[2.14px]"),
                                tw("text-glautGrey")
                            ]}>
                                {getCopy(copy.reports.selectedIqs)}
                            </Text>
                            <View style={tw("px-[5.09px]")}>
                                {selectedIqs.map(iq => (
                                    <View key={iq.id} style={tw("flex flex-row gap-[5.09px] py-[2.14px]")}>
                                        <Text style={tw("font-sans font-medium text-glautGrey text-[7.07px]")}>
                                            Q{iq.index}
                                        </Text>
                                        <Text style={tw("font-sans font-medium text-glautTextMidnight text-[7.07px]")}>
                                            {iq.content[lang] ?? iq.header}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        </>
                    )}
                    {Object.entries(filterBy ?? {}).length > 0 && (
                        <>
                            <Text style={[
                                tw("font-sans font-medium text-[7.07px] leading-[10.6px] py-[2.14px]"),
                                tw("text-glautGrey")
                            ]}>
                                {getCopy(copy.reports.filterBy)}
                            </Text>
                            {Object.entries(filterBy).map(([iqIndex, data]) => (
                                <View
                                    key={iqIndex}
                                    style={tw("flex flex-col px-[5.09px]")}
                                >
                                    <View style={tw("flex flex-row items-center gap-[5.09px] py-[2.14px]")}>
                                        <Text style={tw("font-sans font-medium text-glautGrey text-[7.07px]")}>
                                            {data.header}
                                        </Text>
                                        <Text style={tw("font-sans font-medium text-glautTextMidnight text-[7.07px]")}>
                                            {data.label}
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            tw("flex flex-row flex-wrap pl-[15.09px]"),
                                            { rowGap: "0", columnGap: "5.09px" }
                                        ]}>
                                        {data.categories.map((category, categoryIdx) => (
                                            <View
                                                key={category.id}
                                                style={[
                                                    tw("flex flex-row items-center gap-[5.09px]"),
                                                    tw("py-[2.14px] pl-[2.14px]")
                                                ]}
                                            >
                                                <PdfIcon
                                                    icon={FaTag}
                                                    size={"10.18px"}
                                                    color={namedColors[
                                                        `color${normalizeToColorIndex(categoryIdx)}`
                                                    ]}
                                                />
                                                <Text
                                                    style={[
                                                        tw("font-sans text-[7.07px] font-medium"),
                                                        tw("text-glautTextMidnight")
                                                    ]}
                                                >
                                                    {category.label}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            ))}
                        </>
                    )}
                    {breakdownByIq && (
                        <>
                            <Text style={tw("font-sans font-medium text-glautGrey text-[7.07px] py-[2.14px]")}>
                                {getCopy(copy.reports.breakdownBy)}
                            </Text>
                            <View key={breakdownByIq.id} style={tw("flex flex-row gap-[5.09px] py-[2.14px] px-[5.09]")}>
                                <Text style={tw("font-sans font-medium text-glautGrey text-[7.07px]")}>
                                    Q{breakdownByIq.index + 1}
                                </Text>
                                <Text style={tw("font-sans font-medium text-glautTextMidnight text-[7.07px]")}>
                                    {breakdownByIq.content[lang]}
                                </Text>
                            </View>
                        </>
                    )}
                </View>
            </View>
        )

    return (
        <div className={`flex flex-col border-1 rounded-[0.5rem] py-[0.5rem] pr-[0.5rem] pl-[1.5rem]
            bg-glaut-cards border-glaut-very-light-grey
        `}>
            <button
                type="button"
                className="rounded-none border-none bg-transparent p-0 shadow-none flex justify-between w-full group
                    [&_*]:transition-colors [&_*]:duration-300"
                onClick={() => { setIsExpanded(prev => !prev) }}
                disabled={!isExpandable}>
                <div className="flex gap-[0.5rem] items-center">
                    <MdOutlineChat
                        className="text-glaut-grey w-[1.25rem] h-[1.25rem] group-hover:text-glaut-pink-dark"
                        style={{ transition: "0s" }} // Needed to avoid conflict with the CSS files in `styling` folder
                    />
                    <p className="text-[11.11px] text-glaut-text-midnight group-hover:text-glaut-pink-dark">
                        {numberOfEntries} {getCopy(copy.reports.responsesAnalyzed)}
                    </p>
                </div>
                <div className="flex gap-[0.75rem] items-center">
                    {isExpandable && (
                        <>
                            <p className="text-glaut-dark-grey font-medium text-[11.11px] 
                                group-hover:text-glaut-pink-dark">
                                {getCopy(isExpanded ? copy.reports.hideDetails : copy.reports.showDetails)}
                            </p>
                            {isExpanded && (
                                <BiChevronUp
                                    className="text-glaut-dark-grey group-hover:text-glaut-pink-dark"
                                    style={{ transition: "0s" }}
                                />
                            )}
                            {!isExpanded && (
                                <BiChevronDown
                                    className="text-glaut-dark-grey group-hover:text-glaut-pink-dark"
                                    style={{ transition: "0s" }}
                                />
                            )}
                        </>
                    )}
                    {!isExpandable && (
                        <>
                            <p className="text-glaut-grey font-medium text-[11.11px]">
                                {getCopy(copy.reports.noDetailsToShow)}
                            </p>
                            <BiChevronDown className="text-glaut-grey" />
                        </>
                    )}
                </div>
            </button>
            <div className={`transition-[height,opacity,margin] overflow-hidden
                ${isExpanded ? "h-max opacity-100 mt-[0.75rem] ml-[0.5rem]" : "h-0 opacity-0"}
            `}>
                <div className="flex flex-col">
                    {!!selectedIqs?.length && (
                        <>
                            <p className="font-medium text-glaut-grey text-[11.11px] py-[0.25rem]">
                                {getCopy(copy.reports.selectedIqs)}
                            </p>
                            {selectedIqs.map(iq => (
                                <div key={iq.id} className="flex gap-[0.5rem] py-[0.25rem] px-[0.5rem]">
                                    <p className="font-medium text-glaut-grey text-[11.11px]">
                                        Q{iq.index + 1}
                                    </p>
                                    <p className="font-medium text-glaut-text-midnight text-[11.11px]">
                                        {iq.content[lang] ?? iq.header}
                                    </p>
                                </div>
                            ))}
                        </>
                    )}
                    {Object.entries(filterBy ?? {}).length > 0 && (
                        <>
                            <p className="font-medium text-glaut-grey text-[11.11px]">
                                {getCopy(copy.reports.filterBy)}
                            </p>
                            {Object.entries(filterBy ?? {}).map(([iqIndex, data]) => (
                                <div
                                    key={iqIndex}
                                    className="flex flex-col px-[0.5rem]"
                                >
                                    <div className="flex items-center gap-[0.5rem] py-[0.25rem]">
                                        <p className="font-medium text-glaut-grey text-[11.11px]">
                                            {data.header}
                                        </p>
                                        <p className="font-medium text-glaut-text-midnight text-[11.11px]">
                                            {data.label}
                                        </p>
                                    </div>
                                    <div
                                        className="flex flex-wrap pl-[1.5rem]"
                                        style={{ rowGap: "0", columnGap: "0.5em" }}>
                                        {data.categories.map((category, categoryIdx) => (
                                            <div
                                                key={category.id}
                                                className="flex gap-[0.5rem] py-[0.25rem] pl-[0.5rem]"
                                            >
                                                <FaTag className={`h-[1em] w-[1em] rounded-none
                                                ${textColorWithCssColorVarFromIndex(categoryIdx)}
                                            `} />
                                                <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                                                    {category.label}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {breakdownByIq && (
                        <>
                            <p className="font-medium text-glaut-grey text-[11.11px] py-[0.25rem]">
                                {getCopy(copy.reports.breakdownBy)}
                            </p>
                            <div key={breakdownByIq.id} className="flex gap-[0.5rem] py-[0.25rem] px-[0.5rem]">
                                <p className="font-medium text-glaut-grey text-[11.11px]">
                                    Q{breakdownByIq.index + 1}
                                </p>
                                <p className="font-medium text-glaut-text-midnight text-[11.11px]">
                                    {breakdownByIq.content[lang]}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}