import { IInterviewQuestion } from "@/@types/interview-question"
import Branch from "@components/Icons/Branch"
import GlautTooltip from "@components/layouts/GlautTooltip"
import { Drag } from "@components/menus/DragAndDrop"
import ConfirmModal from "@components/Modals/ConfirmModal"
import { useProjectService } from "@hooks/services/useProjectService"
import { copy, getCopy } from "@utils/Copy"
import { questionTypeDetails } from "@utils/project/interview-questions"
import { namedColors } from "@utils/Variables"
import { useCallback, useMemo, useState } from "react"
import { MdClose } from "react-icons/md"
import { v4 as uuid } from "uuid"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import BranchComponent from "../OutlineTabContentIqsQuestionListItemBranchComponent"
import { useConfirmationModalDispatch } from "@contexts/ConfirmationModalProvider"
import { outlineTabIqsQuestionListItemCopy } from "./utils/copy"

interface IOutlineTabContentIqsQuestionListItemProps {
    interviewQuestion: Partial<IInterviewQuestion>
    /**
     * 0-indexed position of the IQ (for ordering purposes).
     */
    index: number
}

export default function OutlineTabContentIqsQuestionListItem({
    interviewQuestion,
    index
}: Readonly<IOutlineTabContentIqsQuestionListItemProps>) {
    // #region Contexts
    const { project, lang } = useLegacyProject()
    const projectDispatch = useProjectDispatch()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt, hasIqToBeBuiltChanged } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    const confirmationModalDispatch = useConfirmationModalDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Memos
    const isSelected = useMemo(
        () => interviewQuestion.id === iqToBeBuilt?.id,
        [interviewQuestion.id, iqToBeBuilt?.id]
    )
    const questionId = useMemo(() => interviewQuestion.id ?? uuid(), [interviewQuestion.id])

    const tooltipId = useMemo(() => `tooltip--outline-tab-${questionId}-branch-logics`, [questionId])
    // #endregion

    // #region Callbacks
    const handleSelectIqForEdition = useCallback(() => {
        if (!interviewQuestion.id || iqToBeBuilt?.id === interviewQuestion.id) return

        function onConfirm() {
            outlineTabIqsDispatch({
                type: "set-iq-to-be-built",
                iq: interviewQuestion,
                isEditing: true,
                // Reload original IQ to unblock "simulate question" button
                shouldReloadOriginalIq: true
            })
        }

        if (!hasIqToBeBuiltChanged) {
            onConfirm()
            return
        }

        confirmationModalDispatch({
            type: "create-confirmation-modal",
            message: getCopy(outlineTabIqsQuestionListItemCopy.youHaveUnsavedChanges) ?? "",
            onConfirm,
            onConfirmLabel: getCopy(copy.words.yes) ?? "",
            onCloseLabel: getCopy(copy.words.no) ?? ""
        })
    }, [interviewQuestion, iqToBeBuilt?.id, outlineTabIqsDispatch, confirmationModalDispatch, hasIqToBeBuiltChanged])

    const handleRemoveIq = useCallback(() => {
        if (!project || !interviewQuestion.id) return

        const projectId = project._id
        const questionId = interviewQuestion.id

        projectService.deleteInterviewQuestion({ projectId, questionId }).then(() => {
            projectDispatch({ type: "delete-interview-question", interviewQuestionId: questionId })
            outlineTabIqsDispatch({
                type: "set-iq-attribute",
                attribute: "filters",
                value: iqToBeBuilt?.filters?.filter(f => f.id !== questionId) ?? []
            })
            setIsDeleteModalOpen(false)

            if (isSelected) outlineTabIqsDispatch({ type: "clear-iq-being-built" })
        })
    }, [interviewQuestion.id, isSelected, iqToBeBuilt, outlineTabIqsDispatch, project, projectDispatch, projectService])
    // #endregion

    // #region Element memos
    const questionLabelElement = useMemo(() => {
        const label = interviewQuestion.content?.[lang] || interviewQuestion.header
        if (!label)
            return (
                <p className="text-[13.33px] font-medium text-glaut-grey">
                    {getCopy(copy.outline.interviewQuestions.defineTheQuestionEllipsis)}
                </p>
            )

        return (
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {label}
            </p>
        )
    }, [interviewQuestion.content, interviewQuestion.header, lang])
    // #endregion

    if (!interviewQuestion.type) return <></>

    return (
        <>
            <Drag id={questionId} index={index} key={interviewQuestion.id} disabled={!canEdit || !interviewQuestion.id}>
                <button className={`flex flex-col gap-[0.3125rem] px-[0.75rem] py-[0.625rem] rounded-[0.25rem] border-1
                    shadow-none m-0 w-full items-start text-start
                    bg-glaut-very-light-grey
                    ${isSelected ? "border-glaut-bar" : "border-transparent"}
                `} onClick={handleSelectIqForEdition}>
                    <div className="flex flex-col gap-[0.3125rem] w-full">
                        <div className="flex justify-between items-center">
                            <div className="flex">
                                <p className={`text-[11.11px] font-medium 
                                    ${isSelected ? "text-glaut-text-midnight" : "text-glaut-dark-grey"}
                                `}>
                                    {getCopy(copy.words.question)} {index + 1} -{" "}
                                    <span className="font-medium text-glaut-stroke-button">
                                        {questionTypeDetails(lang)[interviewQuestion.type].title}
                                    </span>
                                </p>
                            </div>
                            {!!interviewQuestion.filters?.length && (
                                <div data-tooltip-id={tooltipId} data-tooltip-place="right">
                                    <Branch fill={namedColors["dark-grey"]} />
                                </div>
                            )}
                        </div>
                        {questionLabelElement}
                    </div>
                </button>
                {interviewQuestion.id && (
                    <button
                        className="border-none shadow-none bg-transparent text-base p-0 m-0 opacity-0 
                            group-hover/drag:opacity-100 disabled:invisible"
                        onClick={() => { setIsDeleteModalOpen(true) }}
                        disabled={!canEdit}
                    >
                        <MdClose className="h-[1rem] w-[1rem] text-glaut-dark-grey" />
                    </button>
                )}
                {!interviewQuestion.id && (
                    <div className="w-[1rem]"></div> // for alignment
                )}
            </Drag>
            <ConfirmModal
                open={isDeleteModalOpen}
                onClose={() => { setIsDeleteModalOpen(false) }}
                onConfirm={handleRemoveIq}
            >
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.interviewQuestions.areYouSureYouWantToDeleteThisQuestion)}
                </p>
            </ConfirmModal>
            <GlautTooltip id={tooltipId}> {/** needed for dynamic content */}
                <BranchComponent interviewQuestion={interviewQuestion} />
            </GlautTooltip>
        </>
    )
}