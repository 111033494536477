import { IQuote } from "@/@types/reports"
import { useCallback, useState } from "react"
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa"

interface IReportDetailsInsightBlockThemeQuotesProps {
    quotes: IQuote[]
}

export default function ReportDetailsInsightBlockThemeQuotes({
    quotes
}: Readonly<IReportDetailsInsightBlockThemeQuotesProps>) {
    // #region States
    const [quoteBeingShownIdx, setQuoteBeingShownIdx] = useState<number>(0)
    // #endregion

    // #region Callbacks
    const handleShowNextQuote = useCallback(() => {
        setQuoteBeingShownIdx(prev => {
            if (prev + 1 >= quotes.length) return prev
            return prev + 1
        })
    }, [quotes])

    const handleShowPrevQuote = useCallback(() => {
        setQuoteBeingShownIdx(prev => {
            if (prev - 1 < 0) return prev
            return prev - 1
        })
    }, [])
    // #endregion

    return (
        <div className="flex justify-between gap-[0.75rem] p-[1.5rem] rounded-[0.75rem] bg-glaut-cards h-[185px]">
            <button onClick={handleShowPrevQuote} className="p-0 shadow-none border-0 bg-transparent self-center">
                <FaChevronCircleLeft className={`h-[1.125rem] w-[1.125rem]
                    ${quoteBeingShownIdx === 0 ? "text-glaut-stroke-glaut cursor-not-allowed" : ""}
                    ${quoteBeingShownIdx !== 0 ? "text-glaut-dark-grey" : ""}
                `} />
            </button>
            <div className="flex-1 self-center overflow-y-auto rounded-none max-h-full">
                <span className="text-[16px] text-glaut-text-midnight font-serif">
                    &quot;{quotes[quoteBeingShownIdx]?.body}&quot;
                </span>
            </div>
            <button onClick={handleShowNextQuote} className="p-0 shadow-none border-0 bg-transparent self-center">
                <FaChevronCircleRight className={`h-[1.125rem] w-[1.125rem]
                    ${quoteBeingShownIdx === quotes.length - 1 ? "text-glaut-stroke-glaut cursor-not-allowed" : ""}
                    ${quoteBeingShownIdx !== quotes.length - 1 ? "text-glaut-dark-grey" : ""}
                `} />
            </button>
        </div>
    )
}
