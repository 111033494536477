import { DetailedHTMLProps, InputHTMLAttributes } from "react"

export interface IGlautSwitchProps
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    designMode?: "default" | "ai"
}

export default function GlautSwitch({
    designMode = "default",
    ...props
}: Readonly<IGlautSwitchProps>) {
    return (
        <label className={`inline-flex relative w-max items-center cursor-pointer border-1 rounded-full bg-glaut-grey
            ${designMode === "default" ? "has-[:checked]:bg-glaut-pink" : ""}
            ${designMode === "ai" ? "has-[:checked]:bg-glaut-gradient-1" : ""}
        `}>
            <input type="checkbox" value="" className="sr-only peer" {...props} />
            <div className={`relative w-9 h-5 rounded-full peer
                bg-glaut-very-light-grey
                after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#79747E] after:border
                after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-transform
                peer-focus:outline-none
                peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                peer-checked:after:border-white
                peer-checked:after:bg-white
                peer-disabled:cursor-not-allowed
                ${designMode === "default" ? "peer-checked:bg-glaut-pink" : ""}
                ${designMode === "ai" ? "peer-checked:bg-glaut-gradient-1" : ""}
            `}></div>
        </label>
    )
}