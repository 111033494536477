import ErrorLevel from "@utils/ErrorLevel"
import { GoAlertFill } from "react-icons/go"
import { IoCloseSharp } from "react-icons/io5"
import { MdInfo } from "react-icons/md"

import "./index.css"

interface ITopBarMessageProps {
    message: string
    messageLevel: number
    setMessage: () => void
}

const TopBarMessage = ({
    message,
    messageLevel = ErrorLevel.Info,
    setMessage
}: Readonly<ITopBarMessageProps>) => {
    const className = messageLevel >= ErrorLevel.Warning ? "warning" : "info"

    if (!message) return <></>

    return (
        <div className={"fixed top-0 left-0 right-0 top-bar-message z-50 " + className}>
            <span className="left">
                {messageLevel >= ErrorLevel.Warning ? <GoAlertFill /> : <MdInfo />}
            </span>
            <span>{message}</span>
            <span className="right">
                <IoCloseSharp onClick={() => { setMessage() }} />
            </span>
        </div>
    )
}

export default TopBarMessage
