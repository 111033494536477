import { ISelectScreeningOption } from "@/@types/interview-question"
import GlautInputText from "@components/inputs/GlautInputText"
import { copy, getCopy } from "@utils/Copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { MdRemoveCircle } from "react-icons/md"

interface IEditScreeningQuotaOptionProps {
    option: RecursivePartial<ISelectScreeningOption>
    lang: string
    disabled: boolean
    isRemovalAllowed?: boolean
    valid?: boolean
    handleRemoveOption?: (id: string) => void
    handleChangeOptionLabel?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
    handleChangeOptionQuota?: (quota: number, id: string) => void
}

export default function EditScreeningQuotaOption({
    option,
    lang,
    disabled,
    isRemovalAllowed = false,
    valid = true,
    handleRemoveOption,
    handleChangeOptionLabel,
    handleChangeOptionQuota
}: Readonly<IEditScreeningQuotaOptionProps>) {
    return (
        <div className="flex gap-[0.25rem]">
            <div className="flex-1">
                <GlautInputText
                    value={option.label?.[lang] ?? ""}
                    onChange={e => option.id && handleChangeOptionLabel?.(e, option.id)}
                    disabled={disabled}
                    placeholder={getCopy(copy.actions.writeHereEllipsis) ?? ""}
                    noBorder
                />
            </div>
            <div className="flex gap-[0.25rem] items-center flex-1">
                <div className="flex flex-1 justify-center">
                    <input
                        className={`shadow-none p-0 m-0 focus:shadow-none border-1 rounded-[0.25rem] max-w-10
                            text-center text-glaut-text-midnight
                            ${valid ? "border-glaut-very-light-grey" : "border-glaut-codes-red"}
                            ${valid ? "focus:border-glaut-very-light-grey" : "focus:border-glaut-codes-red"}
                            placeholder:text-glaut-grey disabled:cursor-not-allowed
                        `}
                        placeholder="100"
                        value={typeof option?.quota === "number" ? option.quota : ""}
                        onChange={e => {
                            if (!option.id) return
                            const rawValue = e.target.value
                            if (isNaN(Number(rawValue))) return

                            const value = rawValue !== "" ? Number(e.target.value) : 0

                            handleChangeOptionQuota?.(value > 100 ? 100 : value, option.id)
                        }}
                        disabled={disabled}
                    />
                </div>
                <button
                    className="border-none shadow-none p-0 m-0"
                    disabled={!isRemovalAllowed || disabled}
                    onClick={() => isRemovalAllowed && option.id && handleRemoveOption?.(option.id)}
                >
                    <MdRemoveCircle
                        className={`${!isRemovalAllowed ? "text-glaut-stroke-glaut" : "text-glaut-pink"}`}
                    />
                </button>
            </div>
        </div>
    )
}
