import LoadingBar from "@components/loading/LoadingBar"
import {
    bgColorWithCssColorVarFromIndex,
    textColorWithCssColorVarFromIndex
} from "@utils/styling/colors-from-index"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FaHashtag, FaTag } from "react-icons/fa"
import { MdLayers, MdManageSearch } from "react-icons/md"
import { IInsight, IQuote } from "src/@types/reports"
import ReportDetailsInsightBlockThemeQuotes from "../ReportDetailsInsightBlockThemeQuotes"
import ReportDetailsInsightBlockThemeSegmentedTheme from "../ReportDetailsInsightBlockThemeSegmentedTheme"
import { getCopy } from "@utils/Copy"
import { reportDetailsInsightBlockThemeItemCopy } from "./utils/copy"

interface IReportDetailsInsightBlockThemeItemProps {
    renderAsPdf?: boolean
    block: IInsight
    isExporting: boolean
    theme: {
        index: number
        categoryId: string
        label: string
        description: string
        percentage: number
        occurrences: number
        quotes: IQuote[]
        children: {
            id: string
            label: string
            frequency: number
            occurrences: number
        }[]
    }
}

export default function ReportDetailsInsightBlockThemeItem({
    block,
    isExporting,
    theme
}: Readonly<IReportDetailsInsightBlockThemeItemProps>) {
    // #region States
    const [quotesVisibilityState, setQuotesVisibilityState] = useState<"hidden" | "hiding" | "showing" | "visible">(
        "hidden"
    )
    // #endregion

    // #region Memos
    const isSegmentingApplied = useMemo(() => !!block.segment_by, [block])
    const toggleQuotesVisibilityLabelCopy = useMemo(
        () => ["hidden", "hiding"].includes(quotesVisibilityState)
            ? reportDetailsInsightBlockThemeItemCopy.showQuotes
            : reportDetailsInsightBlockThemeItemCopy.hideQuotes,
        [quotesVisibilityState]
    )
    // #endregion

    // #region Callbacks
    const handleQuotesVisibilityStateToggle = useCallback(() => {
        setQuotesVisibilityState(prev => {
            if (prev === "hidden") return "showing"
            if (prev === "visible") return "hiding"

            return prev
        })
    }, [])
    // #endregion

    // #region Effects

    // onTransientStateChange
    useEffect(() => {
        if (quotesVisibilityState === "showing") setTimeout(() => setQuotesVisibilityState("visible"), 100)
        if (quotesVisibilityState === "hiding") setTimeout(() => setQuotesVisibilityState("hidden"), 100)
    }, [quotesVisibilityState])

    // #endregion

    return (
        <div key={theme.categoryId} className="border-b-1 border-b-glaut-stroke-glaut">
            <div
                className={`flex gap-[0.5rem] px-[0.5rem] py-[0.25rem] w-full
                    lg:flex-row lg:justify-between 
                    ${block.type === "select" ? "flex-row justify-between" : "flex-col"}
                `}
            >
                <div className={`flex items-center gap-[0.5rem] w-full
                    lg:max-w-[65%] xl:max-w-[55%] 2xl:max-w-[65%]
                `}>
                    {isSegmentingApplied && (
                        <MdLayers className={`w-[1.125rem] h-[1.125rem]
                            ${textColorWithCssColorVarFromIndex(theme.index)}
                        `} />
                    )}
                    {!isSegmentingApplied && (
                        <FaTag className={`w-[1em] h-[1em]
                            ${textColorWithCssColorVarFromIndex(theme.index)}
                        `} />
                    )}
                    <p className={`text-[13.33px] font-medium text-glaut-text-midnight
                        overflow-hidden whitespace-nowrap text-ellipsis
                    `}>
                        {theme.label}
                    </p>
                    {block.type === "open" && !isExporting && (
                        <MdManageSearch
                            data-tooltip-id="tooltip--glaut-app"
                            data-tooltip-content={theme.description}
                            data-tooltip-place="right"
                            data-tooltip-class-name="max-w-[400px]"
                            className="w-[1.125rem] h-[1.125rem] text-glaut-grey hover:text-glaut-dark-grey"
                        />
                    )}
                </div>
                <div className="flex items-center min-w-[35%] justify-end pt-[0.5rem] lg:pt-0">
                    <p className="text-[19.2px] text-glaut-text-midnight min-w-12">
                        {Math.round(theme.percentage * 100)}%
                    </p>
                    {block.type === "open" && (
                        <LoadingBar
                            percentage={theme.percentage * 100}
                            showPercentage={false}
                            loadingBarFullClassName={`loading-bar-full
                                ${bgColorWithCssColorVarFromIndex(theme.index)}
                            `}
                            loadingBarDotClassName={`loading-bar-dot
                                ${bgColorWithCssColorVarFromIndex(theme.index)}
                            `}
                            style={{ flex: 1 }}
                        />
                    )}
                    <div className="flex items-center justify-end gap-[0.25rem] min-w-12">
                        <FaHashtag className="w-[0.875rem] h-[0.875rem] text-glaut-grey mb-[0.20em]" />
                        <p className="text-[19.2px] text-glaut-text-midnight">
                            {theme.occurrences}
                        </p>
                    </div>
                    {theme.quotes.length > 0 && (
                        <button
                            className={`p-0 shadow-none border-1 rounded-full py-[0.125rem] px-[0.5rem] ml-[0.25rem]
                                text-[11.11px] font-medium
                                border-transparent text-glaut-midnight bg-glaut-cards
                                hover:border-glaut-dummie-color2
                            `}
                            onClick={handleQuotesVisibilityStateToggle}
                        >
                            {getCopy(toggleQuotesVisibilityLabelCopy)}
                        </button>
                    )}
                </div>
            </div>
            {isSegmentingApplied && (
                <div
                    className="px-[0.75rem] py-[0.5rem] flex flex-wrap"
                    style={{ rowGap: "1em", columnGap: "0.5em" }}
                >
                    {theme.children.map(segmentedTheme => (
                        <ReportDetailsInsightBlockThemeSegmentedTheme
                            key={segmentedTheme.id}
                            colorIndex={theme.index}
                            label={segmentedTheme.label}
                            frequency={segmentedTheme.frequency}
                            occurrences={segmentedTheme.occurrences}
                        />
                    ))}
                </div>
            )}
            <div className={`transition-[opacity,height]
                ${quotesVisibilityState === "hidden" ? "opacity-0 h-0 hidden" : ""}
                ${quotesVisibilityState === "showing" ? "opacity-0 h-auto" : ""}
                ${quotesVisibilityState === "visible" ? "opacity-100 h-auto" : ""}
                ${quotesVisibilityState === "hiding" ? "opacity-0 h-auto" : ""}
            `}>
                <ReportDetailsInsightBlockThemeQuotes quotes={theme.quotes} />
            </div>
        </div>
    )
}