import { FaFileSignature } from "react-icons/fa"
import { format } from "date-fns"
import { analysisTabAddInsightToReportModalSelectLabelCopy as copy } from "./utils/copy"
import { getCopy } from "@utils/Copy"

interface IAnalysisTabAddInsightToReportModalSelectLabelProps {
    title: string
    lastModified: string
}

export default function AnalysisTabAddInsightToReportModalSelectLabel({
    lastModified,
    title
}: Readonly<IAnalysisTabAddInsightToReportModalSelectLabelProps>) {
    return (
        <div className="flex gap-[1rem] py-[0.25rem] px-[1rem] bg-glaut-cards items-center">
            <FaFileSignature className="text-glaut-grey w-[1.25rem] h-[1.25rem]" />
            <div className="flex flex-col">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">{title}</p>
                <p className="text-[11.11px] font-medium text-glaut-grey">
                    {getCopy(copy.lastModifiedOn)} {format(new Date(lastModified), "dd/MM/yyyy")}
                </p>
            </div>
        </div>
    )
}