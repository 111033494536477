import "./styling/tailwind.css"

import GlautTooltip from "@components/layouts/GlautTooltip"
import ConfirmationModalProvider from "@contexts/ConfirmationModalProvider"
import ToastProvider from "@contexts/ToastProvider"
import TrackInteractionProvider from "@contexts/TrackInteractionProvider"
import { AuthProvider } from "@propelauth/react"
import * as Sentry from "@sentry/react"
import { PostHogProvider } from "posthog-js/react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { settings } from "./utils/Variables"

if (settings.sentryDsn)
    Sentry.init({
        dsn: settings.sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        environment: settings.environment,
        release: settings.release,
        tracesSampleRate: settings.sentryTracesSampleRate,
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/app\.glaut\.com\/api/,
            /^https:\/\/app\.staging\.glaut\.com\/api/
        ],
        replaysSessionSampleRate: settings.sentryReplaysSessionSampleRate,
        replaysOnErrorSampleRate: 1.0
    })
else console.warn("Sentry DSN missing")

const root = ReactDOM.createRoot(document.getElementById("root")!)
root.render(
    <PostHogProvider
        apiKey={settings.posthogKey}
        options={settings.posthogOptions}
    >
        <TrackInteractionProvider>
            <BrowserRouter>
                <ToastProvider>
                    <ConfirmationModalProvider>
                        <AuthProvider authUrl={settings.authUrl}>
                            <App />
                            <GlautTooltip />
                        </AuthProvider>
                    </ConfirmationModalProvider>
                </ToastProvider>
            </BrowserRouter>
        </TrackInteractionProvider>
    </PostHogProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
