export const interviewSettingsTabProjectEmbeddingInstructionsCopy = {
    embedGlautInterview: {
        en: "Embed Glaut interview"
    },
    heresHowToEmbedAGlautInterviewIntoA3rdPartyWebsite: {
        en: "Here’s how to embed a Glaut interview into a 3rd-party website. Follow the steps below to ensure a smooth "
            + "integration"
    },
    step1CopyTheCode: {
        en: "Step 1: Copy the code"
    },
    clickTheCopyIconNextToTheEmbedCode: {
        en: "Click the copy icon next to the embed code below to copy it."
    },
    step2PasteTheCode: {
        en: "Step 2: Paste the code"
    },
    pasteTheCodeWhereYouWantTheInterviewToAppearOnYourWebsite: {
        en: "Paste the code where you want the interview to appear on your website. Place it in the body of the page "
            + "(before the closing </body> tag)."
    },
    heresYourUniqueEmbedCodeForThisProject: {
        en: "Here’s your unique embed code for this project"
    }
} as const