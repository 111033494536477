import Sprinkles from "@components/Icons/Sprinkles"

interface IAiLabelProps {
    children: React.ReactNode
}

export default function AiLabel({ children }: Readonly<IAiLabelProps>) {
    return (
        <div className="flex items-center gap-[0.25rem]">
            <Sprinkles designMode="ai" className="w-[1rem] h-[1rem]" />
            <p className="text-[13.33px] bg-clip-text bg-glaut-icon-gradient-1 text-transparent
                rounded-none">
                {children}
            </p>
        </div>
    )
}