import { MutableRefObject, useMemo } from "react"
import { MdOutlineVolumeOff, MdOutlineVolumeUp } from "react-icons/md"
import { Tooltip } from "react-tooltip"
import { copy, getCopy } from "../../utils/Copy"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"

interface IConversationHeaderProps {
    interviewSettings?: {
        accent_color?: string
        logo?: string
    }
    running: boolean
    completion: number
    voice: boolean
    setVoice: React.Dispatch<React.SetStateAction<boolean>>
    customRef?: MutableRefObject<HTMLDivElement>
}

export default function ConversationHeader({
    interviewSettings,
    running,
    completion,
    voice,
    setVoice,
    customRef
}: Readonly<IConversationHeaderProps>) {
    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region Memos
    const tooltipId = useMemo(() => "glaut-voice-icon-tooltip", [])
    const tooltipContent = useMemo(
        () => voice ? getCopy(copy.interview.disableModeratorVoice) : getCopy(copy.interview.enableModeratorVoice),
        [voice]
    )
    // #endregion

    return (
        <div ref={customRef} className="fixed top-0 left-0 right-0 w-full box-border z-40">
            <div
                className="h-[4px] w-full transition-[width] ease-in-out duration-[2s]"
                style={{
                    backgroundColor: interviewSettings?.accent_color,
                    width: `${running ? completion : 100}%`
                }}
            ></div>
            <div className={`flex flex-row items-center justify-between gap-[1em] w-full py-[1em] px-[max(2vw,1em)]
                bg-glaut-off-white`}>
                <div className="m-[0.5em] w-[20px]"></div>
                {interviewSettings?.logo && (
                    <img
                        src={interviewSettings?.logo}
                        className="max-h-[min(7em,8vh)] max-w-[40%] rounded-none h-auto block align-middle"
                        alt="company-logo"
                    />
                )}
                <div
                    className="p-[0.5em] rounded-[8px] shadow-[0_0_8px_0_rgba(0,0,0,0.15)] cursor-pointer"
                    onClick={() => setVoice(s => !s)}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={tooltipContent}
                    data-tooltip-place="bottom"
                >
                    {voice
                        ? (
                            <MdOutlineVolumeUp className="w-[24px] h-[24px] text-glaut-midnight" />
                        )
                        : (
                            <MdOutlineVolumeOff className="w-[24px] h-[24px] text-glaut-codes-red" />
                        )}
                </div>
            </div>
            {!isMobile && <Tooltip id={tooltipId} style={{ zIndex: 100 }} />}
        </div>
    )
}
