import GlautTogglesV2 from "@components/inputs/GlautTogglesV2"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { interviewLayoutOptions } from "./constants/interview-layout-options"
import { useCallback } from "react"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import { copy, getCopy } from "@utils/Copy"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"

export default function OutlineTabContentOverviewInterviewLayout() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleChange = useCallback((value: string) => {
        setProject(prev => prev?.source === "interview" ? ({
            ...prev,
            interview_settings: {
                ...prev.interview_settings,
                voice_first: value === "true"
            }
        }) : prev)
    }, [setProject])
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col gap-[0.75rem]">
            <div className="flex-1">
                <OutlineTabContentDefaultTitle
                    title={getCopy(copy.outline.overview.interviewLayout) ?? ""}
                    subtitle={getCopy(copy.outline.overview.voiceFirstInterviewGivesMoreRelevanceToTheMicrophone) ?? ""}
                />
            </div>
            <div className="flex justify-center my-[0.75rem] xl:w-[80%] 2xl:w-[70%] self-center">
                <div className="flex-1">
                    <GlautTogglesV2
                        availableSelections={interviewLayoutOptions}
                        value={String(project.interview_settings?.voice_first ?? "true")}
                        onChange={handleChange}
                        disabled={!canEdit}
                    />
                </div>
            </div>
        </div>
    )
}