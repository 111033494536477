import { useMemo } from "react"
import { useAnalysisTab } from "../contexts/AnalysisTabProvider"
import { useProject } from "../contexts/ProjectProvider"

export function useAnalysisTabTotalResponses() {
    // #region Contexts
    const { numberOfEntries } = useProject()
    const {
        selectedAnalysis,
        analysesStats,
        selectedInterviewQuestionId,
        questionsStats,
        interviewCount
    } = useAnalysisTab()
    // #endregion

    // #region Memos
    const projectTotalResponses = useMemo(() => interviewCount || numberOfEntries, [interviewCount, numberOfEntries])

    const analysisTotalResponses = useMemo(() => {
        if (selectedAnalysis)
            return (analysesStats[selectedAnalysis.id]?.interview_count ?? projectTotalResponses)

        if (selectedInterviewQuestionId) {
            const stats = questionsStats[selectedInterviewQuestionId]
            if (!stats || Array.isArray(stats)) return projectTotalResponses

            return stats.interview_count ?? projectTotalResponses
        }

        return projectTotalResponses
    }, [analysesStats, projectTotalResponses, questionsStats, selectedAnalysis, selectedInterviewQuestionId])
    // #endregion

    return {
        projectTotalResponses,
        analysisTotalResponses
    }
}