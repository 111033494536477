import { IProject } from "@/@types/project"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MdOutlineChat, MdOutlineFilterAlt, MdOutlineLayers } from "react-icons/md"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTabAnalysisCol } from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import AnalysisTabAnalysisColActionsBoxItem from "../AnalysisTabAnalysisColActionsBoxItem"
import AnalysisTabAnalysisColBreakdownModal from "../AnalysisTabAnalysisColBreakdownModal"
import AnalysisTabAnalysisColCodeOccurrencesModeToggle from "../AnalysisTabAnalysisColCodeOccurrencesModeToggle"
import AnalysisTabAnalysisColFilteringModal from "../AnalysisTabAnalysisColFilteringModal"
import AnalysisTabAnalysisColReviewCodeBookModal from "../AnalysisTabAnalysisColReviewCodeBookModal"
import FaTagsOutline from "@components/Icons/FaTagsOutline"
import { useAnalysisTabTotalResponses } from "../../hooks/useAnalysisTabTotalResponses"

export default function AnalysisTabAnalysisColActionsBox() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { analysisFilters, questionFilters, selectedAnalysis, selectedBreakdown } = useAnalysisTab()
    const { categoryBeingEdited } = useAnalysisTabAnalysisCol()
    // #endregion

    // #region Analysis tab
    const { analysisTotalResponses: totalResponses } = useAnalysisTabTotalResponses()
    // #endregion

    // #region States
    const [currentOpenModal, setCurrentOpenModal] = useState<"review-codebook" | "filtering" | "breakdown">()
    // #endregion

    // #region Memos
    const shouldShowReviewCodebookOption = useMemo(
        () => ["thematic", "entity"].includes(selectedAnalysis?.type ?? ""),
        [selectedAnalysis?.type]
    )
    const shouldShowFilteringModal = useMemo(
        () => project?.source === "interview",
        [project]
    )
    const shouldShowBreakdownModal = useMemo(
        () => project?.source === "interview",
        [project]
    )
    const isReviewCodebookModalButtonDisabled = useMemo(
        () => project?.source === "interview" && !["ended"].includes(project?.status ?? ""),
        [project]
    )
    // #endregion

    // #region Callbacks
    const handleCloseModal = useCallback(() => {
        setCurrentOpenModal(undefined)
    }, [])
    // #endregion

    // #region Effects

    // onEditingCategoryOpenCodebookModal
    useEffect(() => {
        if (categoryBeingEdited === undefined || categoryBeingEdited.type !== "theme") return
        setCurrentOpenModal("review-codebook")
    }, [categoryBeingEdited])

    // #endregion

    return (
        <div className={`flex flex-row gap-[0.5rem] justify-between rounded-[0.5rem] border-1 items-center
            bg-glaut-off-white border-glaut-stroke-glaut
        `}>
            <div className="flex flex-row gap-[0.5rem] py-[1rem] items-center pl-[1rem]">
                <MdOutlineChat className="h-[1.125rem] w-[1.125rem] text-glaut-grey" />
                <p className="text-[11.11px] text-glaut-text-midnight min-w-0 overflow-hidden
                    hidden lg:block">
                    {`${totalResponses} ${getCopy(copy.coding.totalResponses)}`}
                </p>
                <p className="text-[11.11px] text-glaut-text-midnight min-w-0 overflow-hidden
                    block lg:hidden">
                    {totalResponses}
                </p>
            </div>
            <div className="flex flex-row gap-[0.625rem] h-full items-center overflow-x-auto pr-[1rem]">
                {shouldShowReviewCodebookOption && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("review-codebook") }}
                        tooltipContent={getCopy(copy.coding.reviewCodebook) ?? ""}
                        disabled={isReviewCodebookModalButtonDisabled}
                    >
                        <FaTagsOutline />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                {shouldShowBreakdownModal && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("breakdown") }}
                        isApplied={selectedBreakdown !== undefined}
                        tooltipContent={getCopy(copy.coding.breakdownBy) ?? ""}
                    >
                        <MdOutlineLayers />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                {shouldShowFilteringModal && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("filtering") }}
                        isApplied={Object.values({ ...analysisFilters, ...questionFilters }).length > 0}
                        tooltipContent={getCopy(copy.coding.filterBy) ?? ""}
                    >
                        <MdOutlineFilterAlt />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                <div className={`flex flex-col justify-center pl-[1rem] border-s-1 h-full rounded-none
                    border-s-glaut-stroke-glaut    
                `}>
                    <AnalysisTabAnalysisColCodeOccurrencesModeToggle />
                </div>
            </div>

            {shouldShowReviewCodebookOption && selectedAnalysis !== undefined && ( // selectedAnalysis needed internally
                <AnalysisTabAnalysisColReviewCodeBookModal
                    isOpen={currentOpenModal === "review-codebook"}
                    onClose={handleCloseModal}
                />
            )}
            {shouldShowBreakdownModal && (
                <AnalysisTabAnalysisColBreakdownModal
                    isOpen={currentOpenModal === "breakdown"}
                    onClose={handleCloseModal}
                />
            )}
            {shouldShowFilteringModal && (
                <AnalysisTabAnalysisColFilteringModal
                    isOpen={currentOpenModal === "filtering"}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    )
}