import { IQuestionDomain, IQuestionType } from "@/@types/interview-question"
import { questionEmojiFromType } from "@utils/analysis/labels"

const questionDomainPrefix: { [key in IQuestionDomain]: string } = {
    interview: "Q",
    screening: "S"
}

interface IAnalysisTabAnalysisColFilteringModalQuestionSelectLabelProps {
    index: number
    label: string
    type: IQuestionType
    domain: IQuestionDomain
}

export default function AnalysisTabAnalysisColFilteringModalQuestionSelectLabel({
    index,
    label,
    type,
    domain
}: Readonly<IAnalysisTabAnalysisColFilteringModalQuestionSelectLabelProps>) {
    return (
        <div className="flex flex-row items-center gap-[0.25em] cursor-pointer">
            <p className="text-[11.11px] font-medium text-glaut-grey">
                {`${questionDomainPrefix[domain]}${index + 1}`}
            </p>
            <p className="text-[11.11px] font-medium text-glaut-grey">
                {questionEmojiFromType(type)}
            </p>
            <p
                className={`text-[13.33px] font-medium text-ellipsis whitespace-nowrap overflow-hidden
                    text-glaut-text-midnight
                `}>
                {label}
            </p>
        </div>
    )
}