import { getCopy } from "@utils/Copy"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { interviewSettingsTabProjectEmbeddingInstructionsCopy as copy } from "./utils/copy"
import EmbedCodeBlock from "@components/layouts/EmbedCodeBlock"

const steps = [
    {
        id: "step1",
        title: copy.step1CopyTheCode,
        description: copy.clickTheCopyIconNextToTheEmbedCode
    },
    {
        id: "step2",
        title: copy.step2PasteTheCode,
        description: copy.pasteTheCodeWhereYouWantTheInterviewToAppearOnYourWebsite
    }
]

export default function InterviewSettingsTabProjectEmbeddingInstructions() {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col gap-[0.75em]">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(copy.embedGlautInterview)?.toUpperCase()}
            </p>
            <p className="text-[12.7px] text-[#565656]">
                {getCopy(copy.heresHowToEmbedAGlautInterviewIntoA3rdPartyWebsite)}:
            </p>
            {steps.map(step => (
                <p className="text-[12.7px] font-medium text-glaut-text-midnight" key={step.id}>
                    {getCopy(step.title)}:{" "}
                    <span className="text-[12.7px] font-normal text-[#565656]">
                        {getCopy(step.description)}
                    </span>
                </p>
            ))}
            <p className="text-[13.33px] font-medium text-[#565656]">
                {getCopy(copy.heresYourUniqueEmbedCodeForThisProject)}:
            </p>
            <div className="max-w-[700px] self-center">
                <EmbedCodeBlock projectId={project._id} />
            </div>
        </div>
    )
}