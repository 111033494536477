import { IFilter } from "./question"
import { IBaseSegment } from "./segmentation"

// #region Base
export type ILang = Record<string, string | null>

export const questionDomains = ["interview", "screening"] as const
export type IQuestionDomain = typeof questionDomains[number]

export const questionTypes = ["open", "select", "scale", "nps", "asset"] as const
export type IQuestionType = typeof questionTypes[number]

type IQuestionTypeMap = { [key in IQuestionType]: string }
export const questionTypesMap: IQuestionTypeMap = {
    open: "open",
    select: "select",
    scale: "scale",
    nps: "nps",
    asset: "asset"
}

export const NPS_MIN_VALUE = 0 as const
export const NPS_MAX_VALUE = 10 as const
export const npsInterval = [NPS_MIN_VALUE, NPS_MAX_VALUE] as const

export interface IBaseQuestion {
    id: string
    project_id: string

    index: number
    content: ILang
    domain: IQuestionDomain
    type: IQuestionType
    filters: IFilter[]
    header: string | null

    img: string | null
    embed_url: string | null
    deleted: boolean
    probing_instructions: string | null

    last_updated: string
}
// #endregion

// #region Interview questions
export interface IBaseInterviewQuestion extends IBaseQuestion {
    domain: "interview"
}

// #region Open question
export interface IOpenQuestion extends IBaseInterviewQuestion {
    type: "open"
}
// #endregion

// #region Select question
export interface ISelectOption extends IBaseSegment {
    label: ILang
    id: string
}

export interface ISelectQuestion extends IBaseInterviewQuestion {
    type: "select"
    options: ISelectOption[]
    max_choices: number
}
// #endregion

// #region Scale/NPS question
interface IBaseScaleQuestion extends IBaseInterviewQuestion {
    min: number
    max: number
}

export interface IScaleQuestion extends IBaseScaleQuestion {
    type: "scale"
}

export interface INpsQuestion extends IBaseScaleQuestion {
    type: "nps"
    min: typeof NPS_MIN_VALUE
    max: typeof NPS_MAX_VALUE
}

// #endregion

// #region Asset question
export interface IAssetQuestion extends IBaseInterviewQuestion {
    type: "asset"
}
// #endregion

// #endregion

// #region Screening question
const screenerTypes = ["qualify", "disqualify"] as const
export type IScreenerType = typeof screenerTypes[number]

const screeningTypes = ["screener", "quota"] as const
export type IScreeningType = typeof screeningTypes[number]

export interface IBaseScreeningQuestion extends IBaseQuestion {
    domain: "screening"
}

export interface ISelectScreeningOption extends ISelectOption {
    action: IScreenerType | null
    /**
     * Expressed from 0 to 100.
     */
    quota: number | null
}

export interface ISelectScreeningQuestion extends IBaseScreeningQuestion {
    type: "select"
    max_choices: 1
    screening_type: IScreeningType
    options: ISelectScreeningOption[]
}

export type IScreeningQuestion = ISelectScreeningQuestion
// #endregion

export type IInterviewQuestion = IOpenQuestion | ISelectQuestion | IScaleQuestion | INpsQuestion | IAssetQuestion

export type IQuestion = IInterviewQuestion | IScreeningQuestion