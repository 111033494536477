import { getCopy } from "@utils/Copy"
import { DetailedHTMLProps, HTMLAttributes } from "react"
import { MdChevronLeft } from "react-icons/md"
import { showMoreButtonCopy } from "./utils/copy"

interface IShowMoreButtonProps extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    asShowLess?: boolean
}

export default function ShowMoreButton({
    asShowLess = false,
    className,
    ...props
}: Readonly<IShowMoreButtonProps>) {
    return (
        <button
            className={`text-base w-full rounded-[0.25rem] p-[0.5rem] shadow-none gap-[0.25rem] group
                [&_*]:transition-all [&_*]:delay-0
                bg-glaut-cards border-1 border-glaut-very-light-grey
                ${className}
            `}
            {...props}
        >
            <span className="text-[11.11px] font-medium text-glaut-dark-grey group-hover:text-glaut-pink-dark">
                {getCopy(asShowLess ? showMoreButtonCopy.showLess : showMoreButtonCopy.showMore)}
            </span>
            <MdChevronLeft
                className={`text-glaut-dark-grey
                    group-hover:text-glaut-pink-dark
                    ${asShowLess ? "rotate-90" : "-rotate-90"}
                `}
                style={{ transition: "0s" }}
            />
        </button>
    )
}