import { isMobile } from "../device"

export const interview = {
    interview: {
        welcome: {
            en: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            ar: "مرحباً بك، وشكراً لانضمامك إلى هذه المقابلة!\n\nيمكنك التحدث بشكل طبيعي عبر الميكروفون كما لو كنت تتحدث مع شخص حقيقي أو التحول إلى الكتابة متى شئت. لاستخدام الميكروفون، يرجى التأكد من منح متصفحك حق الوصول إليه عند الطلب.\n\n",
            zh: "欢迎，感谢您参加本次访谈！\n\n您可以通过麦克风自然地说话，就像与真人交谈一样，或者随时切换到打字模式。要使用麦克风，请确保在收到提示时授予浏览器访问权限。\n\n",
            hr: "Dobrodošli i hvala vam što ste se pridružili ovom intervjuu!\n\nMožete prirodno govoriti preko mikrofona kao da razgovarate sa stvarnom osobom ili se prebaciti na tipkanje kad god želite. Za korištenje mikrofona, molimo vas da dopustite pristup vašem pregledniku kada to bude zatraženo.\n\n",
            cs: "Vítejte a děkujeme, že jste se připojili k tomuto rozhovoru!\n\nMůžete přirozeně mluvit přes mikrofon, jako byste mluvili se skutečnou osobou, nebo kdykoliv přejít na psaní. Pro použití mikrofonu prosím nezapomeňte povolit prohlížeči přístup, když budete vyzváni.\n\n",
            nl: "Welkom en bedankt voor je deelname aan dit interview!\n\nJe kunt natuurlijk spreken via je microfoon alsof je met een echt persoon praat of overschakelen naar typen wanneer je maar wilt. Om de microfoon te gebruiken, zorg ervoor dat je je browser toegang geeft wanneer daarom wordt gevraagd.\n\n",
            en_GB: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            en_AU: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            en_ZA: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            en_NG: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            en_KE: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            fi: "Tervetuloa ja kiitos osallistumisestasi tähän haastatteluun!\n\nVoit puhua luonnollisesti mikrofoniin kuin puhuisit oikean henkilön kanssa tai vaihtaa kirjoittamiseen milloin tahansa haluat. Käyttääksesi mikrofonia, muista antaa selaimellesi käyttöoikeus siihen pyydettäessä.\n\n",
            fr: "Bienvenue et merci de participer à cette interview !\n\nVous pouvez parler librement dans votre microphone comme si vous parliez à une vraie personne ou passer à la saisie au clavier quand vous le préférez. Pour utiliser le microphone, assurez-vous d'accorder l'accès à votre navigateur lorsque cela vous est demandé.\n\n",
            de: "Willkommen und vielen Dank, dass Sie an diesem Interview teilnehmen!\n\nSie können frei in Ihr Mikrofon sprechen, als würden Sie mit einer echten Person sprechen, oder jederzeit zur Eingabe wechseln. Um das Mikrofon zu verwenden, stellen Sie bitte sicher, dass Sie Ihrem Browser den Zugriff darauf gewähren, wenn Sie dazu aufgefordert werden.\n\n",
            de_CH: "Willkommen und vielen Dank, dass Sie an diesem Interview teilnehmen!\n\nSie können frei in Ihr Mikrofon sprechen, als würden Sie mit einer echten Person sprechen, oder jederzeit zur Eingabe wechseln. Um das Mikrofon zu verwenden, stellen Sie bitte sicher, dass Sie Ihrem Browser den Zugriff darauf gewähren, wenn Sie dazu aufgefordert werden.\n\n",
            el: "Καλώς ήρθατε και σας ευχαριστούμε που συμμετέχετε σε αυτή τη συνέντευξη!\n\nΜπορείτε να μιλήσετε φυσικά μέσω του μικροφώνου σας σαν να μιλάτε με πραγματικό άτομο ή να μεταβείτε στην πληκτρολόγηση όποτε προτιμάτε. Για να χρησιμοποιήσετε το μικρόφωνο, βεβαιωθείτε ότι έχετε παραχωρήσει πρόσβαση στο πρόγραμμα περιήγησής σας όταν σας ζητηθεί.\n\n",
            hi_IN: "स्वागत है, और इस साक्षात्कार में शामिल होने के लिए धन्यवाद!\n\nआप अपने माइक्रोफ़ोन के माध्यम से स्वाभाविक रूप से बात कर सकते हैं जैसे किसी वास्तविक व्यक्ति से बात कर रहे हों या जब चाहें टाइपिंग में स्विच कर सकते हैं। माइक्रोफ़ोन का उपयोग करने के लिए, कृपया सुनिश्चित करें कि जब अनुरोध किया जाए तो अपने ब्राउज़र को एक्सेस प्रदान करें।\n\n",
            it: "Benvenuto e grazie per aver partecipato a questa intervista!\n\nPuoi parlare liberamente nel microfono come se stessi parlando con una persona reale o passare a digitare quando preferisci. Per utilizzare il microfono, assicurati di concedere l'accesso al tuo browser quando richiesto.\n\n",
            id: "Selamat datang, dan terima kasih telah bergabung dalam wawancara ini!\n\nAnda dapat berbicara secara alami melalui mikrofon seperti berbicara dengan orang sungguhan atau beralih ke pengetikan kapan pun Anda mau. Untuk menggunakan mikrofon, pastikan untuk memberikan akses ke browser Anda saat diminta.\n\n",
            ja: "ようこそ、このインタビューにご参加いただきありがとうございます！\n\n実際の人と話すように自然にマイクを通して話すことができ、必要に応じて入力に切り替えることもできます。マイクを使用するには、ブラウザからアクセス許可を求められた際に許可してください。\n\n",
            no: "Velkommen, og takk for at du deltar i dette intervjuet!\n\nDu kan snakke naturlig gjennom mikrofonen som om du snakker med en ekte person, eller bytte til skriving når du ønsker. For å bruke mikrofonen, sørg for å gi nettleseren din tilgang når du blir bedt om det.\n\n",
            pl: "Witamy i dziękujemy za dołączenie do tego wywiadu!\n\nMożesz mówić naturalnie przez mikrofon, jak podczas rozmowy z prawdziwą osobą, lub w dowolnym momencie przejść do pisania. Aby korzystać z mikrofonu, pamiętaj o udzieleniu przeglądarce dostępu, gdy zostaniesz o to poproszony.\n\n",
            pt: "Bem-vindo e obrigado por participar nesta entrevista!\n\nPode falar naturalmente através do seu microfone como se estivesse a falar com uma pessoa real ou mudar para digitação quando preferir. Para usar o microfone, certifique-se de conceder acesso ao seu navegador quando solicitado.\n\n",
            pt_BR: "Bem-vindo e obrigado por participar desta entrevista!\n\nVocê pode falar naturalmente através do seu microfone como se estivesse conversando com uma pessoa real ou mudar para digitação quando preferir. Para usar o microfone, certifique-se de conceder acesso ao seu navegador quando solicitado.\n\n",
            ru: "Добро пожаловать и спасибо за участие в этом интервью!\n\nВы можете естественно говорить через микрофон, как будто разговариваете с реальным человеком, или переключиться на набор текста в любой момент. Чтобы использовать микрофон, пожалуйста, не забудьте предоставить доступ вашему браузеру, когда это будет запрошено.\n\n",
            es: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            es_AR: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            es_MX: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            es_CL: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            es_CO: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            sv: "Välkommen och tack för att du deltar i denna intervju!\n\nDu kan tala naturligt genom din mikrofon som om du pratade med en verklig person eller byta till att skriva när du föredrar det. För att använda mikrofonen, se till att ge din webbläsare tillgång när du blir ombedd.\n\n",
            tr: "Hoş geldiniz ve bu görüşmeye katıldığınız için teşekkür ederiz!\n\nGerçek bir kişiyle konuşuyormuş gibi mikrofonunuz aracılığıyla doğal bir şekilde konuşabilir veya istediğiniz zaman yazmaya geçebilirsiniz. Mikrofonu kullanmak için, istendiğinde tarayıcınıza erişim izni vermeyi unutmayın.\n\n",
            uk: "Ласкаво просимо та дякуємо за участь у цьому інтерв'ю!\n\nВи можете природно говорити через мікрофон, як при розмові з реальною людиною, або перейти до набору тексту, коли забажаєте. Щоб використовувати мікрофон, не забудьте надати доступ вашому браузеру, коли це буде запропоновано.\n\n"
        },
        conversationEnded: {
            en: "Conversation ended",
            ar: "انتهت المحادثة",
            zh: "对话结束",
            hr: "Razgovor završen",
            cs: "Konverzace ukončena",
            nl: "Gesprek beëindigd",
            en_GB: "Conversation ended",
            en_AU: "Conversation ended",
            en_ZA: "Conversation ended",
            en_NG: "Conversation ended",
            en_KE: "Conversation ended",
            fi: "Keskustelu päättyi",
            fr: "Fin de la conversation",
            de: "Gespräch beendet",
            de_CH: "Gespräch beendet",
            el: "Η συνομιλία τελείωσε",
            hi_IN: "वार्तालाप समाप्त",
            it: "Conversazione terminata",
            id: "Percakapan berakhir",
            ja: "会話が終了しました",
            no: "Samtalen er avsluttet",
            pl: "Rozmowa zakończona",
            pt: "Conversa terminada",
            pt_BR: "Conversa encerrada",
            ru: "Разговор окончен",
            es: "Conversación terminada",
            es_AR: "Conversación terminada",
            es_MX: "Conversación terminada",
            es_CL: "Conversación terminada",
            es_CO: "Conversación terminada",
            sv: "Konversationen avslutad",
            tr: "Görüşme sona erdi",
            uk: "Розмова закінчена"
        },
        typeHere: {
            en: "Write your answer here",
            ar: "اكتب إجابتك هنا",
            zh: "在此输入您的答案",
            hr: "Ovdje napišite svoj odgovor",
            cs: "Zde napište svou odpověď",
            nl: "Schrijf hier uw antwoord",
            en_GB: "Write your answer here",
            en_AU: "Write your answer here",
            en_ZA: "Write your answer here",
            en_NG: "Write your answer here",
            en_KE: "Write your answer here",
            fi: "Kirjoita vastauksesi tähän",
            fr: "Écrivez votre réponse ici",
            de: "Schreiben Sie hier Ihre Antwort",
            de_CH: "Schreiben Sie hier Ihre Antwort",
            el: "Γράψτε την απάντησή σας εδώ",
            hi_IN: "अपना उत्तर यहाँ लिखें",
            it: "Scrivi qui la tua risposta",
            id: "Tulis jawaban Anda di sini",
            ja: "ここに回答を入力してください",
            no: "Skriv svaret ditt her",
            pl: "Napisz swoją odpowiedź tutaj",
            pt: "Escreva aqui a sua resposta",
            pt_BR: "Escreva sua resposta aqui",
            ru: "Напишите свой ответ здесь",
            es: "Escribe tu respuesta aquí",
            es_AR: "Escribe tu respuesta aquí",
            es_MX: "Escribe tu respuesta aquí",
            es_CL: "Escribe tu respuesta aquí",
            es_CO: "Escribe tu respuesta aquí",
            sv: "Skriv ditt svar här",
            tr: "Cevabınızı buraya yazın",
            uk: "Напишіть свою відповідь тут"
        },
        typeMailHere: {
            en: "Type your email",
            ar: "اكتب بريدك الإلكتروني",
            zh: "输入您的电子邮箱",
            hr: "Upišite svoju e-mail adresu",
            cs: "Zadejte svůj e-mail",
            nl: "Voer uw e-mailadres in",
            en_GB: "Type your email",
            en_AU: "Type your email",
            en_ZA: "Type your email",
            en_NG: "Type your email",
            en_KE: "Type your email",
            fi: "Kirjoita sähköpostiosoitteesi",
            fr: "Tapez votre email",
            de: "Geben Sie Ihre E-Mail ein",
            de_CH: "Geben Sie Ihre E-Mail ein",
            el: "Πληκτρολογήστε το email σας",
            hi_IN: "अपना ईमेल टाइप करें",
            it: "Digita la tua mail",
            id: "Ketik email Anda",
            ja: "メールアドレスを入力してください",
            no: "Skriv inn e-postadressen din",
            pl: "Wpisz swój adres e-mail",
            pt: "Digite o seu email",
            pt_BR: "Digite seu email",
            ru: "Введите ваш email",
            es: "Escriba su correo electrónico",
            es_AR: "Escriba su correo electrónico",
            es_MX: "Escriba su correo electrónico",
            es_CL: "Escriba su correo electrónico",
            es_CO: "Escriba su correo electrónico",
            sv: "Ange din e-postadress",
            tr: "E-posta adresinizi yazın",
            uk: "Введіть вашу електронну пошту"
        },
        provideEmail: {
            en: "Before starting, please provide your email:",
            ar: "قبل البدء، يرجى تقديم بريدك الإلكتروني:",
            zh: "开始之前，请提供您的电子邮箱：",
            hr: "Prije početka, molimo unesite vašu e-mail adresu:",
            cs: "Před začátkem prosím zadejte svůj e-mail:",
            nl: "Voordat u begint, geef uw e-mailadres op:",
            en_GB: "Before starting, please provide your email:",
            en_AU: "Before starting, please provide your email:",
            en_ZA: "Before starting, please provide your email:",
            en_NG: "Before starting, please provide your email:",
            en_KE: "Before starting, please provide your email:",
            fi: "Ennen aloittamista, anna sähköpostiosoitteesi:",
            fr: "Avant de commencer, veuillez indiquer votre adresse électronique:",
            de: "Bevor Sie beginnen, geben Sie bitte Ihre E-Mail-Adresse an:",
            de_CH: "Bevor Sie beginnen, geben Sie bitte Ihre E-Mail-Adresse an:",
            el: "Πριν ξεκινήσετε, παρακαλώ εισάγετε το email σας:",
            hi_IN: "शुरू करने से पहले, कृपया अपना ईमेल प्रदान करें:",
            it: "Prima di cominciare, inserisci la tua email:",
            id: "Sebelum memulai, harap berikan email Anda:",
            ja: "開始する前に、メールアドレスを入力してください：",
            no: "Før du begynner, vennligst oppgi din e-postadresse:",
            pl: "Przed rozpoczęciem prosimy o podanie adresu e-mail:",
            pt: "Antes de começar, forneça o seu email:",
            pt_BR: "Antes de começar, forneça seu email:",
            ru: "Прежде чем начать, укажите ваш email:",
            es: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            es_AR: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            es_MX: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            es_CL: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            es_CO: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            sv: "Innan du börjar, ange din e-postadress:",
            tr: "Başlamadan önce, lütfen e-posta adresinizi girin:",
            uk: "Перед початком, будь ласка, вкажіть вашу електронну пошту:"
        },
        optionalQuestion: {
            en: "This question is optional",
            ar: "هذا السؤال اختياري",
            zh: "这个问题是可选的",
            hr: "Ovo pitanje nije obavezno",
            cs: "Tato otázka je nepovinná",
            nl: "Deze vraag is optioneel",
            en_GB: "This question is optional",
            en_AU: "This question is optional",
            en_ZA: "This question is optional",
            en_NG: "This question is optional",
            en_KE: "This question is optional",
            fi: "Tämä kysymys on valinnainen",
            fr: "Cette question est facultative",
            de: "Diese Frage ist fakultativ",
            de_CH: "Diese Frage ist fakultativ",
            el: "Αυτή η ερώτηση είναι προαιρετική",
            hi_IN: "यह प्रश्न वैकल्पिक है",
            it: "Questa domanda è opzionale",
            id: "Pertanyaan ini bersifat opsional",
            ja: "この質問は任意です",
            no: "Dette spørsmålet er valgfritt",
            pl: "To pytanie jest opcjonalne",
            pt: "Esta pergunta é opcional",
            pt_BR: "Esta pergunta é opcional",
            ru: "Этот вопрос необязательный",
            es: "Esta pregunta es opcional",
            es_AR: "Esta pregunta es opcional",
            es_MX: "Esta pregunta es opcional",
            es_CL: "Esta pregunta es opcional",
            es_CO: "Esta pregunta es opcional",
            sv: "Denna fråga är valfri",
            tr: "Bu soru isteğe bağlıdır",
            uk: "Це питання необов'язкове"
        },
        rateGlaut: {
            en: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            ar: "كيف تقيم تجربتك في هذا الاستبيان مقارنة بالاستبيانات التقليدية التي شاركت فيها من قبل؟",
            zh: "与您之前参与的传统调查相比，您如何评价这次调查的体验？",
            hr: "Kako biste ocijenili svoje iskustvo s ovom anketom u usporedbi s tradicionalnim anketama koje ste prije ispunjavali?",
            cs: "Jak byste hodnotili svou zkušenost s tímto průzkumem ve srovnání s tradičními průzkumy, kterých jste se zúčastnili dříve?",
            nl: "Hoe zou u uw ervaring met deze enquête beoordelen in vergelijking met traditionele enquêtes die u eerder heeft ingevuld?",
            en_GB: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            en_AU: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            en_ZA: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            en_NG: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            en_KE: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            fi: "Miten arvioisit kokemustasi tästä kyselystä verrattuna aiemmin täyttämiisi perinteisiin kyselyihin?",
            fr: "Comment évaluez-vous votre expérience de cette enquête par rapport aux enquêtes traditionnelles auxquelles vous avez participé auparavant ?",
            de: "Wie würden Sie Ihre Erfahrungen mit dieser Umfrage im Vergleich zu anderen Umfragen, an denen Sie bisher teilgenommen haben, bewerten?",
            de_CH: "Wie würden Sie Ihre Erfahrungen mit dieser Umfrage im Vergleich zu anderen Umfragen, an denen Sie bisher teilgenommen haben, bewerten?",
            el: "Πώς θα αξιολογούσατε την εμπειρία σας από αυτή την έρευνα σε σύγκριση με παραδοσιακές έρευνες που έχετε συμμετάσχει στο παρελθόν;",
            hi_IN: "इस सर्वेक्षण में अपने अनुभव की तुलना आपके द्वारा पहले किए गए पारंपरिक सर्वेक्षणों से कैसे करेंगे?",
            it: "Come valuteresti questo questionario rispetto ai questionari tradizionali che hai completato in passato?",
            id: "Bagaimana Anda mengevaluasi pengalaman Anda mengikuti survei ini dibandingkan dengan survei tradisional yang pernah Anda ikuti sebelumnya?",
            ja: "これまでに回答した従来の調査と比べて、この調査の体験をどのように評価しますか？",
            no: "Hvordan vil du vurdere din opplevelse med denne undersøkelsen sammenlignet med tradisjonelle undersøkelser du har tatt tidligere?",
            pl: "Jak oceniłbyś swoje doświadczenie z tym badaniem w porównaniu z tradycyjnymi badaniami, w których brałeś udział wcześniej?",
            pt: "Como avaliaria a sua experiência neste inquérito em comparação com inquéritos tradicionais que realizou anteriormente?",
            pt_BR: "Como você avaliaria sua experiência com esta pesquisa em comparação com pesquisas tradicionais que você já respondeu antes?",
            ru: "Как бы вы оценили свой опыт прохождения этого опроса по сравнению с традиционными опросами, в которых вы участвовали ранее?",
            es: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            es_AR: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            es_MX: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            es_CL: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            es_CO: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            sv: "Hur skulle du utvärdera din upplevelse av denna undersökning jämfört med traditionella undersökningar du har deltagit i tidigare?",
            tr: "Bu anketi doldurma deneyiminizi daha önce katıldığınız geleneksel anketlerle karşılaştırdığınızda nasıl değerlendirirsiniz?",
            uk: "Як би ви оцінили свій досвіт проходження цього опитування порівняно з традиційними опитуваннями, в яких ви брали участь раніше?"
        },
        ratingOptions: {
            en: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            ar: {
                better: "أحببته أكثر",
                equal: "كان نفس الشيء",
                worse: "أحببته أقل"
            },
            zh: {
                better: "我更喜欢这个",
                equal: "差不多一样",
                worse: "我不太喜欢"
            },
            hr: {
                better: "Svidjelo mi se više",
                equal: "Bilo je isto",
                worse: "Svidjelo mi se manje"
            },
            cs: {
                better: "Líbilo se mi to více",
                equal: "Bylo to stejné",
                worse: "Líbilo se mi to méně"
            },
            nl: {
                better: "Ik vond het beter",
                equal: "Het was hetzelfde",
                worse: "Ik vond het minder"
            },
            en_GB: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            en_AU: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            en_ZA: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            en_NG: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            en_KE: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            fi: {
                better: "Pidin tästä enemmän",
                equal: "Se oli samanlainen",
                worse: "Pidin tästä vähemmän"
            },
            fr: {
                better: "J'ai préféré",
                equal: "C'était pareil",
                worse: "J'ai moins aimé"
            },
            de: {
                better: "Es hat mir besser gefallen",
                equal: "Es war das Gleiche",
                worse: "Es hat mir weniger gefallen"
            },
            de_CH: {
                better: "Es hat mir besser gefallen",
                equal: "Es war das Gleiche",
                worse: "Es hat mir weniger gefallen"
            },
            el: {
                better: "Μου άρεσε περισσότερο",
                equal: "Ήταν το ίδιο",
                worse: "Μου άρεσε λιγότερο"
            },
            hi_IN: {
                better: "मुझे यह बेहतर लगा",
                equal: "यह समान था",
                worse: "मुझे यह कम पसंद आया"
            },
            it: {
                better: "Mi è piaciuto di più",
                equal: "Mi è stato indifferente",
                worse: "Mi è piaciuto di meno"
            },
            id: {
                better: "Saya lebih suka",
                equal: "Sama saja",
                worse: "Saya kurang suka"
            },
            ja: {
                better: "より良かった",
                equal: "同じだった",
                worse: "あまり良くなかった"
            },
            no: {
                better: "Jeg likte det bedre",
                equal: "Det var det samme",
                worse: "Jeg likte det mindre"
            },
            pl: {
                better: "Podobało mi się bardziej",
                equal: "Było tak samo",
                worse: "Podobało mi się mniej"
            },
            pt: {
                better: "Gostei mais",
                equal: "Foi igual",
                worse: "Gostei menos"
            },
            pt_BR: {
                better: "Gostei mais",
                equal: "Foi igual",
                worse: "Gostei menos"
            },
            ru: {
                better: "Мне понравилось больше",
                equal: "Было так же",
                worse: "Мне понравилось меньше"
            },
            es: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            es_AR: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            es_MX: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            es_CL: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            es_CO: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            sv: {
                better: "Jag tyckte bättre om det",
                equal: "Det var samma",
                worse: "Jag tyckte mindre om det"
            },
            tr: {
                better: "Daha çok beğendim",
                equal: "Aynıydı",
                worse: "Daha az beğendim"
            },
            uk: {
                better: "Мені сподобалось більше",
                equal: "Було так само",
                worse: "Мені сподобалось менше"
            }
        },
        recording: {
            en: "Recording...",
            ar: "جاري التسجيل...",
            zh: "录音中...",
            hr: "Snimanje...",
            cs: "Nahrávání...",
            nl: "Opname...",
            en_GB: "Recording...",
            en_AU: "Recording...",
            en_ZA: "Recording...",
            en_NG: "Recording...",
            en_KE: "Recording...",
            fi: "Nauhoitetaan...",
            fr: "Enregistrement en cours...",
            de: "Aufnahme läuft...",
            de_CH: "Aufnahme läuft...",
            el: "Εγγραφή...",
            hi_IN: "रिकॉर्डिंग...",
            it: "Registrazione in corso...",
            id: "Merekam...",
            ja: "録音中...",
            no: "Tar opp...",
            pl: "Nagrywanie...",
            pt: "A gravar...",
            pt_BR: "Gravando...",
            ru: "Запись...",
            es: "Grabación...",
            es_AR: "Grabación...",
            es_MX: "Grabación...",
            es_CL: "Grabación...",
            es_CO: "Grabación...",
            sv: "Spelar in...",
            tr: "Kaydediliyor...",
            uk: "Запис..."
        },
        recordingStopTooltip: {
            en: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            ar: isMobile() ? "(اضغط على إيقاف ◻️ عندما تنتهي)" : "(انقر على إيقاف ◻️ عندما تنتهي)",
            zh: isMobile() ? "(完成后请按停止 ◻️)" : "(完成后请点击停止 ◻️)",
            hr: isMobile() ? "(Pritisnite Stop ◻️ kada završite)" : "(Kliknite Stop ◻️ kada završite)",
            cs: isMobile() ? "(Stiskněte Stop ◻️ až budete hotovi)" : "(Klikněte na Stop ◻️ až budete hotovi)",
            nl: isMobile() ? "(Druk op Stop ◻️ wanneer u klaar bent)" : "(Klik op Stop ◻️ wanneer u klaar bent)",
            en_GB: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            en_AU: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            en_ZA: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            en_NG: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            en_KE: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            fi: isMobile() ? "(Paina Stop ◻️ kun olet valmis)" : "(Napsauta Stop ◻️ kun olet valmis)",
            fr: isMobile() ? "(Appuyez sur Stop ◻️ lorsque vous avez terminé)" : "(Cliquez sur Stop ◻️ lorsque vous avez terminé)",
            de: isMobile() ? "(Drücken Sie Stop ◻️, wenn Sie fertig sind)" : "(Klicken Sie auf Stop ◻️, wenn Sie fertig sind)",
            de_CH: isMobile() ? "(Drücken Sie Stop ◻️, wenn Sie fertig sind)" : "(Klicken Sie auf Stop ◻️, wenn Sie fertig sind)",
            el: isMobile() ? "(Πατήστε Stop ◻️ όταν τελειώσετε)" : "(Κάντε κλικ στο Stop ◻️ όταν τελειώσετε)",
            hi_IN: isMobile() ? "(जब आप समाप्त कर लें तो Stop ◻️ दबाएं)" : "(जब आप समाप्त कर लें तो Stop ◻️ पर क्लिक करें)",
            it: isMobile() ? "(Premi Stop ◻️ quando hai finito)" : "(Clicca su Stop ◻️ quando hai finito)",
            id: isMobile() ? "(Tekan Stop ◻️ jika sudah selesai)" : "(Klik Stop ◻️ jika sudah selesai)",
            ja: isMobile() ? "(終わったらStop ◻️を押してください)" : "(終わったらStop ◻️をクリックしてください)",
            no: isMobile() ? "(Trykk Stop ◻️ når du er ferdig)" : "(Klikk Stop ◻️ når du er ferdig)",
            pl: isMobile() ? "(Naciśnij Stop ◻️ gdy skończysz)" : "(Kliknij Stop ◻️ gdy skończysz)",
            pt: isMobile() ? "(Prima Stop ◻️ quando terminar)" : "(Clique em Stop ◻️ quando terminar)",
            pt_BR: isMobile() ? "(Pressione Stop ◻️ quando terminar)" : "(Clique em Stop ◻️ quando terminar)",
            ru: isMobile() ? "(Нажмите Stop ◻️ когда закончите)" : "(Нажмите Stop ◻️ когда закончите)",
            es: isMobile() ? "(Presiona Stop ◻️ cuando hayas terminado)" : "(Haz clic en Stop ◻️ cuando hayas terminado)",
            es_AR: isMobile() ? "(Presiona Stop ◻️ cuando hayas terminado)" : "(Haz clic en Stop ◻️ cuando hayas terminado)",
            es_MX: isMobile() ? "(Presiona Stop ◻️ cuando hayas terminado)" : "(Haz clic en Stop ◻️ cuando hayas terminado)",
            es_CL: isMobile() ? "(Presiona Stop ◻️ cuando hayas terminado)" : "(Haz clic en Stop ◻️ cuando hayas terminado)",
            es_CO: isMobile() ? "(Presiona Stop ◻️ cuando hayas terminado)" : "(Haz clic en Stop ◻️ cuando hayas terminado)",
            sv: isMobile() ? "(Tryck på Stop ◻️ när du är klar)" : "(Klicka på Stop ◻️ när du är klar)",
            tr: isMobile() ? "(Bitirdiğinizde Stop ◻️ tuşuna basın)" : "(Bitirdiğinizde Stop ◻️ düğmesine tıklayın)",
            uk: isMobile() ? "(Натисніть Stop ◻️ коли закінчите)" : "(Натисніть Stop ◻️ коли закінчите)"
        },
        recordingStartTooltip: {
            en: "Press to record your answer",
            ar: "اضغط لتسجيل إجابتك",
            zh: "点击开始录制您的回答",
            hr: "Pritisnite za snimanje vašeg odgovora",
            cs: "Stiskněte pro nahrání vaší odpovědi",
            nl: "Druk om uw antwoord op te nemen",
            en_GB: "Press to record your answer",
            en_AU: "Press to record your answer",
            en_ZA: "Press to record your answer",
            en_NG: "Press to record your answer",
            en_KE: "Press to record your answer",
            fi: "Paina nauhoittaaksesi vastauksesi",
            fr: "Appuyez pour enregistrer votre réponse",
            de: "Drücken Sie, um Ihre Antwort aufzuzeichnen",
            de_CH: "Drücken Sie, um Ihre Antwort aufzuzeichnen",
            el: "Πατήστε για να ηχογραφήσετε την απάντησή σας",
            hi_IN: "अपना उत्तर रिकॉर्ड करने के लिए दबाएं",
            it: "Premi per registrare la tua risposta",
            id: "Tekan untuk merekam jawaban Anda",
            ja: "回答を録音するには押してください",
            no: "Trykk for å ta opp svaret ditt",
            pl: "Naciśnij, aby nagrać swoją odpowiedź",
            pt: "Pressione para gravar a sua resposta",
            pt_BR: "Pressione para gravar sua resposta",
            ru: "Нажмите, чтобы записать ваш ответ",
            es: "Presione para grabar su respuesta",
            es_AR: "Presione para grabar su respuesta",
            es_MX: "Presione para grabar su respuesta",
            es_CL: "Presione para grabar su respuesta",
            es_CO: "Presione para grabar su respuesta",
            sv: "Tryck för att spela in ditt svar",
            tr: "Cevabınızı kaydetmek için basın",
            uk: "Натисніть, щоб записати вашу відповідь"
        },
        introInfoMessage: {
            en: "Your answers are securely stored and accessible only to the Researcher.",
            ar: "إجاباتك مخزنة بشكل آمن ولا يمكن الوصول إليها إلا من قبل الباحث.",
            zh: "您的回答被安全存储，只有研究人员可以访问。",
            hr: "Vaši odgovori su sigurno pohranjeni i dostupni samo istraživaču.",
            cs: "Vaše odpovědi jsou bezpečně uloženy a přístupné pouze výzkumníkovi.",
            nl: "Uw antwoorden worden veilig opgeslagen en zijn alleen toegankelijk voor de onderzoeker.",
            en_GB: "Your answers are securely stored and accessible only to the Researcher.",
            en_AU: "Your answers are securely stored and accessible only to the Researcher.",
            en_ZA: "Your answers are securely stored and accessible only to the Researcher.",
            en_NG: "Your answers are securely stored and accessible only to the Researcher.",
            en_KE: "Your answers are securely stored and accessible only to the Researcher.",
            fi: "Vastauksesi tallennetaan turvallisesti ja ne ovat vain tutkijan saatavilla.",
            fr: "Vos réponses sont sécurisées et partagées uniquement avec l'interviewer.",
            de: "Ihre Antworten sind sicher und werden nur mit dem Interviewer geteilt.",
            de_CH: "Ihre Antworten sind sicher und werden nur mit dem Interviewer geteilt.",
            el: "Οι απαντήσεις σας αποθηκεύονται με ασφάλεια και είναι προσβάσιμες μόνο στον ερευνητή.",
            hi_IN: "आपके उत्तर सुरक्षित रूप से संग्रहित किए जाते हैं और केवल शोधकर्ता को ही उपलब्ध हैं।",
            it: "Le tue risposte sono sicure e condivise solo con l'intervistatore.",
            id: "Jawaban Anda disimpan dengan aman dan hanya dapat diakses oleh Peneliti.",
            ja: "あなたの回答は安全に保存され、研究者のみがアクセスできます。",
            no: "Dine svar lagres sikkert og er kun tilgjengelige for forskeren.",
            pl: "Twoje odpowiedzi są bezpiecznie przechowywane i dostępne tylko dla badacza.",
            pt: "As suas respostas são armazenadas de forma segura e acessíveis apenas ao investigador.",
            pt_BR: "Suas respostas são armazenadas com segurança e acessíveis apenas ao pesquisador.",
            ru: "Ваши ответы надежно хранятся и доступны только исследователю.",
            es: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            es_AR: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            es_MX: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            es_CL: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            es_CO: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            sv: "Dina svar lagras säkert och är endast tillgängliga för forskaren.",
            tr: "Yanıtlarınız güvenle saklanır ve yalnızca araştırmacı tarafından erişilebilir.",
            uk: "Ваші відповіді надійно зберігаються і доступні тільки досліднику."
        },
        minutes: {
            en: "min",
            ar: "دقيقة",
            zh: "分钟",
            hr: "min",
            cs: "min",
            nl: "min",
            en_GB: "min",
            en_AU: "min",
            en_ZA: "min",
            en_NG: "min",
            en_KE: "min",
            fi: "min",
            fr: "min",
            de: "min",
            de_CH: "min",
            el: "λεπ",
            hi_IN: "मिनट",
            it: "min",
            id: "menit",
            ja: "分",
            no: "min",
            pl: "min",
            pt: "min",
            pt_BR: "min",
            ru: "мин",
            es: "min",
            es_AR: "min",
            es_MX: "min",
            es_CL: "min",
            es_CO: "min",
            sv: "min",
            tr: "dk",
            uk: "хв"
        },
        selectOption: {
            en: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            ar: n => !n || n == 1 ? "حدد خيارًا:" : `اختر حتى ${n} خيارات:`,
            zh: n => !n || n == 1 ? "选择一个选项：" : `最多选择 ${n} 个选项：`,
            hr: n => !n || n == 1 ? "Odaberite opciju:" : `Odaberite najviše ${n} opcija:`,
            cs: n => !n || n == 1 ? "Vyberte možnost:" : `Vyberte maximálně ${n} možností:`,
            nl: n => !n || n == 1 ? "Selecteer een optie:" : `Kies maximaal ${n} opties:`,
            en_GB: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            en_AU: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            en_ZA: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            en_NG: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            en_KE: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            fi: n => !n || n == 1 ? "Valitse vaihtoehto:" : `Valitse enintään ${n} vaihtoehtoa:`,
            fr: n => !n || n == 1 ? "Sélectionnez une option:" : `Sélectionnez jusqu'à ${n} options:`,
            de: n => !n || n == 1 ? "Wählen Sie eine Option:" : `Wählen Sie bis zu ${n} Optionen:`,
            de_CH: n => !n || n == 1 ? "Wählen Sie eine Option:" : `Wählen Sie bis zu ${n} Optionen:`,
            el: n => !n || n == 1 ? "Επιλέξτε μια επιλογή:" : `Επιλέξτε έως ${n} επιλογές:`,
            hi_IN: n => !n || n == 1 ? "एक विकल्प चुनें:" : `अधिकतम ${n} विकल्प चुनें:`,
            it: n => !n || n == 1 ? "Seleziona un'opzione:" : `Seleziona fino a ${n} opzioni:`,
            id: n => !n || n == 1 ? "Pilih satu opsi:" : `Pilih maksimal ${n} opsi:`,
            ja: n => !n || n == 1 ? "オプションを選択:" : `最大${n}個のオプションを選択:`,
            no: n => !n || n == 1 ? "Velg et alternativ:" : `Velg maksimalt ${n} alternativer:`,
            pl: n => !n || n == 1 ? "Wybierz opcję:" : `Wybierz maksymalnie ${n} opcji:`,
            pt: n => !n || n == 1 ? "Selecione uma opção:" : `Selecione no máximo ${n} opções:`,
            pt_BR: n => !n || n == 1 ? "Selecione uma opção:" : `Selecione no máximo ${n} opções:`,
            ru: n => !n || n == 1 ? "Выберите вариант:" : `Выберите не более ${n} вариантов:`,
            es: n => !n || n == 1 ? "Seleccione una opción:" : `Seleccione hasta ${n} opciones:`,
            es_AR: n => !n || n == 1 ? "Seleccione una opción:" : `Seleccione hasta ${n} opciones:`,
            es_MX: n => !n || n == 1 ? "Seleccione una opción:" : `Seleccione hasta ${n} opciones:`,
            es_CL: n => !n || n == 1 ? "Seleccione una opción:" : `Seleccione hasta ${n} opciones:`,
            es_CO: n => !n || n == 1 ? "Seleccione una opción:" : `Seleccione hasta ${n} opciones:`,
            sv: n => !n || n == 1 ? "Välj ett alternativ:" : `Välj högst ${n} alternativ:`,
            tr: n => !n || n == 1 ? "Bir seçenek belirleyin:" : `En fazla ${n} seçenek belirleyin:`,
            uk: n => !n || n == 1 ? "Виберіть варіант:" : `Виберіть не більше ${n} варіантів:`
        },
        disableModeratorVoice: {
            en: "Disable the moderator voice",
            ar: "تعطيل صوت المشرف",
            zh: "禁用主持人语音",
            hr: "Onemogući glas moderatora",
            cs: "Vypnout hlas moderátora",
            nl: "Schakel de moderatorstem uit",
            en_GB: "Disable the moderator voice",
            en_AU: "Disable the moderator voice",
            en_ZA: "Disable the moderator voice",
            en_NG: "Disable the moderator voice",
            en_KE: "Disable the moderator voice",
            fi: "Poista moderaattorin ääni käytöstä",
            fr: "Désactiver la voix du modérateur",
            de: "Moderatorstimme deaktivieren",
            de_CH: "Moderatorstimme deaktivieren",
            el: "Απενεργοποίηση φωνής συντονιστή",
            hi_IN: "मॉडरेटर की आवाज़ बंद करें",
            it: "Disabilita la voce del moderatore",
            id: "Nonaktifkan suara moderator",
            ja: "モデレーターの音声を無効にする",
            no: "Deaktiver moderatorstemmen",
            pl: "Wyłącz głos moderatora",
            pt: "Desativar a voz do moderador",
            pt_BR: "Desativar a voz do moderador",
            ru: "Отключить голос модератора",
            es: "Desactivar la voz del moderador",
            es_AR: "Desactivar la voz del moderador",
            es_MX: "Desactivar la voz del moderador",
            es_CL: "Desactivar la voz del moderador",
            es_CO: "Desactivar la voz del moderador",
            sv: "Inaktivera moderatorrösten",
            tr: "Moderatör sesini devre dışı bırak",
            uk: "Вимкнути голос модератора"
        },
        enableModeratorVoice: {
            en: "Enable the moderator voice",
            ar: "تمكين صوت المشرف",
            zh: "启用主持人语音",
            hr: "Omogući glas moderatora",
            cs: "Zapnout hlas moderátora",
            nl: "Schakel de moderatorstem in",
            en_GB: "Enable the moderator voice",
            en_AU: "Enable the moderator voice",
            en_ZA: "Enable the moderator voice",
            en_NG: "Enable the moderator voice",
            en_KE: "Enable the moderator voice",
            fi: "Ota moderaattorin ääni käyttöön",
            fr: "Activer la voix du modérateur",
            de: "Moderatorstimme aktivieren",
            de_CH: "Moderatorstimme aktivieren",
            el: "Ενεργοποίηση φωνής συντονιστή",
            hi_IN: "मॉडरेटर की आवाज़ चालू करें",
            it: "Abilita la voce del moderatore",
            id: "Aktifkan suara moderator",
            ja: "モデレーターの音声を有効にする",
            no: "Aktiver moderatorstemmen",
            pl: "Włącz głos moderatora",
            pt: "Ativar a voz do moderador",
            pt_BR: "Ativar a voz do moderador",
            ru: "Включить голос модератора",
            es: "Activar la voz del moderador",
            es_AR: "Activar la voz del moderador",
            es_MX: "Activar la voz del moderador",
            es_CL: "Activar la voz del moderador",
            es_CO: "Activar la voz del moderador",
            sv: "Aktivera moderatorrösten",
            tr: "Moderatör sesini etkinleştir",
            uk: "Увімкнути голос модератора"
        },
        activateKeyboardMode: {
            en: "Click here to type your answer",
            ar: "انقر هنا لكتابة إجابتك",
            zh: "点击此处输入您的答案",
            hr: "Kliknite ovdje za unos vašeg odgovora",
            cs: "Klikněte zde pro napsání vaší odpovědi",
            nl: "Klik hier om uw antwoord te typen",
            en_GB: "Click here to type your answer",
            en_AU: "Click here to type your answer",
            en_ZA: "Click here to type your answer",
            en_NG: "Click here to type your answer",
            en_KE: "Click here to type your answer",
            fi: "Napsauta tästä kirjoittaaksesi vastauksesi",
            fr: "Cliquez ici pour saisir votre réponse",
            de: "Klicken Sie hier, um Ihre Antwort einzugeben",
            de_CH: "Klicken Sie hier, um Ihre Antwort einzugeben",
            el: "Κάντε κλικ εδώ για να πληκτρολογήσετε την απάντησή σας",
            hi_IN: "अपना उत्तर टाइप करने के लिए यहां क्लिक करें",
            it: "Clicca qui per digitare la tua risposta",
            id: "Klik di sini untuk mengetik jawaban Anda",
            ja: "ここをクリックして回答を入力してください",
            no: "Klikk her for å skrive svaret ditt",
            pl: "Kliknij tutaj, aby wpisać swoją odpowiedź",
            pt: "Clique aqui para digitar a sua resposta",
            pt_BR: "Clique aqui para digitar sua resposta",
            ru: "Нажмите здесь, чтобы ввести свой ответ",
            es: "Haga clic aquí para escribir su respuesta",
            es_AR: "Haga clic aquí para escribir su respuesta",
            es_MX: "Haga clic aquí para escribir su respuesta",
            es_CL: "Haga clic aquí para escribir su respuesta",
            es_CO: "Haga clic aquí para escribir su respuesta",
            sv: "Klicka här för att skriva ditt svar",
            tr: "Cevabınızı yazmak için buraya tıklayın",
            uk: "Натисніть тут, щоб ввести вашу відповідь"
        },
        activateMicrophoneMode: {
            en: "Click here to use the microphone",
            ar: "انقر هنا لاستخدام الميكروفون",
            zh: "点击此处使用麦克风",
            hr: "Kliknite ovdje za korištenje mikrofona",
            cs: "Klikněte zde pro použití mikrofonu",
            nl: "Klik hier om de microfoon te gebruiken",
            en_GB: "Click here to use the microphone",
            en_AU: "Click here to use the microphone",
            en_ZA: "Click here to use the microphone",
            en_NG: "Click here to use the microphone",
            en_KE: "Click here to use the microphone",
            fi: "Napsauta tästä käyttääksesi mikrofonia",
            fr: "Cliquez ici pour utiliser le microphone",
            de: "Klicken Sie hier, um das Mikrofon zu verwenden",
            de_CH: "Klicken Sie hier, um das Mikrofon zu verwenden",
            el: "Κάντε κλικ εδώ για να χρησιμοποιήσετε το μικρόφωνο",
            hi_IN: "माइक्रोफ़ोन का उपयोग करने के लिए यहां क्लिक करें",
            it: "Clicca qui per utilizzare il microfono",
            id: "Klik di sini untuk menggunakan mikrofon",
            ja: "ここをクリックしてマイクを使用してください",
            no: "Klikk her for å bruke mikrofonen",
            pl: "Kliknij tutaj, aby użyć mikrofonu",
            pt: "Clique aqui para usar o microfone",
            pt_BR: "Clique aqui para usar o microfone",
            ru: "Нажмите здесь, чтобы использовать микрофон",
            es: "Haga clic aquí para usar el micrófono",
            es_AR: "Haga clic aquí para usar el micrófono",
            es_MX: "Haga clic aquí para usar el micrófono",
            es_CL: "Haga clic aquí para usar el micrófono",
            es_CO: "Haga clic aquí para usar el micrófono",
            sv: "Klicka här för att använda mikrofonen",
            tr: "Mikrofonu kullanmak için buraya tıklayın",
            uk: "Натисніть тут, щоб використовувати мікрофон"
        },
    }
} as const