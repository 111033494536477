import { ICategoryStats } from "@/@types/processing/statistics"
import { ISegment } from "@/@types/base/generics"
import { extractIdFromSegment } from "@utils/analysis/segmentation"
import { searchCategoryInStats } from "@utils/analysis/category-stats"
import { IQuote } from "@/@types/reports"

export function isQuoteFromStats(stats: ICategoryStats<ISegment>, quote: IQuote) {
    const segment = searchCategoryInStats(stats, quote.category_id)?.category
    return segment ? extractIdFromSegment(segment) === quote.category_id : false
}
