export const embedCodeBlockCopy = {
    copyCode: {
        en: "Copy code"
    },
    copied: {
        en: "Copied"
    },
    skipWelcome: {
        en: "Skip welcome page"
    }
} as const