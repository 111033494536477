import { MdMicNone, MdOutlineKeyboard } from "react-icons/md"

export const interviewLayoutOptions = [
    {
        label: "Voice first",
        slug: "true",
        icon: <MdMicNone />
    },
    {
        label: "Text first",
        slug: "false",
        icon: <MdOutlineKeyboard />
    }
] as const
