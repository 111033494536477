import AnalysisContent from "@components/Analysis/AnalysisContent"
import AnalysisMetrics from "@components/Analysis/AnalysisMetrics"
import { View } from "@react-pdf/renderer"
import { forwardRef, useMemo } from "react"
import { INpsInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import InsightBlockTemplate from "../ReportDetailsInsightBlockTemplate"
import { isNpsStats } from "@utils/analysis/scale-stats"
import { copy, getCopy } from "@utils/Copy"

interface IReportDetailsNpsInsightBlockProps {
    id?: string
    block: INpsInsight
}

const ReportDetailsNpsInsightBlock = forwardRef<
    HTMLDivElement,
    Readonly<IReportDetailsNpsInsightBlockProps>
>(({
    id,
    block
}, ref) => {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region Memos

    const scaleMetricsBlocks = useMemo(() => {
        if (!block.stats || Array.isArray(block.stats)) return []
        if (isNpsStats(block.stats))
            return [
                {
                    label: getCopy(copy.words.promoters) ?? "",
                    value: `${String(
                        block.stats.promoters.toFixed(2)
                    ).padStart(2, "0")}%`
                },
                {
                    label: getCopy(copy.words.detractors) ?? "",
                    value: `${String(
                        block.stats.detractors.toFixed(2)
                    ).padStart(2, "0")}%`
                },
                {
                    label: getCopy(copy.words.nps) ?? "",
                    value: `${String(
                        block.stats.nps.toFixed(2)
                    ).padStart(2, "0")}`
                }
            ]

        // if (isScaleStats(block.stats))
        //     return [
        //         {
        //             label: getCopy(copy.words.averageScore) ?? "",
        //             value: `${String(
        //                 block.stats.average_score.toFixed(2)
        //             ).padStart(2, "0")}`
        //         }
        //     ]

        return []
    }, [block.stats])
    // #endregion

    if (Array.isArray(block.stats)) // can't be shown when segmented
        return <></>

    if (renderAsPdf)
        return (
            <InsightBlockTemplate block={block}>
                {block.stats && (
                    <View style={tw("flex flex-col")}>
                        <AnalysisMetrics
                            blocks={scaleMetricsBlocks}
                            asPdf
                            twPdf={tw}
                        />
                        <AnalysisContent
                            values={block.stats.values_count}
                            asPdf
                            twPdf={tw}
                        />
                    </View>
                )}
            </InsightBlockTemplate>
        )

    return (
        <InsightBlockTemplate block={block} ref={ref} id={id}>
            {block.stats && (
                <div className="flex flex-col">
                    <AnalysisMetrics blocks={scaleMetricsBlocks} />
                    <AnalysisContent values={block.stats.values_count} />
                </div>
            )}
        </InsightBlockTemplate>
    )
})

ReportDetailsNpsInsightBlock.displayName = "ReportDetailsNpsInsightBlock"

export default ReportDetailsNpsInsightBlock