import ModalLayout from "@components/layouts/ModalLayout"
import { copy, getCopy } from "@utils/Copy"
import { analysisTabAddInsightToReportModalCopy as localCopy } from "./utils/copy"
import GlautSelect from "@components/inputs/GlautSelect"
import { SelectInstance } from "react-select"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AnalysisTabAddInsightToReportModalSelectLabel from "./components/AnalysisTabAddInsightToReportModalSelectLabel"
import { IReport } from "@/@types/reports"
import { useReportService } from "@hooks/services/useReportService"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import { useToastDispatch } from "@contexts/ToastProvider"
import ErrorLevel from "@utils/ErrorLevel"
import * as Sentry from "@sentry/react"

interface IAnalysisTabAddInsightToReportModalProps {
    isOpen: boolean
    onClose: () => void
}

export default function AnalysisTabAddInsightToReportModal({
    isOpen,
    onClose
}: Readonly<IAnalysisTabAddInsightToReportModalProps>) {
    // #region Contexts
    const { project } = useLegacyProject()
    const { selectedAnalysis } = useAnalysisTab()
    const toastDispatch = useToastDispatch()
    // #endregion

    // #region Services
    const reportsService = useReportService()
    // #endregion

    // #region States
    const [availableReports, setAvailableReports] = useState<IReport[]>([])
    const [selectedReportId, setSelectedReportId] = useState<string>()
    // #endregion

    // #region Refs
    const reportSelectRef = useRef<SelectInstance<{ label: React.ReactNode; value: string }>>()
    // #endregion

    // #region Memos
    const reportOptions = useMemo(() => availableReports.map(report => ({
        value: report.id,
        label: <AnalysisTabAddInsightToReportModalSelectLabel
            lastModified={report.last_edited_at}
            title={report.title}
        />
    })), [availableReports])

    const selectedReport = useMemo(
        () => reportOptions.find(report => report.value === selectedReportId),
        [reportOptions, selectedReportId]
    )

    const isSavingAllowed = useMemo(() => !!selectedReport, [selectedReport])
    // #endregion

    // #region Callbacks
    const handleSaveInsightToReport = useCallback(() => {
        if (!project?._id || !selectedReportId || !selectedAnalysis?.id) return

        reportsService.addInsightToReport({
            projectId: project._id,
            reportId: selectedReportId,
            analysisId: selectedAnalysis.id
        }).then(() => {
            toastDispatch({
                type: "add-toast",
                payload: {
                    messageLevel: 1,
                    title: `${getCopy(localCopy.insightSavedToReport) ?? ""}!`
                }
            })
            onClose()
        }).catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    projectId: project._id,
                    analysisId: selectedAnalysis?.id,
                    reportId: selectedReportId
                })

                return scope
            })

            toastDispatch({
                type: "add-toast",
                payload: {
                    messageLevel: ErrorLevel.Error,
                    title: `${getCopy(localCopy.itWasNotPossibleToSaveTheInsightToTheReport) ?? ""}.`
                }
            })
        })
    }, [project?._id, reportsService, selectedAnalysis?.id, selectedReportId, onClose, toastDispatch])
    // #endregion

    // #region Effects

    // onMountListReports
    useEffect(() => {
        if (!project?._id || !isOpen || availableReports.length > 0) return

        reportsService.getReportList({ projectId: project._id }).then(res => {
            setAvailableReports(res.reports)
        }).catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    projectId: project._id,
                    analysisId: selectedAnalysis?.id
                })

                return scope
            })

            toastDispatch({
                type: "add-toast",
                payload: {
                    messageLevel: ErrorLevel.Error,
                    title: `${getCopy(localCopy.itWasNotPossibleToLoadTheProjectReports) ?? ""}.`
                }
            })
        })
    }, [availableReports.length, isOpen, project?._id, reportsService, selectedAnalysis?.id, toastDispatch])

    // onCloseClearSelectValue
    useEffect(() => {
        if (!isOpen) return

        setSelectedReportId(undefined)
        reportSelectRef.current?.clearValue()
    }, [isOpen])

    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`flex flex-col items-center rounded-[0.25em] py-[0.625em] px-[1em] overflow-y-visible 
                w-[552px] max-h-screen no-scrollbar transition-all border-1
                shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)] bg-white border-glaut-stroke-glaut
                ${isOpen ? "opacity-100" : "opacity-0"}
            `}
            style={{ transform: isOpen ? "scale(1)" : "scale(1.25)" }}
        >
            <div className="flex flex-col gap-[1em] w-full">
                <p className="text-[13.33px] font-medium text-glaut-dark-grey self-start">
                    {getCopy(localCopy.addInsightToAReport)}
                </p>
                <GlautSelect
                    ref={elem => {
                        if (!elem) return
                        reportSelectRef.current = elem
                    }}
                    options={reportOptions}
                    value={selectedReport}
                    onSelect={val => {
                        setSelectedReportId(val ?? undefined)
                    }}
                    placeholder={getCopy(localCopy.selectAReport) ?? ""}
                />
                <div className="flex flex-row justify-between pt-[0.5em] w-full">
                    <GlautButtonSecondary onClick={onClose}>
                        {getCopy(copy.coding.cancel)}
                    </GlautButtonSecondary>
                    <GlautButtonPrimary onClick={handleSaveInsightToReport} disabled={!isSavingAllowed}>
                        {getCopy(copy.coding.save)}
                    </GlautButtonPrimary>
                </div>
            </div>
        </ModalLayout>
    )
}