import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import { MdAddCircle } from "react-icons/md"
import { copy, getCopy } from "@utils/Copy"
import { useAnalysisTabAnalysisColDispatch } from "../../contexts/AnalysisTabAnalysisColProvider"
import { useCallback } from "react"

export default function AnalysisTabAnalysisColHeader() {
    // #region Contexts
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Callbacks
    const handleClickAddToReport = useCallback(() => {
        analysisTabAnalysisColDispatch({ type: "open-add-insight-to-report-modal" })
    }, [analysisTabAnalysisColDispatch])
    // #endregion

    return (
        <div className="header flex flex-row justify-between mb-[0.75em]">
            <span className="leading-trim text-[13.33px] text-[rgb(0,0,0,0.6)] mt-[0.75em] ml-[0.75em]">
                {getCopy(copy.coding.results)}
            </span>
            <GlautButtonPrimary onClick={handleClickAddToReport} className="hidden">
                <div className="flex flex-row gap-[0.3125em] items-center">
                    <MdAddCircle
                        className="text-glaut-off-white w-[1.25em] h-[1.25em] group-disabled:text-glaut-grey"
                    />
                    <p className="text-glaut-off-white font-medium text-[13.33px] group-disabled:text-glaut-grey">
                        {getCopy(copy.coding.addToReport)}
                    </p>
                </div>
            </GlautButtonPrimary>
        </div>
    )
}