import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo } from "react"
import { MdPlaylistPlay } from "react-icons/md"
import { useReportDetails, useReportDetailsDispatch } from "../../contexts/ReportDetailsContext"
import { reportDetailsIndexCopy } from "./utils/copy"
import slugify from "slugify"

export default function ReportDetailsIndex() {
    // #region Contexts
    const { report, renderAsPdf } = useReportDetails()
    const reportDetailsDispatch = useReportDetailsDispatch()
    // #endregion

    // #region Callbacks
    const handleClickOnIndexOption = useCallback((blockId: string) => {
        reportDetailsDispatch({ type: "mark-block-to-scroll", blockId })
    }, [reportDetailsDispatch])
    // #endregion

    // #region Element memos
    const indexOptionsElement = useMemo(() => report.goals.map((i, idx) => (
        <button
            key={slugify(i.title)}
            type="button"
            className={`rounded-none shadow-none border-none flex justify-start items-start gap-[0.25em]
                cursor-pointer text-left py-[0.25em] px-[0.75em] w-full
                xl:max-w-[10rem] 2xl:max-w-[15rem]
                bg-glaut-off-white
                hover:bg-glaut-cards
            `}
            onClick={() => { handleClickOnIndexOption(slugify(i.title)) }}
        >
            <p className="text-[11.11px] font-medium text-glaut-grey min-w-3 text-center">
                {idx + 1}
            </p>
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {i.title}
            </p>
        </button>
    )), [report, handleClickOnIndexOption])
    // #endregion

    if (!report || renderAsPdf) return <></>

    return (
        <div className="fixed xl:flex flex-col gap-[0.5rem] border-l-[0.25rem] h-max py-[1rem] px-[0.75rem] shrink-0
            border-l-glaut-pink hidden">
            <button
                type="button"
                className="text-[11.11px] font-medium underline px-[0.5rem] py-[0.375rem] ml-[1.75rem]
                    m-0 p-0 border-none shadow-none bg-transparent
                    text-glaut-dark-grey
                    hover:bg-glaut-very-light-grey"
                onClick={() => {
                    reportDetailsDispatch({ type: "mark-block-to-scroll", blockId: "div--report-title" })
                }}
            >
                {getCopy(reportDetailsIndexCopy.backToTheTop)}
            </button>
            <button
                type="button"
                className="flex items-center gap-[0.5rem] px-[0.5rem] py-[0.25rem]
                    m-0 bg-transparent border-none shadow-none"
                onClick={() => { reportDetailsDispatch({ type: "mark-block-to-scroll", blockId: "project-overview" }) }}
            >
                <MdPlaylistPlay className="w-[1.125rem] h-[1.125rem] text-glaut-grey" />
                <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                    {getCopy(copy.words.projectOverview)}
                </p>
            </button>
            {indexOptionsElement}
            {report.appendix.length > 0 && (
                <button
                    type="button"
                    className="flex items-center gap-[0.5rem] px-[0.5rem] py-[0.25rem]
                        m-0 bg-transparent border-none shadow-none"
                    onClick={() => { reportDetailsDispatch({ type: "mark-block-to-scroll", blockId: "appendix" }) }}
                >
                    <MdPlaylistPlay className="w-[1.125rem] h-[1.125rem] text-glaut-grey" />
                    <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                        {getCopy(copy.words.appendix)}
                    </p>
                </button>
            )}
        </div>
    )
}