
// Contains every possible query parameter for the interview link
interface IFrameQueryParams {
    skipWelcome: boolean
}

/**
 * Builds the complete iframe code with the given project ID and query parameters
 * @param projectId The project identifier
 * @param queryParams Object containing query parameters
 */
export const buildIFrameCode = (
    projectId: string, 
    queryParams: Partial<IFrameQueryParams> = {}
) => {
    
    // Add parameters only if they are true
    const params = new URLSearchParams()
    if (queryParams.skipWelcome) params.append("skip_welcome", "true")
    const queryString = params.toString()
    const queryParamsString = queryString ? `?${queryString}` : ""
    
    // Return the iframe code with the formatted query params
    return `<section id="glaut" style="margin: 0; padding: 0; height: 100vh; display: flex;">
        <iframe 
            src="https://app.glaut.com/i/${projectId}${queryParamsString}" 
            width="100%" 
            height="100%" 
        style="flex-grow: 1; border: none; min-height: 800px;"
        frameborder="0"
            allow="microphone; fullscreen"
            allowfullscreen>
        </iframe>
    </section>`
}
