import { IAnalysis } from "@/@types/analysis"
import { IInterviewQuestion } from "@/@types/interview-question"
import { IProject } from "@/@types/project"
import { IReport } from "@/@types/reports"
import Loading from "@components/loading/Loading"
import { PDFViewer } from "@react-pdf/renderer"
import { IProjectMetrics } from "@services/projects/IProjectGateway"
import { useCallback, useRef } from "react"
import Content from "./components/ReportDetailsContent"
import Header from "./components/ReportDetailsHeader"
import Index from "./components/ReportDetailsIndex"
import ReportDetailsProvider from "./contexts/ReportDetailsContext"
import { useRegisterPdfFonts } from "./hooks/useRegisterPdfFonts"
import "./index.scss"

interface IReportDetailsProps {
    project: IProject
    interviewQuestions: IInterviewQuestion[]
    numberOfEntries: number
    report: IReport
    analyses: IAnalysis[]
    metrics: IProjectMetrics
    onBack?: () => void
    renderAsPdf?: boolean
    isReadOnly?: boolean
}

export default function ReportDetails({
    project,
    interviewQuestions,
    numberOfEntries,
    report,
    analyses,
    metrics,
    onBack,
    renderAsPdf = false,
    isReadOnly = false
}: Readonly<IReportDetailsProps>) {
    // #region Refs
    const reportContentRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region PDF
    useRegisterPdfFonts()
    // #endregion

    // #region Element callbacks
    const renderReportContentElement = useCallback((asPdf: boolean, metrics: IProjectMetrics) => (
        <ReportDetailsProvider
            report={report}
            project={project}
            analyses={analyses}
            interviewQuestions={interviewQuestions}
            numberOfEntries={numberOfEntries}
            onBack={onBack}
            renderAsPdf={asPdf}
            reportMetrics={metrics}
            isReadOnly={isReadOnly}
        >
            <Index />
            <Content ref={reportContentRef} />
        </ReportDetailsProvider>
    ), [isReadOnly, report, project, analyses, interviewQuestions, numberOfEntries, onBack])
    // #endregion

    if (!metrics) return <Loading />

    return (
        <div className="h-lvh sm:h-full flex flex-col bg-glaut-off-white overflow-hidden">
            <Header
                report={report}
                onBack={onBack}
                renderReportPdf={() => renderReportContentElement(true, metrics)}
            />

            <div className="overflow-auto h-full sm:pt-20" id="div--report-container">
                {!renderAsPdf && (
                    renderReportContentElement(renderAsPdf, metrics)
                )}
                {renderAsPdf && (
                    <PDFViewer style={{ width: "100%", height: "100%" }}>
                        {renderReportContentElement(renderAsPdf, metrics)}
                    </PDFViewer>
                )}
            </div>
        </div>
    )
}