import { useCallback } from "react"
import { useBrowserUserAgent } from "./useBrowserUserAgent"

interface IUseScrollToElementCrossPlatformProps {
    headerElementId: string
}

export function useScrollToElementCrossPlatform({ headerElementId }: Readonly<IUseScrollToElementCrossPlatformProps>) {
    // #region Browser hooks
    const { os: { isIos } } = useBrowserUserAgent()
    // #endregion

    // #region Callbacks
    const scrollToElement = useCallback((element: HTMLElement) => {
        if (!isIos) {
            element.scrollIntoView({ behavior: "smooth" })
            return
        }

        // In Safari iOS, the `.scrollIntoView()` function hides header components. This workaround does
        // the trick
        const headerElement = document.getElementById(headerElementId)
        if (!headerElement) {
            console.warn(`Header element with id "${headerElementId}" not found`)
            return
        }

        const headerHeight = headerElement.offsetHeight

        const { x, y } = element.getBoundingClientRect()
        headerElement.scrollBy(x, y - headerHeight)
    }, [isIos, headerElementId])
    // #endregion

    return { scrollToElement }
}