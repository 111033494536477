import { IContainsFilter, IFilter, IWithinFilter } from "@/@types/question"
import { packDuplicatedContainsFilters } from "./pack-duplicated-contains-filters"


export function formatFiltersForApi(filters: { [itemId: string]: { optionId: string }[] }): IFilter[] {
    // Treat all filters as contains
    const preFormattedFilters = Object.entries(filters ?? {}).map(
        ([itemId, codes]) => codes.map<IContainsFilter>(
            code => ({ id: itemId, operator: "contains", value: [code.optionId] })
        )
    ).flat()

    // Pack contains filters
    const packedFilters = preFormattedFilters.reduce(packDuplicatedContainsFilters, [] as IContainsFilter[])

    // Parse filters to turn them into within (when value is numeric), or contains
    const parsedFilters = packedFilters.map(filter => {
        if (filter.value.every(v => !isNaN(Number(v))))
            return { id: filter.id, operator: "in", value: filter.value.map(v => Number(v)) } as IWithinFilter

        return filter
    })

    return parsedFilters
}
