import { IInterviewQuestion, ISelectQuestion } from "@/@types/interview-question"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { Drag, Drop } from "@components/menus/DragAndDrop"
import { useEditQuestionOptions } from "@hooks/useEditQuestion"
import { copy, getCopy } from "@utils/Copy"
import { normalizeQuestionOrOption } from "@utils/strings"
import { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect, useMemo, useRef } from "react"
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd"
import { FaTag, FaTags } from "react-icons/fa"
import { MdAddCircle, MdClose } from "react-icons/md"
import { v4 as uuid } from "uuid"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { normalizeToColorIndex, textColorWithCssColorVarFromIndex } from "@utils/styling/colors-from-index"

export default function OutlineTabContentIqsQuestionFormBodySelectOptions() {
    // #region Contexts
    const { lang } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt, isEditing } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Refs
    const optionsRef = useRef<(HTMLInputElement | null)[]>([])
    const optionsColorIndexesRef = useRef<{ [optionId: string]: number }>({})
    // #endregion

    // #region Edit question hooks
    const setQuestion = useCallback((callback: (iq: Partial<IInterviewQuestion>) => Partial<IInterviewQuestion>) => {
        if (!iqToBeBuilt) return
        const newIqToBeBuilt = callback(iqToBeBuilt)
        outlineTabIqsDispatch({ type: "set-iq-to-be-built", iq: newIqToBeBuilt, isEditing })
    }, [iqToBeBuilt, isEditing, outlineTabIqsDispatch])

    const { addOption, editOption, deleteOption } = useEditQuestionOptions(setQuestion, lang)
    // #endregion

    // #region Memos
    const disabled = useMemo(() => !canEdit, [canEdit])
    const question = useMemo(() => {
        if (iqToBeBuilt?.type !== "select") return
        return iqToBeBuilt as Partial<ISelectQuestion>
    }, [iqToBeBuilt])
    // #endregion

    // #region Callbacks
    const handleOptionDragEnd: OnDragEndResponder = result => {
        if (!question?.options?.length) return

        // Destructure properties from the result
        const { destination, source } = result

        // If there's no destination (e.g. drag is canceled or item is dragged outside droppable area)
        if (!destination)
            return

        // If the source and the destination is the same, no need to do anything
        if (destination.index === source.index)
            return

        // Create a new schema array by reordering the questions
        const newOptions = Array.from(question.options)
        const [movedOption] = newOptions.splice(source.index, 1)
        newOptions.splice(destination.index, 0, movedOption)

        setQuestion(p => ({ ...p, options: newOptions }))
    }

    const handleChangeMaxChoices: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        setQuestion(p => ({ ...p, max_choices: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 1 }))
    }, [setQuestion])

    function handleKeyPress(index: number): KeyboardEventHandler<HTMLInputElement> {
        return event => {
            if (!question?.options?.length) return

            if (event.key === "Enter") {
                event.preventDefault()

                // If it's the last input, add a new one
                if (index === question.options.length - 1) addOption()
                // Otherwise, focus the next input
                else optionsRef.current[index + 1]?.focus()
            }
        }
    }

    const getOptionColorIndex = useCallback((optionId: string, prevOptionId?: string) => {
        if (!optionsColorIndexesRef.current[optionId]) {
            let colorIndex = normalizeToColorIndex(Math.round(Math.random() * 10))
            if (prevOptionId)
                while (colorIndex === optionsColorIndexesRef.current[prevOptionId])
                    colorIndex = normalizeToColorIndex(Math.round(Math.random() * 10))

            optionsColorIndexesRef.current[optionId] = colorIndex
        }

        return optionsColorIndexesRef.current[optionId]
    }, [optionsColorIndexesRef])
    // #endregion

    // #region Effects

    useEffect(() => {
        // Focus on the last input field (newly added) when the inputs array changes
        const lastInputIndex = (question?.options?.length ?? 0) - 1
        if (lastInputIndex > 0)
            optionsRef.current[lastInputIndex]?.focus()
    }, [question?.options?.length])

    useEffect(() => {
        if (!question) return

        // If there are no options, add at least one
        if (!question.options?.length) addOption()

        // Update the refs array to the current length of inputs
        optionsRef.current = optionsRef.current.slice(0, question.options?.length ?? 0)
    }, [addOption, question])

    // #endregion

    if (!question) return <></>

    return (
        <div className="flex flex-col gap-[0.5rem]">
            <div className="flex justify-between items-center">
                <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                    {getCopy(copy.outline.interviewQuestions.howManyOptionsCanTheUserSelectQuestion)}
                </p>
                <input
                    className="border-1 w-20 focus:shadow-none text-center rounded-[0.25em] 
                        border-glaut-bar bg-glaut-off-white enabled:hover:border-glaut-pink"
                    type="number"
                    name="tentacles"
                    min={1}
                    max={999999}
                    value={question.max_choices ?? 1}
                    step={1}
                    onChange={handleChangeMaxChoices}
                    disabled={!canEdit}
                />
            </div>
            <div style={{ width: "100%", position: "relative", paddingBottom: "1rem" }}>
                <DragDropContext onDragEnd={handleOptionDragEnd}>
                    <Drop id="root" type="options" gap="8px">
                        {question.options?.map((o, idx, optionsArr) => (
                            <Drag
                                id={uuid()}
                                key={o.id || idx}
                                index={idx}
                                disabled={disabled}
                                className=""
                            >
                                <div className="flex flex-row gap-[0.5rem] items-center w-full group p-[0.5rem]
                                    border-t-1 border-t-glaut-stroke-glaut">
                                    {!!o.label?.[lang] && (
                                        <FaTag className={`w-[1rem] h-[1rem] ${textColorWithCssColorVarFromIndex(
                                            getOptionColorIndex(o.id, idx > 0 ? optionsArr[idx - 1].id : undefined)
                                        )}`} />
                                    )}
                                    {!o.label?.[lang] && (
                                        <FaTags className="text-glaut-grey w-[1rem] h-[1rem]" />
                                    )}
                                    <input
                                        className="bg-transparent border-none focus:shadow-none p-0 rounded-none"
                                        placeholder={`${idx + 1}- Option`}
                                        value={o.label?.[lang] ?? ""}
                                        ref={el => (optionsRef.current[idx] = el)}
                                        onChange={e => editOption(e.target.value, o.id)}
                                        onBlur={e => editOption(normalizeQuestionOrOption(e.target.value), o.id)}
                                        onKeyDown={handleKeyPress(idx)}
                                        disabled={disabled}
                                    />
                                    {optionsArr.length > 1 && (
                                        <button
                                            className="border-none shadow-none m-0 p-0"
                                            onClick={() => !disabled && deleteOption(o.id)}
                                        >
                                            <MdClose
                                                className="text-glaut-light-grey opacity-0 group-hover:opacity-100"
                                            />
                                        </button>
                                    )}
                                </div>
                            </Drag>
                        ))}
                    </Drop>
                </DragDropContext>
                <div className="flex justify-center">
                    <GlautButtonSecondary onClick={() => { addOption() }} disabled={disabled}>
                        <div className="flex flex-row items-center justify-center gap-[5px]">
                            <MdAddCircle className="w-[20px] h-[20px]" />
                            <p className="text-[13.33px] font-medium">Add option</p>
                        </div>
                    </GlautButtonSecondary>
                </div>
            </div>
        </div>
    )
}