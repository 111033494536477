import { MdOutlineArrowDropDown } from "react-icons/md"
import Select, { DropdownIndicatorProps, SelectInstance } from "react-select"
import { copy, getCopy } from "@utils/Copy"
import { forwardRef, Ref } from "react"

interface IOption {
    label: React.ReactNode
    value: string
}

interface IQuestionSelectProps {
    options: IOption[]
    value?: IOption
    onSelect?: (value: string | null) => void
}

function SelectDownChevron({ innerProps }: Readonly<DropdownIndicatorProps>) {
    return (
        <div className="" {...innerProps}>
            <MdOutlineArrowDropDown
                className="h-[1.25em] w-[1.25em] text-glaut-text-midnight"
            />
        </div>
    )
}

const QuestionSelect = forwardRef<
    SelectInstance<IOption>,
    Readonly<IQuestionSelectProps>
>(({
    value,
    options,
    onSelect
}, ref) => (
    <Select
        ref={ref as Ref<SelectInstance>} // Type casting needed due to react-select's internal type constraints
        placeholder={getCopy(copy.coding.selectAnAnalysis)}
        classNames={{
            container: () => "flex-1",
            placeholder: () => "text-center text-[13.33px] font-medium",
            option: () => "px-[0.5em] py-[0.25em] border-1 border-transparent hover:border-glaut-grey",
            indicatorSeparator: () => "hidden",
            control: () => "pl-[0.25em] pr-[0.75em] border-0 outline-0"
        }}
        components={{
            DropdownIndicator: SelectDownChevron
        }}
        styles={{ // some styles don't work with class names
            option: () => ({
                backgroundColor: "white"
            }),
            placeholder: style => ({
                ...style,
                color: "var(--color-glaut-text-midnight)"
            }),
            control: style => ({
                ...style,
                borderColor: "var(--color-glaut-light-grey)",
                backgroundColor: "var(--color-glaut-cards)",
                boxShadow: "none",
                ":hover": {
                    ...style[":hover"],
                    borderColor: "black",
                    cursor: "pointer"
                }
            })
        }}
        value={value}
        onChange={ev => {
            if (ev === null) {
                onSelect?.(null)
                return
            }

            if (typeof ev !== "object" || !ev || !("value" in ev) || typeof ev.value !== "string") return
            onSelect?.(ev.value)
        }}
        options={options}
        isClearable
        isSearchable={false}
    />
))

QuestionSelect.displayName = "AnalysisTabAnalysisColFilteringModalQuestionSelect"

export default QuestionSelect