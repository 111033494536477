import GlautButtonAiSecondary from "@components/Buttons/GlautButtonAiSecondary"
import GlautButtonAiSecondaryLabel from "@components/Buttons/GlautButtonAiSecondaryLabel"
import GlautSwitchWithLabel from "@components/inputs/GlautSwitchWithLabel"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { interviewSettingsTabProjectsControlCopy as localCopy } from "./utils/copy"
import { featureFlags, IFeatureFlag } from "./constants/feature-flags"
import { useInterviewService } from "@hooks/services/useInterviewService"
import AiLabel from "@components/layouts/AiLabel"
import { INTERVIEWS_POOLING_INTERVAL_MS } from "./constants/intervals"
import * as Sentry from "@sentry/react"

export default function InterviewSettingsTabProjectsControl() {
    // #region Contexts
    const { project, lang, setProject } = useLegacyProject()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States

    // Controls the BE delay to start the generation
    const [isSchedulingSyntheticInterviews, setIsSchedulingSyntheticInterviews] = useState(false)

    // Controls the BE delay to actually generate the interviews
    const [isGeneratingSyntheticInterviews, setIsGeneratingSyntheticInterviews] = useState(false)

    const [amountToGenerate, setAmountToGenerate] = useState<number>(50)

    // #endregion

    // #region Refs
    const amountInputRef = useRef<HTMLInputElement>(null)
    const prevAmountRef = useRef<number>(0)
    // #endregion

    // #region Memos
    const isGenerateSyntheticInterviewsButtonDisabled = useMemo(
        () => isSchedulingSyntheticInterviews || project?.source !== "interview" || project?.status === "ended",
        [isSchedulingSyntheticInterviews, project]
    )
    // #endregion

    // #region Callbacks
    const handleToggleFeatureFlag = useCallback((slug: IFeatureFlag["slug"], value?: boolean) => {
        if (slug === "allow_multiple_submissions") {
            setProject(
                prev => prev?.source === "interview"
                    ? ({
                        ...prev,
                        interview_settings: {
                            ...prev.interview_settings,
                            allow_multiple_submissions: value ?? !prev.interview_settings.allow_multiple_submissions
                        }
                    })
                    : null
            )
            return
        }

        setProject(
            prev => prev
                ? ({ ...prev, features: { ...prev.features, [slug]: value ?? !prev.features[slug] } })
                : null
        )
    }, [setProject])

    const handleGenerateSyntheticInterviews = useCallback(() => {
        if (!project?._id) return

        setIsSchedulingSyntheticInterviews(true)

        interviewService.generateSyntheticAnswers({
            projectId: project?._id,
            amount: amountToGenerate,
            lang,
            reuseExisting: false
        })
            .then(() => setIsGeneratingSyntheticInterviews(true))
            .catch(err => {
                Sentry.captureException(err)
                setIsGeneratingSyntheticInterviews(false)
            })
            .finally(() => setIsSchedulingSyntheticInterviews(false))
    }, [interviewService, project?._id, lang, amountToGenerate])

    const handleGetInterviewsAmount = useCallback(async () => {
        if (!project?._id) throw new Error("Project ID is required")

        return await interviewService.getInterviews({ projectId: project?._id }).then(res => res.total_interviews)
    }, [project?._id, interviewService])
    // #endregion

    // #region Effects

    // onMountGetInterviewsAmount
    useEffect(() => {
        if (!project?._id || prevAmountRef.current !== 0) return

        handleGetInterviewsAmount().then(amount => {
            prevAmountRef.current = amount
        }).catch(() => {
            prevAmountRef.current = 0
        })
    }, [project?._id, handleGetInterviewsAmount])

    // onGeneratingSyntheticInterviewsExecutePooling
    useEffect(() => {
        /**
         * While generating synth answers, the component is blocked from generating other ones. This useEffect performs
         * a pooling to the BE during the generation, and releases the component to generate new synth answers when the
         * amount of new interviews matches the initial desired number.
         */

        if (!project?._id || !isGeneratingSyntheticInterviews) return

        // Prevent memory leak => suggestion from CodeRabbit
        // https://github.com/Glaut-Inc/glaut-app/pull/250#discussion_r1925488110
        let isSubscribed = true

        const interval = setInterval(() => {

            handleGetInterviewsAmount().then(amount => {
                if (amount - prevAmountRef.current < amountToGenerate) return

                if (!isSubscribed) return
                setIsGeneratingSyntheticInterviews(false)
                clearInterval(interval)
                prevAmountRef.current = amount
            })
        }, INTERVIEWS_POOLING_INTERVAL_MS)

        return () => {
            clearInterval(interval)
            isSubscribed = false
        }
    }, [handleGetInterviewsAmount, isGeneratingSyntheticInterviews, project?._id, amountToGenerate])

    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col gap-[1.5em] border-1 border-glaut-light-grey rounded-[0.75rem] p-[1.5rem]">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(localCopy.projectsControl)?.toUpperCase()}
            </p>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-[1.5rem]">
                {featureFlags.map(featureFlag => (
                    <GlautSwitchWithLabel
                        key={featureFlag.id}
                        label={getCopy(featureFlag.title) ?? ""}
                        defaultChecked={
                            featureFlag.slug === "allow_multiple_submissions"
                                ? !!project?.interview_settings.allow_multiple_submissions
                                : !!project?.features[featureFlag.slug]
                        }
                        onChange={ev => handleToggleFeatureFlag(featureFlag.slug, ev.target.checked)}
                    />
                ))}
            </div>
            <div className="flex items-center justify-between gap-[0.5rem] rounded-[0.25rem] py-[0.75rem] px-[1.5rem] 
                bg-glaut-very-light-grey">
                <p className={`text-[13.33px] font-medium
                    ${isGeneratingSyntheticInterviews ? "text-glaut-grey" : "text-glaut-text-midnight"}
                `}>
                    {getCopy(localCopy.generateSyntheticInterviews)}
                </p>
                {isGeneratingSyntheticInterviews && (
                    <div className="py-[0.5rem] px-[0.75rem]">
                        <AiLabel>
                            {getCopy(copy.words.processing)}...
                        </AiLabel>
                    </div>
                )}
                {!isGeneratingSyntheticInterviews && (
                    <div className="flex gap-[0.75rem]">
                        <input
                            type="number"
                            ref={amountInputRef}
                            className="text-[13.33px] font-medium w-[100px] text-center border-0 rounded-[0.25rem] 
                            text-glaut-text-midnight bg-glaut-off-white
                            focus:shadow-none"
                            value={amountToGenerate}
                            onChange={ev => setAmountToGenerate(ev.target.valueAsNumber)}
                            min={1}
                            max={100}
                        />
                        <GlautButtonAiSecondary
                            onClick={handleGenerateSyntheticInterviews}
                            disabled={isGenerateSyntheticInterviewsButtonDisabled}
                        >
                            <GlautButtonAiSecondaryLabel disabled={isGenerateSyntheticInterviewsButtonDisabled}>
                                {getCopy(copy.actions.generate)}!
                            </GlautButtonAiSecondaryLabel>
                        </GlautButtonAiSecondary>
                    </div>
                )}
            </div>
        </div>
    )
}