export const analysisTabAddInsightToReportModalCopy = {
    addInsightToAReport: {
        en: "Add Insight to a report"
    },
    selectAReport: {
        en: "Select a report"
    },
    insightSavedToReport: {
        en: "Insight saved to report"
    },
    itWasNotPossibleToLoadTheProjectReports: {
        en: "It was not possible to load the project reports"
    },
    itWasNotPossibleToSaveTheInsightToTheReport: {
        en: "It was not possible to save the insight to the report"
    }
} as const
