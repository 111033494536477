import { useToPng } from "@hugocxl/react-to-image"
import { forwardRef, useState } from "react"
import slugify from "slugify"
import { ISelectInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import InsightBlockDownloadBar from "../ReportDetailsInsightBlockDownloadBar"
import InsightBlockTemplate from "../ReportDetailsInsightBlockTemplate"
import InsightBlockThemes from "../ReportDetailsInsightBlockThemes"
import SelectInsightBlockDonut from "../ReportDetailsSelectInsightBlockDonut"

interface IReportDetailsSelectInsightBlockProps {
    id?: string
    block: ISelectInsight
}

const ReportDetailsSelectInsightBlock = forwardRef<
    HTMLDivElement,
    Readonly<IReportDetailsSelectInsightBlockProps>
>(({
    id,
    block
}, ref) => {
    // #region Contexts
    const { renderAsPdf } = useReportDetails()
    // #endregion

    // #region States
    const [isExporting, setIsExporting] = useState(false)
    // #endregion

    // #region React to Image
    const [, convertToPng, exportableRef] = useToPng<HTMLDivElement>({
        onStart: () => {
            setIsExporting(true)
        },
        onSuccess: data => {
            const link = document.createElement("a")
            link.download = `${slugify(block.title)}-options.png`
            link.href = data
            link.click()

            setTimeout(() => {
                link.remove()
                setIsExporting(false)
            }, 500)
        },
        onError: () => {
            setIsExporting(false)
        }
    })
    // #endregion

    if (renderAsPdf)
        return (
            <InsightBlockTemplate block={block}>
                <InsightBlockThemes block={block} />
            </InsightBlockTemplate>
        )

    return (
        <InsightBlockTemplate block={block} ref={ref} id={id}>

            {!!block.stats?.length && (
                <div
                    className="flex flex-col items-between self-center w-[90%] p-1 bg-glaut-off-white"
                    ref={exportableRef}>
                    <div className="flex flex-col xl:flex-row justify-between items-center w-full">
                        <SelectInsightBlockDonut block={block} />

                        <div className="w-full xl:w-[50%] 2xl:w-[60%]">
                            <InsightBlockThemes block={block} />
                        </div>
                    </div>

                    <InsightBlockDownloadBar onDownload={convertToPng} showDownloadButton={!isExporting} />
                </div>
            )}

        </InsightBlockTemplate>
    )
})

ReportDetailsSelectInsightBlock.displayName = "ReportDetailsSelectInsightBlock"

export default ReportDetailsSelectInsightBlock